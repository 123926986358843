import React from 'react';
import { Helmet } from "react-helmet"
import { Container, Grid } from 'semantic-ui-react'
import IcosmHeader from './components/IcosmHeader';

const info = [
    {
        name: 'Dr Built Varinporn Amporndanai',
        job_title: 'Medical Director',
        info: `Dr Built has been practicing as a doctor for over 5 years which has included public hospital years and experience in general medicine and psychiatry. Dr Built is a long time enthusiast of cosmetic medicine and even though she is yet to have a cosmetic treatment herself being the baby of the Dr team, she loves talking her patients and scripting nurses through transformations and improvement’s in their practice and supporting her fellow doctors in the busy area of cosmetic scripting with InstantCosmetics.`,
        img: 'drbuilt'
    },
    {
        name: 'Dr Jonathan Hopkirk',
        job_title: 'Cosmetic Complications Director',
        info: `Dr Hopkirk is the former Global Medical Director of the Laser Clinics Group, and has built a strong skillset and clinical acumen for managing rare and severe adverse events. In 2021 Dr Hopkirk’s large-scale experience in managing complications across diverse population groups earned him a seat on Dr Lee Walker’s ‘Complications Medical Aesthetics Collaborative’ (CMAC) international expert board.
        In 2022, he shared the stage with chosen industry professionals from around the globe at the first-ever CMAC ‘New Frontiers Safety Congress’ in London. He was again invited back in 2023 to participate in expert panel discussions on various aesthetic complications topics. The same year, he was invited to present in the main hall on rare adverse events at Australia’s largest aesthetic medicine conference, the ‘Non-Surgical Symposium’.
        Dr Hopkirk is a cosmetic doctor at Rose Bay Medical Clinic, a GP-based practice in Sydney. He also does medical consultancy work for Universkin and Fillmed Laboratories. Outside of the aesthetic medical field, Dr Hopkirk is the Medical Director for HealthCrest Australia and the founder and CEO of the not-for-profit organisation ‘Dance For Abilities,’ which serves the specially abled community in Australia and New Zealand.`,
        img: 'drhopkirk'
    },
    {
        name: 'Dr Walid Alhassan',
        job_title: 'Cosmetic Doctor',
        info: `Dr Walid was born in Dubai and completed a Bachelor degree of medicine (MBBS), then completed training in emergency medicine from Syria -Damascus university to become an emergency medicine specialist. He worked as an emergency medicine specialist in Syria, Kuwait and Dubai before moving to Australia. His work as a doctor commenced in Australia in 2013 in various positions including both hospital and general practice, and has been calling Australia home ever since. Whilst working in Hervey Bay QLD, he developed an interest in skin cancer checks, mole scans, skin cancer procedures and aesthetic medicine and completed training in skin cancer in 2020 after which a Diploma in aesthetic medicine
        Dr. Walid’s hobbies consist of sports and music, and plays a few instruments including violin, flute and guitar.`,
        img: 'drwalid'
    },
    {
        name: 'Dr Pong',
        job_title: 'Cosmetic Doctor',
        info: `Dr Pong is an accomplished medical practitioner with surgical experience in Thailand and Australia.
        She is a firm believer of feeling good inside and out which is why she has been drawn to cosmetic medicine and enjoys going to the gym.
        Dr Pong loves working with the team of doctors and nurses at InstantCosmetics.`,
        img: 'drpong'
    },
    {
        name: 'Ella Smith',
        job_title: 'Nurse Practitioner',
        info: `Ella is a Nurse Practitioner with over 15 years nursing experience. Ella’s background includes forensic, emergency, addiction 
        medicine and peri-op nursing. She became endorsed in 2016 as Victoria Police’s first Nurse Practitioner. 
        During covid Ella undertook her cosmetic aesthetics training and has since completed observation and training with some of Victoria’s 
        best long-term experienced nurses. She enjoys seeing the subtle results of aesthetic products and people feeling more comfortable 
        in their skin. When Ella is not scripting you will find her teaching and doing Pilates in her studio in Melbourne.`,
        img: 'ella'
    },
    {
        name: 'Dr Jonathan Loo',
        job_title: 'Cosmetic Doctor',
        info: `Dr Yew Loong (Jonathan) Loo has experience in General and Plastic Surgery in several renowned institutions in Manchester, Leeds and London. He has interest in Facial and Reconstructive Surgery with a Distinction in Masters in Burns, Plastic & Reconstructive Surgery.
        Since moving to Melbourne, Australia in 2019, he has worked in several large healthcare institutions such as St Vincent’s Hospital, Melbourne and Royal Children’s Hospital, Flemington where he was further trained in General & Plastic Surgery. He is also a published scientific author in the field of Plastic Surgery.
        Considering his interest, Dr Jonathan Loo decided to pursue a career in Cosmetic Medicine in Melbourne offering cosmetic treatment for medical and aesthetic purposes for the last 3 years.`,
        img: 'jonathan'
    },
    {
        name: 'Dr Qing Lee',
        job_title: 'Cosmetic Doctor',
        info: `Dr Qing graduated from the University of Queensland in 2018. Since then, he has gained extensive experience in cosmetic, emergency, and family medicine. He currently divides his time between being a registrar in general practice and as a cosmetic physician.`,
        img: 'drqing'
    },
    {
        name: 'Nazranah',
        job_title: 'Cosmetic Nurse',
        info: `Naz is one who is always on the go, from being a busy Mum to 3 children and owning her own business. Naz loves her work and mum life balance, keeping her on her toes.
        Naz has been in the Cosmetic Industry for over 7 years and has worked alongside Doctors and Plastic surgeons. Completing extensive studies in Cosmetic Nursing Qualifications both nationally and internationally.  Naz has been able to provide her skills and training and put them into practice in her very own Cosmetic Clinic in QLD and Victoria. 
        Naz loves meeting new people, and building rapport and is committed to delivering the best evidence-based practice. Customer service has always been her key to success in her business. Naz is your go-to for support here at InstantCosmetics. She will go above and beyond to help you with your enquiries and support you with your business journey.`,
        img: 'naz'
    },
    {
        name: 'Dr Tandra',
        job_title: 'Cosmetic Doctor',
        info: `Dr Tandra Paul graduated from University of Western Australia in 2001  MBBS (Honours). 
        Over the past 20+ years she has worked in various medical and surgical roles and principally as an Emergency Department Doctor. 
        In 2012 she discovered her true calling, Aesthetic medicine. Dr Paul is a qualified and experienced Cosmetic Dr with over a decade experience in aesthetics.`,
        img: 'drtandra'
    }
    /*{
        name: 'Brigitte Carruthers',
        job_title: 'Support & Services Director',
        info: `Brigitte has a background in medical science (Blood Bank production and testing), people management and customer service.
        Joining the team at InstantScripts & InstantCosmetics she has been able to combine her skills to work with a wonderful team to support InstantCosmetics during its exciting face lift.
        She is excited to learn more about the cosmetic industry, continue working with a wonderful team of people and get her 1st injectables.`,
        img: ''
    },*/
]

const Team = () => {

	return <div className="team">
		<Helmet>
			<title>Our Team</title>
		</Helmet>

        <IcosmHeader title="Our Team"/>

		<Container className="px-4 py-8 lg:p-12">
            <div className="info w-6/7 mx-auto">
                <Grid columns={2}>
                    {info.map((el, i) =>
                        <Grid.Row key={i}>
                            <Grid.Column mobile={12} tablet={12} computer={4}>
                                <img className="object-cover h-full w-full mb-2 lg:mb-0" src={`https://storage.googleapis.com/icosm/team-${el.img}.jpg`}/>
                            </Grid.Column>

                            <Grid.Column mobile={16} tablet={16} computer={12} className="team-desc flex flex-col self-end justify-center text-icosm-gold-200">
                                <h3>{el.name}</h3>
                                <p className="meta text-icosm_sm">{el.job_title}</p>
                                <hr/>
                                <p className="text-icosm-gray text-icosm_base">{el.info}</p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </div>
		</Container>
	</div>
}

export default Team;

import React from 'react';
import { Helmet } from "react-helmet"
import IcosmHeader from './components/IcosmHeader';

const Homepage = () => {

	return <div className="home">

		<Helmet titleTemplate={null}>
			<title>InstantCosmetics - Welcome to InstantCosmetics</title>
		</Helmet>

        <IcosmHeader title="Welcome to InstantCosmetics" link="/about-instantcosmetics" link_text="Learn More"/>

	</div>
}

export default Homepage;

import React from 'react';

export function CosmeticPhotoDisclaimer() {
	return (
		<p>
			I understand and consent to photographs being taken before and
			after this treatment and that these photographs will form part
			of my medical records and will be used in accordance with{" "}
			<a
				href="#"
				style={{color: "#0065F2"}}
				onClick={e => {
					e.preventDefault();
					app.trigger(app.events.SHOW_POPUP, {
						pt: "cx_txt",
						txt: "Privacy Policy",
						pn: "privacy-policy",
					})
				}}
			>
				InstantCosmetics Privacy Policy
			</a>
			. A copy of the{" "}
			<a
				href="#"
				style={{color: "#0065F2"}}
				onClick={e => {
					e.preventDefault();
					app.trigger(app.events.SHOW_POPUP, {
						pt: "cx_txt",
						txt: "Privacy Policy",
						pn: "privacy-policy",
					})
				}}
			>
				Privacy Policy
			</a>{" "}
			is available upon request.
		</p>
	);
}
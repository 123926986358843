import React, { useEffect, useState, useRef } from 'react';
import { cls } from './utils';

const Slider = ({ data, interval = 5000, auto = false, clsn, cont, type, img_path, img_cls }) => {
    const [slides, setSlides] = useState(data);
    const [active, setActive] = useState(0);
    const slider = useRef(null);

    useEffect(() => {
        if (auto) {
            const upd = setInterval(() => {
                setSlides(slides => slides.slice(1).concat(slides[0]))
            }, interval);
            return () => clearInterval(upd);
        }
    }, [])


    const handleScroll = (i) => {
        const cont = slider.current;
        const scroll = cont.scrollWidth / slides.length;
        cont.scrollTo(i * scroll, 0);
        setActive(i);
    }

    return (<>
        <div className={cls("slider-container md:flex items-center", !auto  && "flex h-full overflow-x-scroll scroll-smooth transition-[scroll] ease-in-out gap-2", cont)} ref={slider}>
            {slides
                .map((el, i) =>
                    type === 'reviews' ?
                        <div key={i} className="relative flex flex-col rounded-md shadow p-6 items-center justify-between w-full md:w-1/3 bg-white shrink-0 h-full grow">
                            <div className="flex gap-1">{Array(5).fill().map((el, i) => <svg key={i} width="16" height="16" viewBox="0 0 24 24"><use href="#star" fill="#3C96FB"></use></svg>)}</div>
                            <div className="relative py-2 px-6">
                                <img src={`${img_path}/quotation.svg`} className="absolute left-0 top-4 h-[0.69rem]" />
                                <p className="text-center text-is-black-50 mt-4">{el.text}</p>
                                <img src={`${img_path}/quotation.svg`} className="absolute right-0 bottom-4 rotate-180 h-[0.69rem]" />
                            </div>
                            <p className="text-sm text-right my-4 mt-auto self-end text-is-black-50">- {el.name}</p>
                        </div>
                        :   <a key={i} className={cls(clsn, 'm-auto cursor-pointer')} target="_blank" href={el.link}>
                                <img alt={el.name} src={el.img} className={cls(img_cls||"h-auto")}/>
                            </a>

                )}
        </div>

        {!auto && <div className="flex justify-center gap-2 w-full mt-4">
            {Array(data.length).fill().map((el, i) => <span key={i} onClick={() => handleScroll(i)} className={cls("h-4 w-4 cursor-pointer rounded-full", active == i ? "bg-is-blue" : "bg-is-gray-25")}></span>)}
        </div>}

    </>);
}

export default Slider;

import React from 'react';
import { Helmet } from "react-helmet"
import { Container } from 'semantic-ui-react'
import InstagramFeed from '../../xAppLib/UIelems/InstagramFeed';
import IcosmHeader from './components/IcosmHeader';
import IcosmTiles from './components/icosmTiles';

const About = () => {
	const info = [
		{
            title: 'Our Team',
            link: '/instantcosmetics-team',
            img: 'about-us-team.jpg'
		},
        {
            title: 'How We Work',
            link: '/how-we-work',
            img: 'about-us-how.jpg'
        },
        {
            title: 'The Huddle',
            link: '/the-huddle',
            img: 'hero-huddle-sm.jpg'
        },
        {
            title: 'Our Partners',
            link: '/partners',
            img: 'about-us-partners.jpg'
        },
	]

	return <div className="about">

		<Helmet>
			<title>About</title>
		</Helmet>

        <IcosmHeader title="About Us" img="about"/>
		
        <Container className="px-4 py-8 sm:p-12">
            <div className="text-center text-icosm-gold-200 font-bold md:w-3/4 mx-auto md:px-8">
                <h3>We’re here to help you on your journey</h3>
                <p className="text-icosm_base md:text-icosm_xl">InstantCosmetics are the original scripting platform and have been supporting our cosmetic nurses with fast access to experienced and quality cosmetic doctors since 2018.
                We support our community of cosmetic nurses with quality aesthetic products, advanced education and access to the latest industry advancements and releases in Australia.</p>
            </div>
            <IcosmTiles tile_data={info}/>
            <hr/>
            <div className="socials text-center text-icosm-gold-200 font-bold md:w-3/4 mx-auto md:p-12">
                <h4 className="text-icosm_xl"><a href="https://www.instagram.com/instantcosmeticsau/" target="_blank">Follow us</a> to keep up to date on events and more!</h4>
            </div>

            {/* Instagram feed 
            <InstagramFeed size="5"/>*/}

           
		</Container>
	
	</div>
}

export default About;

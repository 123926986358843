import React, { memo } from 'react';
import { CART_LOC } from './ShopUtil';
import { useWatchRecord } from '../../xAppLib/Hooks';


export default memo(function ShopCartCount({ text }) {
	const qty = useWatchRecord(`${CART_LOC()}/qty`) ?? {};
	const count = Object.values(qty)?.filter(Boolean)?.length;

	return (
		<div className="flex">
			{text}
			{count > 0 && (
				<div style={{ ...(text && { marginLeft: 4 }) }} className="text-white bg-red-500 px-2 py-[1px] rounded-full flex justify-center items-center">
					{count}
				</div>
			)}
		</div>
	);
});
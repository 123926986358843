import React from 'react';
import db_lib from 'xAppLib/libs/db_lib';
import FieldActBtns from 'views/UIelems/fields/ActBtns';
import ProductSelect from '../views/inventory/ProductSelect';
import StockLevels from '../views/inventory/StockLevels';
import moment from 'moment-timezone';

const _MODEL_NAME = 'inventory_model';
const _USE_DATA = 'API';
const _API_NAME = 'inventory';

const sumObjectValues = (arrayOfObjects, key) => {
	return arrayOfObjects.reduce((sum, obj) => {
		// Check if the key exists and the value is a number
		if (obj[key] && typeof obj[key] === 'number') {
			return sum + obj[key];
		}
		// If the key doesn't exist or the value is not a number, just return the current sum
		return sum;
	}, 0);
}

export default class inventory_model extends db_lib {
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }

	static list_fields = [
		{ name: 'Name', jpath: 'name' },
		{ name: 'Units Remaining', jpath: 'units_remaining' },
		{ name: 'Stock Level', type: 'compound', parts: [{ name: 'items', jpath: 'items' }], template: ({ items, refresh }) => <StockLevels items={items} allowInventoryUse={true} refresh={refresh} /> },
		{
			name: 'Actions', template: FieldActBtns, btns_list_func: () => [
				{ n: 'Reorder', a: 'reorder', i: 'cart', c: 'green', func: this.buy_item },
				{ n: 'Drugbook', a: 'drugbook', i: 'book', func: this.view_item },
			], act_run: async (b, row, mod) => {
				await b.func(row, mod)
			}
		},
	];

	static drugbook_fields = [
		{ name: 'Date', jpath: 'date' },
		{ name: 'Name', jpath: 'name' },
		{ name: 'Units', jpath: 'units' },
		{ name: 'Batch Number', jpath: 'batch_number' },
		{ name: 'Nurse', template: ({ row: { nurse_name, nurse_email } }) => <>{nurse_name}<br />{nurse_email}</> },
		{ name: 'Patient', jpath: 'pt_name' },
		// { name: 'Script', jpath: 'script' },
		{ name: 'Purchase', jpath: 'purchase' },
		{ name: 'Description', jpath: 'description' },
	];

	static edit_fields = [
		{ name: 'pid', label: 'Product', type: ProductSelect, valid_not_required: true },
		{ name: 'units', label: 'Quantity - Units', type: 'number' },
		{ name: 'batch_number', label: 'Batch Number', type: 'text' },
		{ name: 'expiry_date', label: 'Expiry Date', type: 'date' },
		{ name: 'supplier', label: 'Supplier', type: 'text' },
		{ name: 'price', label: 'Total Price', type: 'number', valid_not_required: true },
		{ name: 'ref', label: 'Order Reference', type: 'text'},
	]

	static use_fields = [
		{ name: 'units', label: 'Units', type: 'number', placeholder: 'How many units would you like to use?' },
		{ name: 'desc', label: 'Description', type: 'text', placeholder: 'Enter a description of how this product was used' },
	]

	static async buy_item(row) {
		app.history.push('/products/shop?q='+row.name?.toLowerCase());
	}
	static async view_item(row) {
		app.history.push(`/drugbook/${row.pid}`);
	}
	static async use_item({ iid, units, sid, desc }) {
		const res = await inventory_model.get_record(null, null, { act: 'use', data: { iid, units, sid, desc, oid: app.dvc?.org?.oid } });
	}
	static async add_item(data) {
		data.oid = app.dvc?.org?.oid;
		if(data.ref){
			data.meta = { tid: data.ref };
			delete data.ref;
		}
		const res = await inventory_model.get_record(null, null, { act: 'add', data });
		return res;
	}
	static async get(id) {
		let data = { ...(id ? {id} : {}), oid: app.dvc?.org?.oid },
			act = id ? 'get' : 'all';
		const res = await inventory_model.get_record(null, null, { act, data });
		return res;
	}
	static async drugbook({ pid, iid }) {
		let data = { oid: app.dvc?.org?.oid, ...(pid ? {pid} : {}), ...(iid ? { iid } : {}) };
		const res = await inventory_model.get_record(null, null, { act: 'drugbook', data });
		return inventory_model.formatDbDataForDrugbook(res);
	}
	static formatDbData(data) {
		const items = [];

		data.forEach(d => {
			items.push({
				pid: d[0].pid,
				name: d[0].prod.name,
				units_remaining: sumObjectValues(d, 'units_remaining'),
				items: d
			})
		});

		return items;
	}
	static formatDbDataForDrugbook(data) {
		
		const formatWithPlus = (value) => {
			const num = Number(value);
			return num > 0 ? `+${num}` : `${num}`;
		}

		let drugbook = {};
		
		data.forEach(({ items, prod, pid }) => {
			drugbook[pid] = [];
			items.forEach(d => {
				drugbook[pid].push({
					pid: pid,
					name: prod.name,
					date: moment(d.tm).tz('Australia/Melbourne').format('DD/MM/YY hh:mmA'),
					units: formatWithPlus(d.item.units),
					batch_number: d.batch_number,
					nurse_name: d.by.n,
					nurse_email: d.by.e,
					pt_name: d.meta?.pt?.nm,
					script: d.meta?.sid,
					purchase: d.meta?.tid,
					description: d.meta?.desc
				})
			});
		});

		return drugbook;
	}
}
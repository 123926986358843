import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react'
// style={{backgroundImage: `url(/images/icosm/icosm-header-${img}.jpg)`

const IcosmHeader = ({title, img, link, link_text}) => {

    return  <div className="hero flex flex-col justify-end h-full w-full bg-no-repeat bg-cover bg-center">   
                <Container>
                    <h1 className="text-icosm-gold-50">{title}</h1>
                    {link && <Link to={link} className="link">{link_text}</Link>}
                </Container>
            </div>
}

export default IcosmHeader;



import React, { Component } from 'react';
import { Button, Input, Card, Segment, Loader, Modal } from 'semantic-ui-react'
import { v4 as generate_id } from 'uuid'

import API_service from '../providers/API_service'

import { getExifOrientation, resetOrientation } from '../libs/image_orient'
import gtm from '../providers/gtm';
import { resizeImage } from "./resizeImage";
import Badge from 'views/NUI/Badge'
const DEBUG = false
const MAX_FILE_SIZE_MB = 5;

class TakePhoto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uniqueId: generate_id(),
			image_source: 'canvas',
			img_name: '',
			show_modal: false,
			error : false
		};
		
		this.videoRef = React.createRef()
		this.canvasRef = React.createRef()
		this.fileRef = React.createRef()
	}
	
	componentWillUnmount() {
		this.shutdown()
	}

	// 		--------------------------------		--------------------------------		---------

	init() {
		navigator.mediaDevices
			.getUserMedia({ video: { width: 468, height: 400 } })
			.then(stream => {
				this.videoRef.current.addEventListener("playing", this.videoReady);
				this.videoRef.current.srcObject = stream;
				this.videoRef.current.play();
				this.stream = stream
			})
			.catch((err) => {
				alert("This device cannot access the camera.")
				this.setState({show:'button'})
			});
	}
	
	videoReady = () => {
		this.setState({videoReady:true})
	}
	
	shutdown() {
		if (!this.stream)
			return
		this.videoRef.current.removeEventListener("playing", this.videoReady);
		
		this.stream.getTracks().forEach(function(track) {
		  track.stop();
		});
		
		this.stream = null
	}
	// 		--------------------------------		--------------------------------		---------

	async upl_photo() {
		
		const { target = 'cosm-trt-pts', uploadType = this.state.type === 'application/pdf' ? 'attachment' : 'photo' } = this.props

		this.setState({uploading:true})

		const img_data = this.state.img

		const res = await API_service.load_data(
            `file/${uploadType}/store/${target}/snap`,
            {
                img_data: this.state.img,
                img_src: this.state.src,
                comn: this.state.comn || this.state.img_name,
                o: this.state.orientation,
            }
        );
							
		this.setState({uploading:false, img_name: null})
		const { onPhoto } = this.props

		res.is_attachment = res.tag === 'attachment';
		if (this.props.tag)
			res.tag = this.props.tag
		if (this.props.tag_label)
			res.tag_label = this.props.tag_label

		res.image_source = this.state.image_source

		// onPhoto && onPhoto({
		// 	...res,
		// 	img_data
		// })
		onPhoto && onPhoto(res)

		DEBUG && console.log('uploaded photo', res);

		this.setState({show:'button'})
		gtm.log('','upl_pic');
	}

	/*show_toast_error(msg) {
		toast.error(msg, {
			containerId: 'default',
			position: 'bottom-center',
			hideProgressBar: true,
			draggable: false,
		});
	}*/

	async on_image_upload_select(event) {

    	const file = event.target.files[0];

		const sizeInBytes = MAX_FILE_SIZE_MB * (1024*1024);

		// Get the base64 representation of the image and store in the state
		new Promise((resolve, reject) => {
			const fileReader = new FileReader();

			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				if (file.size <= sizeInBytes) return resolve(fileReader.result);

				const too_large = () => reject(`File size is too large. Max size is ${MAX_FILE_SIZE_MB}MB`);
				const temp = new Image();
				temp.onload = function (event) {
					try {
						const {width, height} = event.target;
						// Naive scaling. This will reduce the number of pixels to the desired about, but
						// depending on compression etc the actual file size ends up quite a bit smaller.
						// Ref https://math.stackexchange.com/a/3983239
						const ratio = Math.sqrt(sizeInBytes / file.size);
						const new_w = Math.floor(width * ratio);
						const new_h = Math.floor(height * ratio);
						resolve(resizeImage(event.target, new_w, new_h) || fileReader.result);
					} catch (err) {
						too_large()
					}
				};
				temp.onerror = too_large;
				temp.src = fileReader.result.toString();
			}

			fileReader.onerror = () => reject(`Unable to read the photo. Please try again`);
		}).then(
			img => {
				this.setState({
					img,
					show: 'preview',
					image_source: 'upload',
					img_name: file.name,
					type: file.type,
					error: false
				});
			},
			err => this.setState({error: err?.message || String(err)})
			// this.show_toast_error(err?.message || String(err))
		);
	}

	render_ios_button() {
		return <label className="ui basic button !ml-2">
			<input ref={this.fileRef} type="file" accept="image/*,.pdf" onChange={async e=>{
					try {
						const file = this.fileRef.current.files[0]

						const img = await new Promise(resolve=>{
							const reader = new FileReader()
							reader.addEventListener("load",  () => {
								resolve(reader.result)
							}, false);

						  if (file) {
								reader.readAsDataURL(file);

						  }
						})
						this.setState({show:'preview',img,src:img, type: file.type})

						const orientation = await getExifOrientation(file)
						DEBUG && console.log("orientation",orientation);
						this.setState({orientation})
					} catch (e) {
						DEBUG && console.log("error",e);
						this.setState({show:'button',img:null})
					}

				}
			}
			style={{
					width:'0.1px',
					height:'0.1px',
					opacity: 0,
					overflow:'hidden',
					position: 'absolute',
					zIndex: -1
				}} />
				Upload
			</label>
	}
	
	render_canvas_button() {
		const acceptedTypes = this.props.acceptedTypes?.reduce((res, t) => `${res}${res && ','} ${t.value}`, "") || "image/*,.pdf";
		const fieldId = this.state.uniqueId;
		return (
			<div className="mx-2.5">
				<div className="flex gap-2">
					{this.props.enableImageUpload && (
						<React.Fragment>
							<Button
								type="button"
								basic
								content="Upload file"
								onClick={_ => { document.getElementById(`form-${fieldId}-uploader-input`).click() }}
							/>

							<input
								type="file"
								accept={acceptedTypes}
								style={{ display: 'none' }}
								data-testid="input-upload-file"
								id={`form-${fieldId}-uploader-input`}
								onChange={(e) => this.on_image_upload_select(e)}
							/>
						</React.Fragment>
					)}
					{fieldId.indexOf("image") > -1 && <Button
						type="button"
						basic
						content="Take photo"
						onClick={_=>{this.setState({show:'canvas', show_modal:true}, () => this.init())}}
					/>}
				</div>
			</div>
		);
	}

	render_canvas() {
		const { show_modal } = this.state
		return <>
				<Modal 
				open={!!show_modal}
				onClose={_=>{
					this.shutdown()
					this.setState({show_modal:false, img:null, show:'button'})}}
				closeIcon
				className="text-is-black max-w-[500px] max-h-[730px] h-fit-content"
				>
					<Modal.Header content="Take a photo"/>
					<Modal.Content>
						{this.state.img ? 
							<><div className="w-full mx-auto rounded-lg bg-black h-[400px] bg-cover mb-2 bg-no-repeat bg-center" style={{ backgroundImage: `url('${this.state.img}')`}}/>
								<p className="text-is-black">Review the photo to make sure that it is clear and accurately captures the area you need to show the Doctor. If necessary, retake the photo to get a better shot.</p>
								<Button type="button" color="green" fluid
									onClick={_=>{ this.setState({show:'preview'})}}
									style={{marginBottom: '.5em'}}>Upload Photo</Button>
								<Button basic fluid onClick={_=>{this.setState({img:null}, () => this.init())}}>Re-take photo</Button>
							</> 
						:   <>
								<video className="rounded-md max-w-full bg-black h-[400px]" ref={this.videoRef} width="100%" height="400" autoPlay></video>
								<canvas ref={this.canvasRef} width="468" height="400" className='hidden'></canvas>
								<ol className="text-is-black list-decimal ml-6 mb-5 mt-2">
									<li>Make sure you find a well-lit area to take the photo</li>
									<li>Hold the camera still</li>
									<li>Take the photo</li>
									<li>Check the photo</li>
									<li>Upload the photo</li>
								</ol>
								{this.state.videoReady && <><Button color="blue" fluid
									content="Take Photo"
									type="button"
									style={{marginBottom: '.5em'}}
									onClick={_=>{
										this.canvasRef.current.getContext('2d').drawImage(this.videoRef.current, 0, 0, 468, 400);
										const img = this.canvasRef.current.toDataURL("image/jpeg");
										this.shutdown()
										this.setState({img})}}
									/>
									<Button basic fluid
										content="Cancel" 
										onClick={_=>{
											this.shutdown()
											this.setState({show_modal:false, img:null, show:'button'})
										}}/>
								</>}
							</>
						}
					</Modal.Content>
				  </Modal>
				</>
	}
	
	render_preview() {
		return (
			<Card className="file-upload">
				<div className="w-full p-2 flex flex-wrap items-center justify-between">
					<div className="flex items-center">  
						<img className="h-10 mr-2" src={this.state.img} alt="" />
						<span className="text-is-blue">{this.state.img_name}</span>
					</div>
					
				<div className="flex flex-wrap relative gap-2">
					{this.state.uploading ? 
						<div className="p-2"><Loader size='tiny' active inline/><span className="ml-2 text-sm">Uploading...</span></div> 
					:   <>
							<Input
								placeholder='Comment'
								defaultValue={this.state.comn}
								onChange={ (e,{value}) => this.setState({comn:value || this.state.img_name || 'Untitled'}) }
								className="w-full md:w-[150px] mt-2 md:mt-0"
							/>
							<Button
								basic
								className='w-[100px] h-fit'
								onClick={_=>this.setState({show:'button', img:null})}
								content="Cancel"
								/>
							<Button
								color="green"
								className='w-[100px] h-fit'
								data-testid="button-upload-file"
								onClick={_=> { 
									this.upl_photo()
									this.setState({img:null, comn:null})
								}}
								content="Upload"/>
							
						</>
					}
					</div>
				</div>
			</Card>
		);
	}

	render_button() {
		return app.settings.is_mob ? this.render_ios_button() : this.render_canvas_button()
	}

	render() {
		const { onPhoto, inline=false } = this.props
		const { show = 'button' } = this.state
		
		return (
			<><Segment basic style={{padding:0, margin:'.25em 0', display:'flex', height: '100%', width:'100%'}}>
				{ show=='button' && this.render_button() }
				{ show=='preview' && this.render_preview() }
				{ show=='canvas' && this.render_canvas() }
				</Segment>
				{this.state.error ? <p className="text-is-red text-sm">{this.state.error}</p> : 

				<div>
				<Badge className="text-xs mt-2 mr-2 mb-2" small>Max file size 5MB</Badge>
				<Badge className="text-xs mt-2" small>
					Supported type{this.props.acceptedTypes?.length==1?'':'s'}{" "}
						{this.props.acceptedTypes
							?.reduce((res, t) => `${res}${res && ','} ${t.label}`, "") || "JPEG, PNG, PDF"}
				</Badge>

				</div>}
			</>
		)
	}
}

export default TakePhoto;

import React from 'react';
import { Link } from 'react-router-dom';
import { 
    Button,
    Icon,
    Breadcrumb as BreadcrumbSUI
} from 'semantic-ui-react'
import Breadcrumb from 'xAppLib/MenuNav/Breadcrumb'
import cat_model from 'models/cat_model';
import BackButton from 'xAppLib/UIelems/BackButton';


const Intro = ({cat_route,cat_nm, med_code, med_data}) => {

    const base_path = `${app.consts.routes.cat_route}/${cat_nm}/`

    const parent = cat_route ? base_path + cat_route : '/'
    return <>

            {!app.settings.iprep && <h2 className='head gap-2'>
                {!app.settings.icann && <BackButton parent={base_path} /> }
                {(cat_nm == 'patho' || med_data.itm_tp=='patho') && "Pathology Request For "}
                {med_data?.name || <><Icon name='spinner' loading size="small" /> <small>loading...</small></>} {med_data?.size || ''}
                {/*med_data?.CMI && <a href={med_data.CMI} target='info'>&nbsp;&nbsp;<Icon name='info circle'  /></a>*/}
            </h2> || <h2 className='head' >{med_code == '-MdM99oAFZEtgGgABh2n' && 'Digital consultation' || 'Order PrEP'}</h2>}
     </>;
}
 
export default Intro;
import React, { useState } from 'react';
import { Helmet } from "react-helmet"
import { Container, Form, Segment, Button, Icon } from 'semantic-ui-react'
import IcosmHeader from './components/IcosmHeader'
import UniForm from 'xAppLib/UniForm/UniForm'
import UniFieldSUI from 'xAppLib/UniForm/UniField-SUI';
import API_service from 'xAppLib/providers/API_service'

const CONTACT_FIELDS = [
    {
        name:'name',
        label:'Full Name:',
    },
    {
        name:'phone',
        label:'Mobile:',
    },
    {
        name:'email',
        label:'Email:',
        type:'email',
    },
    {
        name:'details',
        label:'Details:',
        type:'textarea',
    },
    
]
const contact_details = [
    {
        title: 'Email',
        desc: 'assist@instantcosmetics.com.au',
        href:`mailto:assist@instantcosmetics.com.au?subject=${encodeURI('Instant Cosmetics Enquiry')}`
    },
]

const Contact = () => {
    const [loading, setLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	
	async function submitForm(d) {
        // console.log(d)
        setLoading(true)
	    await API_service.api_promise('/icosm/enquiry',d)
		setLoading(false)
		setSubmitted(true)
	}

	return <div className="contact bg-icosm-gold-50">

		<Helmet>
			<title>Contact</title>
		</Helmet>

        <IcosmHeader title="Contact" img="contact"/>

		<Container className="px-4 py-8 sm:p-12">
            
            <Segment loading={loading} basic id="icosm-contact">
                {!submitted && <>
                <h4 className="text-center">Please complete the below form and we will be in touch shortly.</h4>
                <UniForm 
                    onActForm = { submitForm }
                    render_children = {true}
                    fields = { CONTACT_FIELDS	}
                    >
                        {
                            (values, valids, uf_this,fields) => {
                                const args = {values, valids, uf_this}
                                return <Form size="large">
                                    <UniFieldSUI fl={fields.name} {...args} />
                                    <UniFieldSUI fl={fields.email} {...args} />
                                    <UniFieldSUI fl={fields.phone} {...args} />
                                    <UniFieldSUI fl={fields.details} {...args} />

                                    <Button 
                                        size="large" 
                                        type='button'
                                        className='cta'
                                        onClick={_=>uf_this.actForm() }
                                        >Submit</Button>
                                </Form>
                            }
                        }
                    </UniForm></>
                    || <><h3 className="text-center text-icosm-gold-200">Thank you</h3>
                    <p className="text-center text-icosm-gold-200 text-icosm_xl font-bold mb-8">We will be in contact</p></>
                }
            </Segment>

            <h4 className="text-center">Alternatively, you can email using the below details</h4>
            <div className="flex flex-col lg:flex-row items-center justify-center m-auto">
                {contact_details.map((el, i) => 
                    <div key={i} className='contact-details border-2 border-icosm-gold-200 rounded-2xl w-full sm:w-3/4 m-4 text-center font-bold overflow-hidden'>
                        <h4 className="text-icosm-gold-50 bg-icosm-gold-200 p-2 mb-0">{el.title}</h4>
                        <a href={el.href}><p className="text-icosm-gold-200 break-all py-8 px-2 sm:py-10 sm:px-6 lg:py-14 font-bold">{el.desc}</p></a>
                    </div>
                )}
            </div>
           
		</Container>
	
	</div>
}

export default Contact;

import React, { Component } from 'react';
import {
		Dropdown,
		Icon,
		Header,
		Pagination,
		Segment
	} from 'semantic-ui-react'

const defaultPageSizeOptions = [10,30,50,100,200,1000,10000]

export function WrappedListPagination({children, top = true, bottom = true, ...props}) {
	return (<>
		{top && <ListPagination {...props} />}
		{children}
		{bottom && <ListPagination {...props}/>}
	</>);
}

class ListPagination extends Component {

	constructor(props) {
		super(props);

	}
	
	setPage = (e, { activePage }) => {
		console.log("setPage(%o)",activePage)
		const { onPageChange } = this.props
		onPageChange && onPageChange(activePage)
	}


	render() {
		const { data, page , pages = 1, total, 
				pageSize, showPageSizeSelector = false, 
				pageSizeOptions = defaultPageSizeOptions,
				boundaryRange = 5 } = this.props
		
		if (!data)
			return null
			
		return (
			<Segment basic clearing style={{padding:0}}>
				<div className="font-bold list-pagination flex items-center justify-between">
					<div>
						Total records: {total} &nbsp; &nbsp;
						<span/>
						Showing records {(page -1 ) * pageSize + 1}-{Math.min((page) * pageSize, total)}  &nbsp; &nbsp;
						<span/>

						{showPageSizeSelector && 
							<Dropdown 
								value={pageSize}
								text={'Page Size '+pageSize} 
								options={pageSizeOptions.map((o,i)=>({ text: o.toString(), value: o }))}
								simple item 
								onChange={ (event, data) => {this.props.onPageSizeChange && this.props.onPageSizeChange(parseInt(data.value))} }
							/>}
					</div>
					{pages  > 0 && <Pagination
							totalPages={pages}
							activePage={page}
							onPageChange={ this.setPage }
							boundaryRange={boundaryRange}
							siblingRange={3}
							size='small'
							ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
							firstItem={page > 2 ? { content: <Icon name='angle double left' />, icon: true } : null}
							prevItem={page > 1 ? { content: <Icon name='angle left' />, icon: true } : null}
							nextItem={page < pages ? { content: <Icon name='angle right' />, icon: true } : null}
							lastItem={page < pages-1 ? { content: <Icon name='angle double right' />, icon: true } : null}
							className='right floated clearing'
						/>}
				</div>
			</Segment>

		);
	}


}


export default ListPagination;

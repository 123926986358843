import React, { Component, useState } from 'react';
import {
		Card,
		Modal,
		Image,
		Label,
		List,
		Form,
		Container,
		Input,
		Button,
		Table,
		Icon,
		Message,
		Segment,
		Header,
		Loader,
		Popup,
		Menu,
		Dimmer,
		TextArea,
	} from 'semantic-ui-react'

import moment from 'moment'

import API_service from '../providers/API_service'

import file_to_base64 from '../helpers/file_to_base64';
import UploadButton from '../UIelems/UploadButton'
import PathoResultsInput from 'views/patho/PathoResultsInput'
import PathoResultsPatientReport from 'views/patho/PathoResultsPatientReport'
import FileDropArea from 'xAppLib/Files/FileDropArea'
import patho_model from 'models/patho_model'
import {supportsPDFs} from 'xAppLib/helpers/pdf'
import ShareDownloadButton from 'xAppLib/UIelems/ShareDownloadButton'
import { useToggleState } from 'xAppLib/Hooks';


const TYPES = {
	"stor":{
		name:'File',
		icon:"file alternate outline"
	},
	"txt":{
		name:'Note',
		icon:"sticky note outline"
	},
	"PIT":{
		name:'Results Text (PIT)',
		icon:"file powerpoint outline"
	},
	"patho":{
		name:'Pathology Result',
		icon:"lab"
	},
}

const suiValue = fn => (e, { name, value }) => fn({ [name]: value })

function api_file(data) {
	const url_encoded_file_fn = encodeURIComponent(data.file_fn)
	return API_service.API_BASE_URL+`file/doc/view/${data.bct}/${url_encoded_file_fn}?Authorization=Bearer ${app.user.token}`
}

const NoteFile = ({data,onChange, editMode}) => {
	const {comn,txt} = data
	
	return <Form>
			<Form.Input fluid label='Description'
			 value={comn} 
			 name='comn'
			 readonly={!editMode}
			 onChange={editMode && suiValue(onChange) || undefined} 
			 />
			<Form.TextArea label="Note"
				value={txt} 
				name='txt'
				readonly={!editMode}
				onChange={editMode && suiValue(onChange) || undefined} 
				style={{minHeight:'200px'}}
			/>
	</Form>
};

const PITText = ({data,onChange, editMode}) => {
	const {comn,txt} = data

	//	ref http://www.healthintersections.com.au/wp-content/uploads/2011/05/PIT_v0.7.pdf

	const txt_arr = txt.split("\n");
	
	return !editMode &&
		<>
			<h3>{comn}</h3>
			<div>
				{txt_arr.filter( l => l.startsWith('30') ).length && <h4><b>Result</b></h4> || ''}
				{txt_arr.filter( l => l.startsWith('30') ).map( l => <>{l.replace(/^\d+\s*/, '')}<br/></> )}

				{txt_arr.filter( l => l.startsWith('31') ).length && <h4>Serial Results</h4> || ''}
				{txt_arr.filter( l => l.startsWith('31') ).map( l => <>{l.replace(/^\d+\s*/, '')}<br/></> )}

				<hr/>

				{txt_arr.filter( l => l.startsWith('2') ).length && <h4><b>Specimen Details and Request Details</b></h4> || ''}
				{txt_arr.filter( l => l.startsWith('2') ).map( l => <>{l.replace(/^\d+\s*/, '')}<br/></> )}

				{txt_arr.filter( l => l.startsWith('10') ).length && <h4><b>Patient Details</b></h4> || ''}
				{txt_arr.filter( l => l.startsWith('10') ).map( l => <>{l.replace(/^\d+\s*/, '')}<br/></> )}

				{txt_arr.filter( l => l.startsWith('11') ).length && <h4><b>Specimen Reference / Medicare</b></h4> || ''}
				{txt_arr.filter( l => l.startsWith('11') ).map( l => <>{l.replace(/^\d+\s*/, '')}<br/></> )}

				{txt_arr.filter( l => l.startsWith('12') ).length && <h4><b>Referring Doctor</b></h4> || ''}
				{txt_arr.filter( l => l.startsWith('12') ).map( l => <>{l.replace(/^\d+\s*/, '')}<br/></> )}
			</div>
			{!txt_arr.filter( l => l.startsWith('30') || l.startsWith('31') || l.startsWith('2') || l.startsWith('10') || l.startsWith('11') || l.startsWith('12') ).length &&
				<div>
					<h2>raw</h2>
					{txt_arr.map( l => <>{l}<br/></> )}
				</div>
				|| ''
			}
		</>
		|| <Form>
			<Form.Input fluid label='Description'
			 value={comn} 
			 name='comn'
			 readonly={!editMode}
			 onChange={editMode && suiValue(onChange) || undefined} 
			 />
			<Form.TextArea label="Note"
				value={txt} 
				name='txt'
				readonly={!editMode}
				onChange={editMode && suiValue(onChange) || undefined} 
				style={{minHeight:'200px'}}
			/>
	</Form>
};

const FileFile = ({data,file,onChange, editMode}) => {
	const {comn} = data


	if (!editMode) {
		const is_pdf = data.file_fn.toLowerCase().includes('pdf')

		const url = api_file(data)
	
		const view_url = is_pdf && !supportsPDFs() && `/pdf-view/viewer.html?file=`+encodeURIComponent(url) || url

		return <iframe src={view_url} style={{width:'100%',height:'70vh'}} frameBorder="0"></iframe>
	}
		
	
	return <Form>
	
			<Form.Input fluid label='Description'
				 value={comn} 
				 name='comn'
				 onChange={suiValue(onChange)} 
				 />
			 {file && <div className="field">
				<label>File Details</label>

				<List>
					<List.Item>
						<List.Header>Name</List.Header>
						{file.name}
					</List.Item>
					<List.Item>
						<List.Header>Size</List.Header>
						{file.size}
					</List.Item>
					<List.Item>
						<List.Header>Type</List.Header>
						{file.type}
					</List.Item>
				</List>
			</div>}
			
	</Form>
};

const PathoFile = (props) => {
	const { data: { value }, med_db_data, onChange, editMode, extra } = props;

	return editMode ? 
			<PathoResultsInput value={value} med_db_data={med_db_data} onChange={ value => onChange({ value })} />
			: 
			<PathoResultsPatientReport value={value} med_db_data={med_db_data} extra={extra} sid={extra?.sid} />
};


export default class Files extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			// data_list: props.data ? [...props.data] : [],
			files: [],
			comn:'', 
			txt:'',
			data:{},
			index:null
		};
	}

	// 		--------------------------------		--------------------------------		---------
	
	
	async upl_file(file, comn) {
		
		const { target = 'files-bct', level = 'doc' } = this.props

		console.log("uploading ",file);

		const file64 = await file_to_base64(file).catch(e => console.error(e));

		this.setState({uploading:true})

		const res = await API_service.load_data(
								`file/${level}/store/${target}`,
								{file_data: file64, file_name: file.name, file_type: file.type, file_size: file.size, file_tm: file.lastModified, comn},
								// r => DEBUG && console.log('/photo/store/cosm-trt-pts/snap res', r)
							)

		this.setState({uploading:false})

		return res

	}
	
	onReceiveFiles = (files) => {
		if (files && files.length) {
			const [file,...rest] = files
			this.setState({index:null,file:files[0],files:rest,data:{type:'stor',comn:''}})
		}
		
	}
	
	onEditNote = () => {
		this.setState({index:null,data:{type:'txt',comn:'',txt:''}})
	}
	
	onEditPIT = _ => this.setState({index:null,data:{type:'PIT',comn:'Patho res raw text',txt:''}})
	
	onEditPatho = () => {
		const i = this.files.findIndex(f=>f.data.type=="patho")
		if (i == -1)
			this.setState({index:null,data:{type:'patho',value:null}})
		else 
			this.open_file(this.files[i].data,i)
	}
	
	onClearEdit = () => {
		this.setState({file:null,files:[],data:{},edit:false})
	}


	async onSave()  {
		if (this.read_only)
			return

		const { onAddFile } = this.props
		const { file = {}, index } = this.state
		const tm = moment().format()
		let data = {...this.state.data, tm}
		
		switch (data.type) {
			case 'stor':
				const { name, size, type } = file
				if (!data.comn) {
					data.comn = file.name
					this.setState({data:{...data,comn:data.comn}})
				}
				const upl_res = await this.upl_file(file,data.comn)
				if (upl_res.res!='ok') {
					alert("Could not upload file")
					return
				}
				delete upl_res.res
				Object.assign(data, upl_res)
				break;
			case 'txt':
				break;
			case 'patho':
				break;
		}
		if (data) {
			
			onAddFile?.(data,index)
			
			const files = this.state.files
			if (this.state.edit)
				this.setState({edit:false})
			else {
				this.onClearEdit()
			}
			if (files.length) {
				setTimeout(_=>{
					this.onReceiveFiles(files)
				},150)
			} 
		}
	}
	
	async onDelete() {
		const { index } = this.state
		const { onRemoveFile } = this.props
		if (index!=undefined && confirm('Are you sure?')) {
			onRemoveFile?.(index)
			this.onClearEdit()
		}
	}
	
	
	onInputChange = v => this.setState(state=>({data:{...state.data,...v}}))
	
	
	get files() {
		const { data } = this.props
		return data.map((data,index)=>({
			icon:TYPES[data.type]?.icon,
			time:moment(data.tm).format('D/M/YY HH:mm'),
			title:data.comn || TYPES[data.type]?.name,
			index,
			data
		}))
	}
	
	get can_save() {
		const { data, file } = this.state
		
		switch (data.type) {
			case 'stor':
				return !!file
			case 'txt':
			case 'PIT':
				return data.comn != '' && data.txt != ''
			case 'patho':
				return !!data.value
		}
		
		return false
	}
	
	get read_only() {
		const { data, index } = this.state
		const { mode } = this.props
		return mode == 'view_only' || (index != undefined && data.type == 'stor')
	}
	
	get is_new() {
		const { index } = this.state
		return index == null
	}
	
	get edit_mode() {
		if (this.read_only)
			return false
		if (this.is_new)
			return true
		const { edit } = this.state
		return edit
	}
	
	open_file(data,index) {
		this.setState({data, index})
		if (data.type=='patho' && !patho_model.results_complete(data.value))
			this.setState({edit:true})
		
	}
	
	// 		--------------------------------		--------------------------------		---------
	
	render() {
		const { mode, data, children, scr_obj } = this.props
		const { show } = this.state
		
		return 	<FileDropArea onDropFiles={this.onReceiveFiles}>
			
			{this.render_list()}

			{mode != 'view_only' &&
				this.render_add()
			}
			{
				this.render_modal()
			}
		</FileDropArea>
	}
	
	render_list() {
		const { mode, data, children } = this.props
		const { show } = this.state
		let { view_as } = this.props
		
		if (!view_as) {
			if (mode != 'view_only' && data.length > 0)
				view_as = 'list'
			else if (mode == 'view_only' && data.length > 0 )
				view_as = 'cards'
		}

		return view_as == 'list' && 
					<List>
						{
							this.files.map( (p, i) => <List.Item
									key={'f'+i}
									onClick={_=>this.open_file(p.data,i)}
									style={{cursor:'pointer'}}
								>
								 <List.Icon name={p.icon} />
									<List.Content>
										{p.title}<br/>
										<small>{p.time}</small>
									</List.Content>
								
							</List.Item> )
						}
					</List>
				|| 
				view_as == 'cards' && 
					<Card.Group>
						{
							this.files.map( (p, i) => <Card
										key={'f'+i}
										onClick={_=>this.open_file(p.data,i)}
										meta = {p.time}
										style={{width:'auto',height:'auto'}}
										header = {<Header><Icon name={p.icon} />{p.title}</Header>}
									/>
								)
						}
					</Card.Group>
			
	}
	
	render_add() {
		const { data, index, file,uploading } = this.state
		const { children, addNotes = true, addPIT = true, addFiles = true, addPatho = false, scr_obj } = this.props
		const { type } = data
		const hasPatho = Boolean(this.files.filter(f=>f.data.type=="patho").length)
		
		
		return <React.Fragment>
				<div className="ui buttons" >
					{addFiles && <UploadButton onFile={this.onReceiveFiles} icon={TYPES['stor'].icon} label="+ File" multiple  />}
					{addNotes && <Button color="green" content="+ Note" icon={TYPES['txt'].icon} onClick={this.onEditNote} compact />}
					{addPIT && <Button color="green" content="+ PIT" icon={TYPES['PIT'].icon} onClick={this.onEditPIT} compact />}
					{addPatho && <Button color="green" content={hasPatho ? "Edit Patho" : "+ Patho"} icon={TYPES['patho'].icon} onClick={this.onEditPatho} compact />}
				</div>
				{children}
			
			</React.Fragment>
			
		
	}
	
	render_modal() {
		const { data, index, file, uploading } = this.state
		const { children, addNotes = true, addFiles = true, addPatho = false, scr_obj, onRemoveFile } = this.props
		const { type } = data
		
		const med_db_data = scr_obj && {m:scr_obj.med?.id,...scr_obj.med_db_data}
		
		return type && <Modal open={true} 
							onClose={this.onClearEdit}
							closeIcon>
						<Modal.Header style={{display:'flex'}}>
							<div><Icon name={TYPES[type].icon} /> {this.is_new && 'Add '}{TYPES[type].name} {type == 'patho' && ` for ${scr_obj?.med_db_data?.name}`}{type == 'stor' && file && file.name}</div>
							{data.file_fn && <Button size='small'
									as='a'
									style={{marginLeft:'auto'}} 
									href={API_service.API_BASE_URL+`file/doc/view/${data.bct}/${data.file_fn}?Authorization=Bearer ${app.user.token}`} 
									icon="external alternate"
									target='_blank' />}
						</Modal.Header>
						<Modal.Content>
								{type == 'patho' && <PathoFile editMode={this.edit_mode} data={data} med_db_data={med_db_data} extra={scr_obj} onChange={this.onInputChange} />}
								{type == 'txt' && <NoteFile editMode={this.edit_mode} data={data} onChange={this.onInputChange} />}
								{type == 'PIT' && <PITText editMode={this.edit_mode} data={data} onChange={this.onInputChange} />}
								{type == 'stor' && <FileFile editMode={this.edit_mode} data={data} file={file} onChange={this.onInputChange} />}
						</Modal.Content>
						<Modal.Actions>
							{(app.user?.claims?.admin || app.acl.is_supp_patho) && !this.is_new && onRemoveFile && <Button type="button" color="red" loading={uploading} onClick={_=>this.onDelete()} style={{marginRight:'auto'}}>Delete</Button>}
						
							{this.edit_mode && !this.is_new && <Button type="button" color="orange" loading={uploading} onClick={_=>this.setState({edit:false})}>View</Button>}
							
							{!this.edit_mode && !this.read_only && <Button type="button" color="orange" loading={uploading} disabled={!this.can_save} onClick={_=>this.setState({edit:true})}>Edit</Button>}
							
							{this.edit_mode && <Button type="button" color="green" loading={uploading} disabled={!this.can_save} onClick={_=>this.onSave()}>Save</Button>}
					
							{!this.edit_mode && type=='stor' && <ShareDownloadButton url={api_file(data)} filename={data.file_fn}/>}
						</Modal.Actions>
					</Modal> || null
			
		
	}
}

import React from "react";

export default function SIcon({cls='', svg_cls='', name, color='currentColor', size, str, bold, }) {

  let cont, s;
 
  switch(size) {
    case 'xxs': cont = 'h-[20px] w-[20px]', s = 'md:stroke-[.75px]'
      break;
    case 'xs': cont = 'h-[24px] w-[24px]', s = 'md:stroke-[1px]'
      break;
    case 's': cont = 'h-[32px] w-[32px]', s = 'md:stroke-[1.5px]'
      break;
    case 'sm':  cont = 'h-[32px] w-[32px] md:h-[40px] md:w-[40px]', s = 'md:stroke-[2px]'
      break;
    case 'md': cont = 'h-[40px] w-[40px] md:h-[48px] md:w-[48px]', s = 'md:stroke-[2.5px]'
      break;
    case 'lg': cont = 'h-[48px] w-[48px] md:h-[56px] md:w-[56px]', s = 'md:stroke-[3px]'
      break;
    case 'xl': cont = 'h-[56px] w-[56px]', s = 'stroke-[3px]'
      break;
    default: cont = '', s = 'md:stroke-[2px]';
  }

  let cl = bold ? 'stroke-[3px]' : `stroke-2 ${str || s}`;
  let style = { stroke: color, strokeLinecap: "round", strokeLinejoin: "round"};
  
  let path;
  switch(name) {
    case 'alarm-bell': path = <path d="M20 43.5a4.174 4.174 0 008.01 0M24 6V1.5M24 6a15 15 0 0115 15c0 14.092 3 16.5 3 16.5H6S9 33.668 9 21A15 15 0 0124 6v0z"></path>
    break;

    case 'alert-circle': path = <path fill={color} stroke="none" d="M24 0A24.48 24.48 0 006.94 7.3 23.7 23.7 0 000 24.42 23.56 23.56 0 0023.6 48h.4a24.22 24.22 0 0024-24.42A23.54 23.54 0 0024 0zm-3 33.08A2.958 2.958 0 0124 30a3.06 3.06 0 013.04 2.94A2.94 2.94 0 0124.1 36a3.06 3.06 0 01-3.1-2.92zm1-8v-12a2 2 0 114 0v12a2 2 0 01-4 0z"></path>
    break;

    case 'arrange-list-asc': 
      path = <><path d="M22.56 36.96h20.16m-20.16 7.68H45.6M22.56 29.28h15.36M22.56 21.6h11.52"></path>
      <path className={cl} d="M14.88 46.556v-43.2M6.24 12l8.64-8.64L23.52 12"></path></>
      break;

    case 'arrange-list-desc': 
      path = <><path d="M14.88 46.556v-43.2m-8.64 34.56l8.64 8.64 8.64-8.64"></path>
      <path className={cl} d="M22.56 20.64h20.16m-20.16 7.68H45.6M22.56 12.96h15.36M22.56 5.28h11.52"></path></>
      break;

    case 'bin': path = <path d="M2 10h44M28.5 2h-9a3 3 0 00-3 3v5h15V5a3 3 0 00-3-3zm-9 33.5v-15m9 15v-15m9.22 22.74A2.98 2.98 0 0134.74 46H13.26a2.98 2.98 0 01-2.98-2.76L7.5 10h33l-2.78 33.24z"></path>
      break;
   
    case 'burger-menu':
      path =  <path d="M4.5 36.006h39m-39-12h39m-39-12h39"></path>
      break;

    case 'close': 
      path = <path d="M1.5 46.498l45-45m0 45l-45-45"></path>
      break;

    case 'cog-settings': 
      path = <><path d="M21.092 4.876a3.913 3.913 0 005.816 0L28.8 2.8a3.918 3.918 0 016.82 2.826l-.142 2.8a3.916 3.916 0 004.102 4.108l2.8-.142a3.918 3.918 0 012.82 6.82l-2.084 1.88a3.92 3.92 0 000 5.818l2.084 1.88a3.918 3.918 0 01-2.826 6.82l-2.8-.142a3.915 3.915 0 00-4.112 4.112l.142 2.8A3.918 3.918 0 0128.8 45.2l-1.882-2.082a3.92 3.92 0 00-5.816 0l-1.89 2.082a3.918 3.918 0 01-6.812-2.816l.144-2.8a3.917 3.917 0 00-4.112-4.112l-2.8.142A3.916 3.916 0 012.8 28.8l2.082-1.88a3.92 3.92 0 000-5.818L2.8 19.212A3.916 3.916 0 015.618 12.4l2.8.142a3.916 3.916 0 004.116-4.12L12.4 5.62a3.918 3.918 0 016.812-2.82l1.88 2.076z"></path>
      <path d="M15 24.002a9 9 0 1018 0 9 9 0 00-18 0v0z"></path></>
      break;

    case 'calendar': 
      path = <><path d="M43.5 7.5h-39a3 3 0 00-3 3v33a3 3 0 003 3h39a3 3 0 003-3v-33a3 3 0 00-3-3zm-42 12h45m-33-7.5V1.5m21 10.5V1.5"></path>
      <path d="M11.25 28.5a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m0 12a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m12.75-9a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m0 12a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m12.75-9a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m0 12a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5"></path></>
      break;

    case 'check-circle-bold': 
      path = <path fill={color} stroke="none" d="M24 0a24 24 0 100 48 24 24 0 000-48zm13.86 16.4l-13.7 18.58a2.001 2.001 0 01-2.86.38l-9.78-7.82a2 2 0 01-.3-2.82 2 2 0 012.78-.3l8.16 6.52L34.64 14a2 2 0 012.78-.42 1.999 1.999 0 01.44 2.82z"></path>
      break;

    case 'chevron': 
      path =<path d="M46.5 14.622L25.06 36.06a1.499 1.499 0 01-2.12 0L1.5 14.622"></path>
      break;

    case 'med-cert': 
      path = <><path d="M24.5 32.688H4.126c-.345 0-.676-.066-.994-.197a2.573 2.573 0 01-.842-.563 2.57 2.57 0 01-.563-.842 2.571 2.571 0 01-.198-.994V4.127c0-.345.066-.676.198-.994.132-.318.32-.599.563-.842a2.57 2.57 0 01.842-.563 2.57 2.57 0 01.994-.198H37.88c.344 0 .676.066.994.198.318.132.599.32.842.563.244.243.431.524.563.842.132.318.198.65.198.994v10.386m-13.98 25.965H14.513l1.298-7.79m-5.193 7.79h3.895m.998-31.458h9.987m-14.98 6.99h19.973m-16.977 6.991h11.984"></path>
      <path d="M46.47 28.494a7.974 7.974 0 01-1.347 4.439 7.967 7.967 0 01-2.204 2.204 7.975 7.975 0 01-4.439 1.346 7.966 7.966 0 01-3.057-.608 7.976 7.976 0 01-3.586-2.942 7.97 7.97 0 01-1.346-4.439 7.966 7.966 0 01.608-3.057 7.97 7.97 0 012.942-3.586 7.969 7.969 0 012.88-1.193 7.972 7.972 0 014.617.455 7.968 7.968 0 013.585 2.942 7.969 7.969 0 011.193 2.88c.102.515.154 1.034.154 1.559z"></path>
      <path d="M39.978 28.494a1.484 1.484 0 01-.438 1.06 1.484 1.484 0 01-1.06.438 1.484 1.484 0 01-1.059-.439 1.484 1.484 0 01-.439-1.059 1.484 1.484 0 01.44-1.06 1.484 1.484 0 011.058-.438 1.484 1.484 0 011.06.439c.14.14.248.302.324.486.076.183.114.374.114.573zm-6.491 6.238V46.47l4.994-3.995 4.993 3.995V34.732"></path></>
      break;

    case 'certificate-2': 
      path = <><path d="M13.5 37.5V42A4.5 4.5 0 019 46.5h21a4.5 4.5 0 004.5-4.5V7.82c0-1.132.876-6.32 6-6.32a6 6 0 016 6v6h-12m6-12h-18M5.474 17.966l-1.61 11.758a1.501 1.501 0 002.538 1.332l4.098-4.1 4.1 4.1a1.503 1.503 0 002.538-1.332l-1.6-11.758"></path>
      <path d="M10.5 19.5a9 9 0 100-18 9 9 0 000 18z"></path>
      <path strokeMiterlimit="8" d="M10.5 11.25a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5"></path></>
      break;

    case 'chart-heart': 
      path = <><path d="M40.484 25.502V10.519c0-.397-.076-.78-.228-1.147a2.967 2.967 0 00-.65-.972 2.969 2.969 0 00-.972-.65 2.968 2.968 0 00-1.147-.228h-5.994M7.517 37.488v5.993c0 .398.076.78.228 1.147.152.367.368.691.65.972.28.281.605.498.972.65.367.152.75.228 1.147.228h26.973c.397 0 .78-.076 1.147-.228a2.97 2.97 0 00.972-.65 2.97 2.97 0 00.65-.972c.152-.367.228-.75.228-1.147v-5.993M16.507 7.522h-5.994c-.397 0-.78.076-1.147.229a2.97 2.97 0 00-.972.65 2.969 2.969 0 00-.65.971c-.152.367-.228.75-.228 1.147v14.983"></path>
      <path d="M1.522 31.495h11.525a.7.7 0 00.671-.413l3.509-7.018c.147-.295.384-.433.713-.414.328.018.55.182.662.492l4.763 13.1c.112.326.34.494.683.506.343.011.582-.14.716-.457l4.595-10.339a.696.696 0 01.668-.444.696.696 0 01.687.414l2.078 4.16a.7.7 0 00.671.413h13.015M28.406 4.526a4.344 4.344 0 00-.662-1.263 4.345 4.345 0 00-1.032-.983c-.399-.27-.83-.47-1.294-.599A4.347 4.347 0 0024 1.53a4.348 4.348 0 00-1.415.153 4.388 4.388 0 00-2.32 1.582c-.29.384-.51.805-.66 1.261h-1.6a1.485 1.485 0 00-1.059.439 1.484 1.484 0 00-.439 1.06v5.993a1.485 1.485 0 00.44 1.06 1.485 1.485 0 001.059.438h11.988a1.485 1.485 0 001.06-.439 1.483 1.483 0 00.439-1.06V6.025a1.485 1.485 0 00-.44-1.059 1.486 1.486 0 00-1.06-.439h-1.587 0z"></path></>
      break;

    case 'chart-pills': 
      path = <><path d="M36.268 25.526a5.966 5.966 0 016.507-1.296 5.97 5.97 0 011.941 9.739l-10.56 10.546a5.968 5.968 0 11-8.434-8.442l10.546-10.546v0zm-5.278 5.277l8.443 8.44m-28.92-18.262H28.46M10.513 28.46h11.964m-11.964 7.479h5.981"></path>
      <path d="M18.489 46.41H4.529a2.991 2.991 0 01-2.992-2.992V12.007a2.992 2.992 0 012.991-2.992h7.481a7.479 7.479 0 1114.958 0h7.478a2.992 2.992 0 012.992 2.992v6.481"></path>
      <path d="M19.486 9.015a.748.748 0 110-1.495m0 1.495a.748.748 0 100-1.495"></path></>
      break;

    case 'clock': 
      path = <><path d="M24 46.5c12.426 0 22.5-10.074 22.5-22.5S36.426 1.5 24 1.5 1.5 11.574 1.5 24 11.574 46.5 24 46.5z"></path>
      <path d="M24 12.052V24h-8M1.5 24h4.892m-3.178-8.616l4.52 1.874m.364-9.176l3.456 3.46m3.85-8.336l1.868 4.52m6.75-6.226l-.006 4.892"></path></>
      break;

    case 'data-transfer-vertical': 
      path = <path d="M18 21v25.5m6-6l-6 6-6-6M30 27V1.5m-6 6l6-6 6 6"></path>
      break;

    case 'delete': 
      path =  <path d="M1.496 24a22.5 22.5 0 1045 0 22.5 22.5 0 00-45 0v0zm13.5 9l17.998-18m.002 18L14.994 15"></path>
      break;

    case 'delivery-truck': 
      path = <><path d="M34.5 25.5c0 .398.076.78.228 1.148.152.367.369.692.65.973.282.282.606.498.973.65.368.153.75.229 1.148.229.398 0 .78-.076 1.148-.229a2.97 2.97 0 00.974-.65 3.005 3.005 0 00.65-3.269 2.974 2.974 0 00-.65-.973 2.973 2.973 0 00-.974-.65A2.972 2.972 0 0037.5 22.5c-.398 0-.78.076-1.148.229a2.983 2.983 0 00-.973.65 2.97 2.97 0 00-.65.973A2.97 2.97 0 0034.5 25.5zm-26.999 0a3.005 3.005 0 003 3c.398 0 .78-.076 1.148-.229.367-.152.692-.369.973-.65a2.97 2.97 0 00.65-.973 2.97 2.97 0 00.229-1.148c0-.398-.076-.78-.229-1.148a2.972 2.972 0 00-.65-.973 2.973 2.973 0 00-.973-.65A2.971 2.971 0 0010.5 22.5c-.398 0-.78.076-1.148.229a2.963 2.963 0 00-.973.65 2.971 2.971 0 00-.65.973A2.97 2.97 0 007.5 25.5zM39 13.5H27"></path>
      <path d="M40.5 25.5h3a2.994 2.994 0 002.12-.878 2.97 2.97 0 00.65-.974 2.97 2.97 0 00.23-1.148v-3c0-2.881-.984-2.776-7.5-6l-1.864-7.455a5.96 5.96 0 00-.79-1.815 5.96 5.96 0 00-1.338-1.458 5.962 5.962 0 00-1.74-.944 5.955 5.955 0 00-1.951-.327H4.5c-.397 0-.78.077-1.148.229a2.973 2.973 0 00-.973.65 2.971 2.971 0 00-.65.973A2.97 2.97 0 001.5 4.501v18c0 .397.077.78.229 1.147.152.368.369.692.65.973.281.282.606.498.973.65.368.153.75.229 1.148.229h3m27 0h-21M27 1.501V25.5"></path>
      <path d="M21 7.5H7.501v6h13.5v-6z"></path></>
      break;

    case 'delivery-pill': 
      path = <><path d="M26.426 12.46l9.115 9.114a6.42 6.42 0 002.089 1.354 6.439 6.439 0 004.885-.04 6.432 6.432 0 002.862-2.353 6.422 6.422 0 00.962-2.296 6.424 6.424 0 00-.33-3.69 6.423 6.423 0 00-1.354-2.09l-9.114-9.114a6.428 6.428 0 00-3.29-1.704 6.437 6.437 0 00-4.786.982 6.429 6.429 0 00-2.354 2.862 6.442 6.442 0 001.315 6.975v0zm4.557 4.557l9.115-9.115m-1.557 33.567h3.002c.265 0 .52-.051.765-.153a1.98 1.98 0 001.083-1.083c.101-.245.152-.5.152-.765v-9.003M23.537 19.462h-4.001a1.984 1.984 0 00-1.848 1.235c-.102.245-.153.5-.153.765V41.47h11.003m-21.006-.002H3.53a1.984 1.984 0 01-1.415-.586 1.98 1.98 0 01-.433-.649 1.982 1.982 0 01-.152-.765v-6.766c0-.379.1-.73.299-1.052.2-.322.468-.567.807-.737l2.895-1.448 2.449-4.902c.17-.338.415-.608.737-.807.322-.2.673-.299 1.051-.3h7.767v18.006m21.006.006c0 .664-.127 1.302-.38 1.914a4.957 4.957 0 01-1.085 1.623c-.469.47-1.01.83-1.622 1.084a4.953 4.953 0 01-1.914.381 4.954 4.954 0 01-1.914-.38 4.957 4.957 0 01-1.623-1.085 4.955 4.955 0 01-1.084-1.623 4.956 4.956 0 01-.38-1.913c0-.664.126-1.302.38-1.915a4.957 4.957 0 011.084-1.622 4.954 4.954 0 011.623-1.084 4.954 4.954 0 011.914-.381c.663 0 1.3.127 1.914.38a4.956 4.956 0 011.622 1.085c.47.469.83 1.01 1.084 1.622.254.613.381 1.251.381 1.914z"></path>
      <path d="M17.535 41.468c0 .664-.127 1.302-.38 1.914a4.954 4.954 0 01-1.085 1.623 4.94 4.94 0 01-1.623 1.084 4.945 4.945 0 01-1.914.381 4.954 4.954 0 01-1.914-.38 4.956 4.956 0 01-1.622-1.085 4.954 4.954 0 01-1.085-1.623 4.953 4.953 0 01-.38-1.913c0-.664.127-1.302.38-1.915a4.956 4.956 0 011.085-1.622 4.953 4.953 0 011.622-1.084 4.954 4.954 0 011.914-.381c.664 0 1.302.127 1.914.38a4.954 4.954 0 011.623 1.085c.469.469.83 1.01 1.084 1.622.254.613.38 1.251.38 1.914z"></path></>
      break;

    case 'duplicate': 
      path = <><path d="M33.5 9V3.5a2 2 0 00-2-2h-28a2 2 0 00-2 2v28a2 2 0 002 2H9"></path>
      <path d="M14.5 16.5a2 2 0 012-2h28a2 2 0 012 2v28a2 2 0 01-2 2h-28a2 2 0 01-2-2v-28z"></path></>
      break;

    case 'external': path = <path d="M18.5 29.046L46.5 1.5m0 15.742V1.5h-16m-6.25 10h-21a1.75 1.75 0 00-1.75 1.75v31.5a1.75 1.75 0 001.75 1.75h31.5a1.75 1.75 0 001.75-1.75v-21"></path>
        break;

    case 'female-waist': 
      path = <><path d="M10.5 1.5s3 3 3 12c0 10.5-9 12-9 24a13.14 13.14 0 003 9m30-45s-3 3-3 12c0 10.5 9 12 9 24a13.14 13.14 0 01-3 9m-39-39l4.94 4.94a1.499 1.499 0 010 2.12L1.5 19.5m45-12l-4.94 4.94a1.497 1.497 0 000 2.12l4.94 4.94m-22.489 27v-6"></path>
      <path d="M42.188 30.388C34.558 32.288 24 40.5 24 40.5S13.442 32.288 5.812 30.388h36.376v0z"></path>
      <path strokeMiterlimit="8" d="M24.011 21.75a.75.75 0 110-1.5m0 1.5a.75.75 0 000-1.5"></path></>
      break;

    case 'diet-scales': 
      path = <><path d="M40.5 1.5h-33a6 6 0 00-6 6v33a6 6 0 006 6h33a6 6 0 006-6v-33a6 6 0 00-6-6z"></path>
      <path d="M14.58 15.68a1.52 1.52 0 001.42.82h16a1.52 1.52 0 001.34-.82l2-3.84a1.52 1.52 0 00-.72-2A26.36 26.36 0 0024 7.5a26.36 26.36 0 00-10.62 2.3 1.52 1.52 0 00-.72 2l1.92 3.88v0zM21 26.26c0-2-2.36-3.76-5.26-3.76s-5.24 1.68-5.24 3.76v10.5a3.76 3.76 0 007.5 0 14.9 14.9 0 012.58-9.04 2.94 2.94 0 00.42-1.46v0zm6 0c0-2 2.36-3.76 5.24-3.76 2.88 0 5.26 1.68 5.26 3.76v10.5a3.76 3.76 0 01-7.5 0 14.9 14.9 0 00-2.58-9.04 2.94 2.94 0 01-.42-1.46v0zM19.5 7.9v2.6m9-2.6v2.6"></path></>
    break;

    case 'doc-check': 
      path = <path d="M42.253 32.377a9.846 9.846 0 01-.751 3.78 9.865 9.865 0 01-5.346 5.345 9.847 9.847 0 01-3.779.752 9.853 9.853 0 01-3.78-.752 9.855 9.855 0 01-4.432-3.638 9.844 9.844 0 01-1.475-3.56 9.854 9.854 0 010-3.854 9.86 9.86 0 012.703-5.056 9.855 9.855 0 015.057-2.703 9.86 9.86 0 015.707.562 9.86 9.86 0 014.432 3.637 9.858 9.858 0 011.664 5.487zm4.234 14.11l-7.126-7.126M1.513 34.494a13.49 13.49 0 013.536-9.097 13.482 13.482 0 015.499-3.631 13.48 13.48 0 016.562-.596 13.518 13.518 0 013.192.915M23.25 9.758a8.228 8.228 0 01-1.39 4.581 8.224 8.224 0 01-2.274 2.275 8.224 8.224 0 01-2.972 1.231 8.226 8.226 0 01-4.764-.47 8.213 8.213 0 01-2.675-1.787 8.229 8.229 0 01-2.257-4.221 8.229 8.229 0 01.47-4.764 8.223 8.223 0 011.787-2.675 8.224 8.224 0 014.222-2.257 8.227 8.227 0 014.763.47 8.224 8.224 0 013.7 3.036 8.23 8.23 0 011.39 4.581zM11.007 21.66v9.836m1.999 0c0 .265-.05.52-.152.765a1.98 1.98 0 01-.433.648 1.978 1.978 0 01-.649.433 1.979 1.979 0 01-1.53 0 1.98 1.98 0 01-.648-.433 1.98 1.98 0 01-.433-.648 1.979 1.979 0 01-.153-.765c0-.265.051-.52.153-.765a1.981 1.981 0 011.082-1.082c.244-.102.5-.152.764-.152a1.986 1.986 0 011.847 1.234c.101.245.152.5.152.765v0z"></path>
      break;

    case 'doc-steth': 
      path = <><path d="M34.493 28.497a19.47 19.47 0 018.72 7.179 19.446 19.446 0 012.436 5.162 19.442 19.442 0 01.836 5.647m-38.975 0a19.253 19.253 0 011.573-7.606 19.253 19.253 0 014.424-6.384"></path>
      <path d="M34.493 25.499v4.497a7.477 7.477 0 01-1.263 4.164 7.476 7.476 0 01-3.364 2.76 7.478 7.478 0 01-4.33.427 7.478 7.478 0 01-3.838-2.051 7.488 7.488 0 01-2.195-5.3V25.5M9.51 19.503c0 .53-.102 1.04-.305 1.53a3.96 3.96 0 01-.866 1.296 3.96 3.96 0 01-1.297.867c-.49.203-1 .304-1.53.304s-1.04-.101-1.53-.304a3.96 3.96 0 01-1.297-.867 3.96 3.96 0 01-.866-1.296 3.96 3.96 0 01-.304-1.53c0-.53.101-1.04.304-1.53.203-.49.492-.922.866-1.297a3.96 3.96 0 011.297-.866c.49-.203 1-.304 1.53-.304s1.04.1 1.53.304c.49.203.922.491 1.297.866.375.375.663.808.866 1.297.203.49.304 1 .304 1.53v0z"></path>
      <path d="M9.49 19.843a5.965 5.965 0 011.618.871 5.962 5.962 0 012.097 2.968 5.96 5.96 0 01.282 1.817v16.49c0 .597.114 1.172.344 1.725.23.552.556 1.04.98 1.461a4.45 4.45 0 001.466.974 4.447 4.447 0 001.727.336H22.5c.596 0 1.17-.114 1.72-.342a4.454 4.454 0 001.46-.975 4.48 4.48 0 001.317-3.179V37.49m10.494-25.482a10.468 10.468 0 01-.799 4.015 10.468 10.468 0 01-2.274 3.404 10.461 10.461 0 01-3.404 2.275 10.474 10.474 0 01-6.063.597 10.457 10.457 0 01-3.783-1.567 10.473 10.473 0 01-3.864-4.709 10.48 10.48 0 01-.598-6.062 10.465 10.465 0 011.567-3.783 10.476 10.476 0 014.71-3.865 10.468 10.468 0 014.015-.798 10.47 10.47 0 014.015.798 10.46 10.46 0 013.405 2.275 10.465 10.465 0 012.274 3.404 10.48 10.48 0 01.8 4.016v0z"></path>
      <path d="M18.004 6.671a17.237 17.237 0 005.704 3.937 17.233 17.233 0 006.788 1.4 17.049 17.049 0 006.895-1.42"></path></>
      break;

    case 'doc-sign-pen': 
      path = <><path d="M18.328 26.592a4.6 4.6 0 016.422-.184 4.6 4.6 0 01-.184 6.42c-1.762 1.766-9.504 3.264-9.504 3.264s1.504-7.738 3.266-9.5v0zm7.132-15.45l7.277-7.278a2.94 2.94 0 014.158 0l2.08 2.08"></path>
      <path d="M22.34 22.578L38.974 5.944a4.422 4.422 0 016.238 0 4.426 4.426 0 010 6.238L28.577 28.816l-6.238-6.238zm10.397-10.396l6.238 6.238"></path>
      <path d="M42.44 27.006c0 8.082.85 12.65-3.517 17.016l-2.264 2.262a.756.756 0 01-.532.218H2.25a.75.75 0 01-.53-1.28l1.2-1.2c4.53-4.53 3.52-7.428 3.52-31.548 0-6.296 4.696-9.668 6-10.972h8.896"></path></>
      break;

    case 'doc-stamp': 
      path = <><path d="M13.483 12.02V3.494c0-1.103.894-1.997 1.997-1.997h19.396l11.626 11.721v31.288a1.997 1.997 0 01-1.998 1.996H31.46"></path>
      <path d="M34.457 1.537v9.984c0 1.103.894 1.996 1.998 1.996h9.987m-6.892 7.488H25.469M39.55 36.978h-5.592m5.592-7.986H28.465M1.498 40.472l1.498 5.99H23.97l1.498-5.99m-5.992-5.989h-2.997V29.15a5.989 5.989 0 002.194-8.182 5.994 5.994 0 00-8.186-2.193 5.989 5.989 0 000 10.375v5.332H7.49a5.991 5.991 0 00-5.993 5.99h23.97a5.991 5.991 0 00-5.992-5.99z"></path></>
      break;

    case 'download-button': 
      path = <><path d="M24.004 1.502v30m-9-9l9 9 9-9"></path>
      <path d="M31.504 9H36a6 6 0 016 6v25.5a6 6 0 01-6 6H12a6 6 0 01-6-6V15a6 6 0 016-6h4.5"></path></>
      break;

    case 'email-circle': 
      path = <><path d="M46.48 24a22.458 22.458 0 01-2.654 10.597A22.484 22.484 0 0119.614 46.05a22.431 22.431 0 01-6.212-2.223 22.47 22.47 0 01-10.915-13.3 22.463 22.463 0 01-.536-10.912 22.463 22.463 0 016.153-11.51A22.482 22.482 0 0146.48 24v0z"></path>
      <path d="M13.51 13.51h20.98c.398 0 .78.075 1.147.227a3 3 0 011.851 2.769v14.988c0 .397-.076.78-.229 1.147a2.969 2.969 0 01-.65.972 2.97 2.97 0 01-.972.65c-.367.152-.75.228-1.147.228H13.51a2.97 2.97 0 01-1.147-.228 2.968 2.968 0 01-.972-.65 2.968 2.968 0 01-.65-.972 2.97 2.97 0 01-.228-1.147V16.506a2.98 2.98 0 01.878-2.119c.28-.281.605-.498.972-.65.367-.152.75-.228 1.147-.228v0z"></path>
      <path d="M10.512 16.506L24 24l13.489-7.494"></path></>
      break;
    
    case 'email-tick': 
      path = <><path d="M22.5 34.498a11.977 11.977 0 002.022 6.666 11.972 11.972 0 003.31 3.31 11.97 11.97 0 004.325 1.792 11.977 11.977 0 004.682 0 11.969 11.969 0 004.325-1.792 11.972 11.972 0 003.31-3.31 11.966 11.966 0 001.792-4.325 11.97 11.97 0 000-4.681 11.968 11.968 0 00-1.792-4.325 11.972 11.972 0 00-3.31-3.31 11.97 11.97 0 00-4.325-1.792 11.97 11.97 0 00-4.682 0 11.956 11.956 0 00-4.325 1.791 11.982 11.982 0 00-4.419 5.385 11.97 11.97 0 00-.913 4.591z"></path>
      <path d="M39.846 31.01l-5.81 7.746c-.13.173-.291.312-.482.416a1.487 1.487 0 01-.611.18 1.485 1.485 0 01-.631-.09 1.488 1.488 0 01-.537-.344l-3-3m-12.274-4.419H4.503c-.398 0-.78-.076-1.148-.229a2.97 2.97 0 01-.973-.65 2.97 2.97 0 01-.65-.973 2.97 2.97 0 01-.229-1.148V4.503c0-.398.076-.78.229-1.148a2.97 2.97 0 01.65-.973 2.97 2.97 0 01.973-.65 2.97 2.97 0 011.148-.229h35.994c.398 0 .78.076 1.148.229.368.152.692.369.973.65.282.281.498.606.65.973.153.368.229.75.229 1.148V18"></path>
      <path d="M42.819 2.603L26.533 15.13a6.568 6.568 0 01-1.903 1.02c-.69.235-1.4.352-2.13.352a6.57 6.57 0 01-2.13-.352 6.571 6.571 0 01-1.903-1.02L2.18 2.603"></path></>
      break;

    case 'icon-flower': 
      path = <><path d="M31.467 45.098c5.212-1.989 8.756-5.387 7.915-7.591-.84-2.204-5.748-2.38-10.96-.39-5.213 1.988-8.756 5.386-7.916 7.59.841 2.204 5.748 2.38 10.96.39z"></path>
      <path d="M20.517 44.703c.841-2.204-2.703-5.602-7.915-7.59-5.213-1.99-10.12-1.814-10.96.39-.841 2.204 2.703 5.602 7.915 7.59 5.213 1.99 10.12 1.814 10.96-.39zm-.01-15.538V46.09m0-24.475a4.555 4.555 0 100-9.11 4.555 4.555 0 000 9.11z"></path>
      <path d="M37.208 17.06a6.073 6.073 0 00-6.215-6.073 6.073 6.073 0 10-10.486-6.073 6.073 6.073 0 10-10.506 6.073 6.073 6.073 0 100 12.146 6.073 6.073 0 1010.506 6.073 6.073 6.073 0 1010.486-6.073 6.073 6.073 0 006.214-6.073h0z"></path></>
      break;

    case 'form-tick': 
      path = <><path d="M25.231 29.729l2.999 2.998 5.996-5.997"></path>
      <path d="M46.473 20.988V4.728a3.207 3.207 0 00-.249-1.235 3.207 3.207 0 00-.7-1.046 3.209 3.209 0 00-1.047-.7 3.207 3.207 0 00-1.234-.248H4.71a3.192 3.192 0 00-1.228.247c-.392.164-.74.396-1.04.697-.301.3-.533.648-.697 1.04A3.19 3.19 0 001.5 4.711v32.535c.001.428.084.84.248 1.235.164.395.397.744.7 1.046.302.303.65.536 1.046.7.395.165.806.248 1.234.25h8.795M1.499 10.494h44.974"></path>
      <path d="M17.99 29.729a11.706 11.706 0 00.892 4.493 11.71 11.71 0 002.544 3.81 11.72 11.72 0 006.012 3.214 11.714 11.714 0 004.582 0 11.702 11.702 0 004.232-1.753 11.715 11.715 0 003.239-3.24 11.714 11.714 0 001.752-4.234c.15-.756.225-1.52.225-2.29a11.706 11.706 0 00-.893-4.494 11.71 11.71 0 00-2.544-3.81 11.72 11.72 0 00-6.011-3.214 11.715 11.715 0 00-4.582 0 11.71 11.71 0 00-4.232 1.754 11.715 11.715 0 00-3.24 3.24 11.714 11.714 0 00-1.751 4.233c-.15.756-.226 1.52-.225 2.29v0zM46.5 46.5l-8.47-8.47"></path></>
      break;

    case 'gender-female': 
      path = <path d="M16.5 31.5v15m-6-6h12m-21-24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15z"></path>
      break;

    case 'gender-hetero-1': 
      path = <> <path d="M15.052 33.986c7.453 0 13.495-6.042 13.495-13.495 0-7.453-6.042-13.494-13.495-13.494-7.453 0-13.495 6.041-13.495 13.494 0 7.453 6.042 13.495 13.495 13.495zM8.824 42.29H21.28m-6.228-8.304l.006 12.457m9.511-35.489l8.805-8.814m.526 7.3l-.563-7.32-7.32-.563"></path>
      <path d="M16.69 26.6a1.82 1.82 0 01-2.63 0l-4.906-5.108a3.677 3.677 0 115.198-5.199l1.016 1.014 1.013-1.014a3.677 3.677 0 015.198 5.199l-4.889 5.107v0z"></path></>
      break;

    case 'gender-hetero-2': 
      path = <><path d="M21.009 13.138c5.17 4.147 5.999 11.7 1.852 16.87-4.147 5.169-11.7 5.998-16.87 1.851C.823 27.712-.006 20.16 4.14 14.99a12 12 0 012.539-2.364m6.83 21.874v12"></path>
      <path d="M16.277 27.648c-5.067-4.271-5.713-11.842-1.441-16.91 4.271-5.067 11.842-5.712 16.909-1.44 4.93 4.155 5.695 11.464 1.732 16.55m-.982-15.864L40.51 1.5m-7.501 0h7.5V9m-33 31.5h12"></path></>
      break;

    case 'gender-male': 
      path = <path d="M27.106 20.892L46.456 1.5M5.894 20.892c5.858-5.858 15.355-5.857 21.213 0 5.858 5.859 5.857 15.356 0 21.214-5.859 5.857-15.355 5.857-21.213 0C.036 36.248.035 26.751 5.893 20.893v-.001 0zM32.956 1.5h13.5V15"></path>
      break;

    case 'head-cough': 
      path = <><path d="M41.035 42.377V31A17.007 17.007 0 0029.46 1.516c-12.963 0-15.764 10.675-20.384 22.664a1.364 1.364 0 001.27 1.853h3.45v5.457a5.457 5.457 0 005.458 5.457h2.716v5.456"></path>
      <path d="M7.73 41.773a.758.758 0 010-1.516m0 1.516a.758.758 0 000-1.516m-5.456-5.295a.758.758 0 110-1.516"></path>
      <path d="M2.273 34.962a.758.758 0 100-1.516m6.812.154a.758.758 0 110-1.516M9.084 33.6a.758.758 0 100-1.516"></path></>
      break;

    case 'head-cross': 
      path = <><path d="M7.501 46.5V33.97a18.7 18.7 0 01-4.703-6.89 18.709 18.709 0 01-1.246-8.248 18.703 18.703 0 012.46-7.97 18.7 18.7 0 015.676-6.114 18.698 18.698 0 017.767-3.041A18.714 18.714 0 0120.25 1.5c14.278 0 17.36 11.756 22.45 24.96.089.23.118.469.09.714a1.48 1.48 0 01-.254.674 1.48 1.48 0 01-.537.478 1.481 1.481 0 01-.699.174h-3.798v6a5.982 5.982 0 01-1.011 3.333 5.985 5.985 0 01-2.693 2.21 5.98 5.98 0 01-2.296.457h-3v6"></path>
      <path d="M31.501 18a1.486 1.486 0 00-.44-1.06 1.487 1.487 0 00-1.06-.44h-4.5V12a1.486 1.486 0 00-.44-1.06 1.487 1.487 0 00-1.06-.44h-6a1.486 1.486 0 00-1.06.44A1.487 1.487 0 0016.5 12v4.5h-4.502a1.485 1.485 0 00-1.06.44A1.486 1.486 0 0010.5 18v6a1.486 1.486 0 00.44 1.06 1.487 1.487 0 001.06.44h4.5V30a1.486 1.486 0 00.44 1.06 1.489 1.489 0 001.06.44h6a1.486 1.486 0 001.06-.44A1.487 1.487 0 0025.5 30v-4.5H30a1.486 1.486 0 001.06-.44A1.487 1.487 0 0031.5 24v-6 0z"></path></>
      break;

    case 'head-dizzy': 
      path = <><path d="M36.8 46.5V33.97a18.724 18.724 0 005.624-17.47m-36.972 0c-1.306 3.092-2.508 6.476-3.852 9.96A1.502 1.502 0 003 28.5h3.8v6a6 6 0 006 6h3v6M35.3 2.77c4.566 1.098 7.5 2.8 7.5 4.73 0 3.314-8.73 6-19.5 6m-15-2.166C5.5 10.294 3.8 8.958 3.8 7.5c0-3.314 8.73-6 19.5-6"></path>
      <path d="M15.8 12.834a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5M29.3 3.75a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5"></path></>
      break;

    case 'head-flu': 
      path = <><path d="M36.8 46.5V33.97A18.73 18.73 0 0024.05 1.5C9.772 1.5 6.698 13.256 1.6 26.46A1.503 1.503 0 003 28.5h3.8v6a6 6 0 006 6h3v6"></path>
      <path d="M15.8 30.75a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m10.5 1.5a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m-4.5-6a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5"></path></>
      break;
      
    case 'head-pain': 
      path = <path d="M10.54 7.5c-3.884 4.674-6.1 11.6-8.94 18.96a1.48 1.48 0 00-.09.714c.03.246.114.47.254.674.14.204.319.363.538.479.218.115.45.173.698.173h3.8v6a5.982 5.982 0 001.011 3.333 5.985 5.985 0 002.693 2.21 5.98 5.98 0 002.296.457h3v6m21 0V33.97a18.671 18.671 0 004.292-5.918 18.667 18.667 0 001.697-7.11 18.676 18.676 0 00-1.156-7.218A18.67 18.67 0 0037.8 7.5m-19-6c-3 6 4.5 6 1.5 12m8.276-12c-3 6 4.5 6 1.5 12"></path>
      break;

    case 'head-right': 
      path = <path d="M7.501 46.5V33.97c-7.588-7.03-8.04-18.88-1.01-26.469A18.73 18.73 0 0120.25 1.5c15 0 17.646 13 23.25 27h-6v6a6 6 0 01-6 6h-3v6"></path>
      break;

    case 'head-sore-throat': 
      path = <><path d="M36.8 46.5V33.97A18.729 18.729 0 0024.052 1.5C9.774 1.5 6.692 13.256 1.6 26.46A1.503 1.503 0 003 28.5h3.8v6a6 6 0 006 6h3v6"></path>
      <path d="M21.8 44.25a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m1.502-6a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5m6 7.5a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5"></path></>
      break;

    case 'head-throat':
      path = <> <path d="M7.501 46.5V33.97a18.702 18.702 0 01-4.703-6.89 18.71 18.71 0 01-1.246-8.248 18.705 18.705 0 012.46-7.972A18.7 18.7 0 019.69 4.748a18.705 18.705 0 017.768-3.042 18.717 18.717 0 012.794-.206c14.278 0 17.36 11.756 22.45 24.96.089.23.118.469.09.714a1.48 1.48 0 01-.254.674 1.48 1.48 0 01-.537.479 1.48 1.48 0 01-.699.173h-3.8v6a5.982 5.982 0 01-1.011 3.333 5.985 5.985 0 01-2.693 2.21 5.98 5.98 0 01-2.296.457h-3v6"></path>
      <path d="M37.501 34.5h-15a5.982 5.982 0 00-3.333 1.011 5.985 5.985 0 00-2.21 2.693A5.98 5.98 0 0016.5 40.5v6"></path></>
      break;

    case 'help-msg': 
      path = <><path d="M43.5 37.5h-21l-12 9v-9h-6a3 3 0 01-3-3v-30a3 3 0 013-3h39a3 3 0 013 3v30a3 3 0 01-3 3z"></path>
      <path d="M18 13.5a6 6 0 0111.506-2.383A6 6 0 0126 19.158a3 3 0 00-2 2.83v.512"></path>
      <path d="M24 30a.75.75 0 010-1.5m0 1.5a.75.75 0 000-1.5"></path></>
      break;

    case 'head-wearable-hearing': 
      path = <><path d="M36.8 46.5V33.97a18.702 18.702 0 004.704-6.89 18.71 18.71 0 001.245-8.248 18.705 18.705 0 00-2.46-7.972 18.7 18.7 0 00-5.677-6.112 18.708 18.708 0 00-7.768-3.042A18.717 18.717 0 0024.05 1.5C9.772 1.5 6.69 13.256 1.6 26.46a1.48 1.48 0 00-.09.714c.03.246.114.47.254.674.14.204.319.363.538.479.218.115.45.173.698.173h3.8v6a5.982 5.982 0 001.011 3.333 5.985 5.985 0 002.693 2.21 5.98 5.98 0 002.296.457h3v6"></path>
      <path d="M21.904 17.848c-.438.001-.86.086-1.265.254a3.28 3.28 0 00-1.072.719 3.28 3.28 0 00-.716 1.073 3.28 3.28 0 00-.25 1.266v4.64a5.284 5.284 0 00.893 2.944 5.281 5.281 0 001.462 1.462 5.3 5.3 0 002.944.894h2.65a3.939 3.939 0 001.494-.328 3.939 3.939 0 001.26-.867 3.94 3.94 0 00.839-1.28 3.94 3.94 0 00.294-1.5c0-.52-.098-1.02-.294-1.5a3.938 3.938 0 00-.84-1.28 3.939 3.939 0 00-1.259-.867 3.94 3.94 0 00-1.494-.328h-1.326v-1.99c0-.44-.084-.863-.252-1.27a3.278 3.278 0 00-.72-1.075 3.28 3.28 0 00-1.078-.718 3.279 3.279 0 00-1.27-.249v0z"></path>
      <path d="M22.504 17.848c0-.53.101-1.04.304-1.53.203-.49.492-.923.867-1.298a3.962 3.962 0 011.298-.867c.49-.204 1-.305 1.53-.305h1c1.41 0 2.758.284 4.047.854a9.894 9.894 0 013.354 2.418l8.024 9.728h2.576"></path></>
      break;

    case 'hearing-aid': 
      path = <><path d="M13.432 42.795a8.346 8.346 0 001.821 1.945 8.369 8.369 0 004.96 1.758c.9.02 1.78-.102 2.64-.365a8.346 8.346 0 002.393-1.173 8.344 8.344 0 001.904-1.864 8.344 8.344 0 001.225-2.367 8.345 8.345 0 00.421-2.632c0-1.244.26-2.43.782-3.56a8.403 8.403 0 012.202-2.907 11.96 11.96 0 003.122-4.134 11.97 11.97 0 001.089-5.066 11.959 11.959 0 00-1.15-5.052 11.969 11.969 0 00-3.172-4.097 11.958 11.958 0 00-4.604-2.377 11.955 11.955 0 00-5.176-.214 11.96 11.96 0 00-4.785 1.988 11.965 11.965 0 00-3.5 3.82M35.996 1.5a8.976 8.976 0 015 1.516A8.968 8.968 0 0143.477 5.5a8.988 8.988 0 011.517 5m-10.498-3c.596 0 1.17.113 1.722.342a4.457 4.457 0 011.46.975c.422.422.747.909.975 1.46.229.551.343 1.125.343 1.722"></path>
      <path d="M18.906 19.198a5.887 5.887 0 013.06-1.176 5.887 5.887 0 013.222.61 5.888 5.888 0 012.42 2.211c.598.966.897 2.018.897 3.154M17.998 35.996l-6-6M7.5 46.495l-6-6m7.499-3l1.5 1.5m8.249-11.248l1.5 1.5"></path></>
      break;

    case 'hearing-aid-t-1': 
      path = <><path d="M1.519 35.99a10.462 10.462 0 00.8 4.014 10.467 10.467 0 002.274 3.402 10.455 10.455 0 003.403 2.273 10.456 10.456 0 004.014.798 10.461 10.461 0 004.014-.798 10.463 10.463 0 003.403-2.273 10.455 10.455 0 002.275-3.402 10.478 10.478 0 00.8-4.014c0-1.554.325-3.037.976-4.448a10.497 10.497 0 012.75-3.63 14.987 14.987 0 10-24.71-11.406"></path>
      <path d="M22.501 18.005a7.471 7.471 0 00-.71-3.177 7.47 7.47 0 00-1.99-2.577 7.467 7.467 0 00-2.894-1.49 7.472 7.472 0 00-3.254-.121 7.47 7.47 0 00-2.998 1.27 7.473 7.473 0 00-2.175 2.42 7.47 7.47 0 00-.944 3.116 7.47 7.47 0 00.466 3.222"></path>
      <path d="M4.517 26.997a7.475 7.475 0 001.263 4.164 7.482 7.482 0 004.768 3.186 7.474 7.474 0 004.33-.426 7.482 7.482 0 003.363-2.76 7.472 7.472 0 001.263-4.164 7.476 7.476 0 00-1.263-4.163 7.478 7.478 0 00-3.363-2.76 7.476 7.476 0 00-4.33-.426 7.485 7.485 0 00-4.768 3.186 7.478 7.478 0 00-1.263 4.163v0zm39.148-.681a22.417 22.417 0 002.1-5.27c.477-1.847.716-3.723.716-5.63 0-1.906-.239-3.783-.716-5.628a22.42 22.42 0 00-2.1-5.271m-4.72 4.632a13.472 13.472 0 011.54 6.269 13.474 13.474 0 01-1.54 6.269m4.538 24.794V37.49m-2.997-.001h5.995"></path></>
      break;

    case 'hearing-aid-t-2': 
      path = <><path d="M13.433 42.795a8.346 8.346 0 001.82 1.945 8.366 8.366 0 002.34 1.278c.847.3 1.721.46 2.62.48.9.02 1.78-.102 2.64-.365a8.346 8.346 0 002.393-1.173 8.344 8.344 0 001.905-1.864 8.344 8.344 0 001.224-2.367 8.348 8.348 0 00.422-2.632c0-1.244.26-2.43.782-3.56a8.403 8.403 0 012.201-2.907 11.954 11.954 0 003.123-4.134 11.97 11.97 0 001.088-5.066 11.959 11.959 0 00-1.15-5.052 11.969 11.969 0 00-3.171-4.097 11.96 11.96 0 00-4.604-2.377 11.954 11.954 0 00-5.177-.214 11.96 11.96 0 00-4.784 1.988 11.965 11.965 0 00-3.5 3.82M35.995 1.5a8.976 8.976 0 015 1.516A8.968 8.968 0 0143.477 5.5a8.981 8.981 0 011.517 5m-10.498-3c.597 0 1.17.113 1.722.342a4.457 4.457 0 011.46.975c.422.422.747.909.976 1.46a4.46 4.46 0 01.342 1.722"></path>
      <path d="M18.905 19.198a5.888 5.888 0 013.061-1.176 5.887 5.887 0 013.222.61 5.888 5.888 0 012.42 2.211 5.89 5.89 0 01.896 3.154M17.998 35.996l-6-6M7.5 46.495l-6-6m7.499-3l1.5 1.5m8.249-11.248l1.5 1.5m21.747 17.247v-8.999m-3 0h6"></path></>
      break;

    case 'hearing-1': 
      path = <><path d="M1.5 12.013a12.929 12.929 0 011.35-3.654 12.929 12.929 0 012.369-3.09 12.93 12.93 0 013.178-2.25 12.923 12.923 0 013.702-1.208 13.482 13.482 0 015.983.05 13.52 13.52 0 019.665 8.71 13.491 13.491 0 01.752 4.442 15.717 15.717 0 01-1.475 6.746 15.716 15.716 0 01-4.195 5.485 7.632 7.632 0 00-3.372 5.163 7.627 7.627 0 00-.056 2.108 21.846 21.846 0 01-.092 4.833 8.98 8.98 0 01-2.265 4.33 8.967 8.967 0 01-2.65 1.936 8.967 8.967 0 01-3.167.856 8.975 8.975 0 01-4.795-.943 8.972 8.972 0 01-2.607-1.992 8.972 8.972 0 01-1.72-2.794 8.992 8.992 0 01-.604-3.225"></path>
      <path d="M7.884 15.353a5.492 5.492 0 012.08-3.66 5.485 5.485 0 012.982-1.145 5.485 5.485 0 013.115.705 5.48 5.48 0 012.198 2.316 5.496 5.496 0 01.54 3.147M46.5 19.514h-9m0-9.002l6-6m-6 24.003l6 6"></path></>
      break;

    case 'hearing-2': 
      path = <><path d="M8.599 32.58a19.493 19.493 0 01-6.745-11.354A19.498 19.498 0 016.474 4.52a19.49 19.49 0 013.353-2.973m5.283 26.145a13.466 13.466 0 01-3.344-4.45 13.463 13.463 0 01-1.264-5.42 13.47 13.47 0 011.03-5.47 13.462 13.462 0 013.15-4.59m31.615 4.916A13.502 13.502 0 0030.383 1.756a13.487 13.487 0 00-7.14 3.912 13.496 13.496 0 00-2.974 13.828 13.475 13.475 0 003.614 5.463c.408.372.836.719 1.286 1.039a7.62 7.62 0 011.59 1.383 7.628 7.628 0 011.839 5.885 8.968 8.968 0 00.73 5.052 8.97 8.97 0 003.362 3.84 8.97 8.97 0 003.195 1.246 8.969 8.969 0 003.906-.151 8.974 8.974 0 003.474-1.793 8.966 8.966 0 002.386-3.098 8.974 8.974 0 00.848-3.816"></path>
      <path d="M40.114 15.386a5.482 5.482 0 00-1.297-2.918 5.48 5.48 0 00-2.7-1.705 5.485 5.485 0 00-3.192.082 5.486 5.486 0 00-2.609 1.842 5.491 5.491 0 00-1.117 4.06"></path></>
      break;

    case 'heart': 
      path = <path d="M24.001 40.863L4.835 20.873a11.27 11.27 0 01-2.078-2.852 11.267 11.267 0 01-1.1-3.353 11.27 11.27 0 01-.016-3.528 11.27 11.27 0 011.738-4.524A11.342 11.342 0 0120.87 4.83L24 7.959l3.131-3.128a11.32 11.32 0 014.535-2.773 11.325 11.325 0 016.608-.11 11.325 11.325 0 015.547 3.594 11.354 11.354 0 012.538 5.596 11.27 11.27 0 01-.016 3.527 11.269 11.269 0 01-1.099 3.35 11.274 11.274 0 01-2.075 2.852L24.001 40.862h0z"></path>
      break;

    case 'heart-beat': 
      path = <><path d="M34.066 30.373l-8.984 9.372a1.483 1.483 0 01-1.082.462 1.484 1.484 0 01-1.082-.462l-8.986-9.374M2.942 18.38a11.309 11.309 0 01-1.165-8.008 11.31 11.31 0 014.348-6.657 11.32 11.32 0 014.903-2.058 11.35 11.35 0 019.84 3.176L24 7.955l3.132-3.132a11.343 11.343 0 0113.617-1.848A11.338 11.338 0 0146.495 13.1a11.318 11.318 0 01-1.431 5.27"></path>
      <path d="M1.5 24.373h13.036a.7.7 0 00.672-.414l3.446-6.894c.15-.311.397-.452.741-.422.344.03.564.21.659.542l3.362 11.2c.106.326.33.5.673.52.342.019.585-.128.727-.44l3.14-7.328a.704.704 0 01.62-.45.704.704 0 01.694.328l2.232 3.348h15"></path></>
      break;

    case 'heart-monitor-1': 
      path = <><path d="M7.506 1.509h32.987a6.319 6.319 0 012.295.431 6.11 6.11 0 011.947 1.229c.278.263.526.55.745.86a5.56 5.56 0 01.896 2.042c.076.365.115.734.115 1.106V38.35a5.356 5.356 0 01-.457 2.17 5.55 5.55 0 01-.554.98 5.724 5.724 0 01-.746.858 6.097 6.097 0 01-1.946 1.229 6.246 6.246 0 01-2.295.431H7.506a6.312 6.312 0 01-2.295-.431 6.115 6.115 0 01-1.037-.524 5.932 5.932 0 01-.909-.705 5.736 5.736 0 01-.745-.859 5.56 5.56 0 01-.896-2.043 5.352 5.352 0 01-.115-1.106V7.177a5.352 5.352 0 01.456-2.17 5.736 5.736 0 011.3-1.839 5.93 5.93 0 01.91-.704 6.244 6.244 0 013.332-.955v0z"></path>
      <path d="M1.509 15.679h11.995l2.329-4.4c.139-.26.362-.39.67-.39.308 0 .531.13.67.39l4.702 8.88c.133.253.35.383.649.391.3.008.523-.11.672-.356l4.7-7.774a.718.718 0 01.264-.256.769.769 0 01.362-.101.774.774 0 01.65.315l2.325 3.3H46.49M1.509 32.682h7.497l1.5-2.833 2.998 5.668 1.5-2.835h16.493l1.5-2.833 2.998 5.668 1.5-2.835h8.996"></path></>
      break;

    case 'heart-monitor-2': 
      path = <><path d="M7.504 1.504h32.993a5.985 5.985 0 013.333 1.011 5.984 5.984 0 012.21 2.692 5.99 5.99 0 01.456 2.296v32.994a5.987 5.987 0 01-1.757 4.242 5.982 5.982 0 01-3.072 1.641 5.988 5.988 0 01-1.17.115H7.504a5.987 5.987 0 01-4.242-1.756 5.984 5.984 0 01-1.642-3.072 5.986 5.986 0 01-.115-1.17V7.503a5.985 5.985 0 011.01-3.332 5.987 5.987 0 012.693-2.21 5.982 5.982 0 012.296-.456v0z"></path>
      <path d="M1.505 16.502h11.998l2.33-4.658a.7.7 0 01.669-.412.7.7 0 01.67.412l4.703 9.399c.134.268.35.406.65.415a.704.704 0 00.672-.377l4.7-8.229a.738.738 0 01.264-.27.738.738 0 01.362-.108.737.737 0 01.369.083c.116.06.21.143.282.251l2.325 3.494h14.997M10.503 40.497v-3m17.996 3v-3m-8.998 3v-5.999m17.996 5.999v-5.999"></path></>
      break;

    case 'heart-organ': 
      path = <><path d="M1.52 4.517l5.926 9.316c-1.326 2.75-1.803 5.639-1.43 8.668C7.514 35.99 15.008 46.481 24 46.481c8.993 0 16.486-10.491 17.985-23.98.373-3.03-.104-5.919-1.43-8.668l5.926-9.316"></path>
      <path d="M19.504 1.52v3.458a18.758 18.758 0 00-6.536 2.68l-2.456-6.139m26.976.001l-2.456 6.138a18.758 18.758 0 00-6.536-2.68V1.52"></path>
      <path d="M31.304 5.784l-.018.014s-4.996 4.274-1.447 8.646m11.704 11.055s-11.52-3.397-12.979 3.429M6.016 22.501s11.59.688 11.832-4.23m-4.105 3.565l4.105 3.663m-1.022 18.63v-.024s2.978-6.716 7.174-6.716"></path></>
      break;

    case 'heart-star': 
      path = <><path d="M35.888 23.357L38.97 30h5.998c.31-.012.595.066.856.235.26.168.448.396.565.684.116.287.139.582.068.884-.07.303-.222.557-.453.763l-5.198 5.126 2.883 6.626c.13.311.158.631.082.96-.076.33-.24.605-.494.827-.254.223-.549.35-.885.383a1.532 1.532 0 01-.94-.206l-6.97-3.92-6.972 3.92c-.291.17-.605.239-.941.206a1.532 1.532 0 01-.884-.383 1.533 1.533 0 01-.494-.827 1.534 1.534 0 01.082-.96l2.885-6.626-5.198-5.126a1.436 1.436 0 01-.455-.757c-.071-.3-.05-.595.063-.882.114-.287.3-.515.558-.685.257-.17.54-.25.85-.242h5.997l3.087-6.644c.3-.568.773-.852 1.415-.852.643 0 1.115.285 1.414.854v0z"></path>
      <path d="M16.447 33L4.829 20.872a11.28 11.28 0 01-2.077-2.852 11.278 11.278 0 01-1.1-3.353 11.284 11.284 0 01-.017-3.53 11.277 11.277 0 011.738-4.524 11.34 11.34 0 0117.494-1.786l3.13 3.132 3.132-3.132a11.333 11.333 0 018.502-3.313 11.327 11.327 0 016.236 2.194 11.334 11.334 0 013.426 4.067 11.203 11.203 0 011.196 5.116A11.203 11.203 0 0145.255 18"></path></>
      break;

    case 'heart-tick': 
      path = <><path d="M46.463 34.485a11.953 11.953 0 01-.912 4.585 11.96 11.96 0 01-2.597 3.888 11.953 11.953 0 01-3.888 2.598 11.956 11.956 0 01-4.586.912 11.946 11.946 0 01-4.585-.912 11.95 11.95 0 01-3.888-2.598 11.948 11.948 0 01-2.598-3.888 11.954 11.954 0 01-.912-4.585 11.953 11.953 0 01.912-4.586 11.957 11.957 0 012.598-3.888 11.946 11.946 0 013.887-2.597 11.952 11.952 0 014.586-.913"></path>
      <path className={cl} d="M46.464 25.497L35.541 36.42a1.482 1.482 0 01-1.06.439 1.483 1.483 0 01-1.059-.44l-3.429-3.432m-13.535 0L4.852 20.874a11.28 11.28 0 01-2.074-2.849 11.264 11.264 0 01-1.099-3.348 11.26 11.26 0 01-.016-3.524 11.261 11.261 0 011.735-4.52 11.328 11.328 0 0117.476-1.78L24 7.977l3.128-3.126a11.322 11.322 0 019.825-3.174 11.323 11.323 0 018.32 6.116 11.188 11.188 0 011.195 5.112 11.188 11.188 0 01-1.234 5.102"></path></>
      break;

    case 'house-chimney': 
      path = <path  d="M31.5 46.5v-15a3 3 0 00-3-3h-9a3 3 0 00-3 3v15H3.46a2 2 0 01-2-2v-25a2.34 2.34 0 01.8-1.5L24 1.5l8 6v-5h9v11.82L45.74 18c.453.413.726.988.76 1.6v24.94a2 2 0 01-2 2l-13-.04z"></path>
      break;

    case 'hyperlink-circle': 
      path = <><path d="M25.31 35.986a9.116 9.116 0 01-12.094 4.356v0A9.116 9.116 0 018.86 28.248l2.764-5.874a9.114 9.114 0 0112.092-4.356v0a9.087 9.087 0 015.022 6.382"></path>
      <path className={cl} d="M23.692 12.214a9.116 9.116 0 0112.094-4.356 9.116 9.116 0 014.356 12.092l-2.764 5.874a9.116 9.116 0 01-12.094 4.356v0a9.064 9.064 0 01-4.704-5.238"></path></>
      break;

    case 'insurance-card': 
      path = <path d="M4.5 1.5h39a2.994 2.994 0 012.771 1.852c.153.368.23.75.23 1.148v30c0 .398-.077.78-.23 1.148a2.971 2.971 0 01-.65.973 2.97 2.97 0 01-.973.65c-.367.153-.75.23-1.148.23h-39a2.96 2.96 0 01-1.148-.23 2.971 2.971 0 01-.973-.65 2.97 2.97 0 01-.65-.973A2.972 2.972 0 011.5 34.5v-30a2.994 2.994 0 01.878-2.121 2.97 2.97 0 01.974-.65 2.96 2.96 0 011.148-.23V1.5zm-3 9h45m-6 9h-12m6 9h-6M15 18v12m-6-6h12"></path>
      break;

    case 'info-circle': 
      path = <path fill={color} className={cl} stroke="none" d="M24 0a24 24 0 100 48 24 24 0 000-48zm.5 10a3 3 0 110 5.999 3 3 0 010-5.999zM29 37h-8a2 2 0 010-4h1.5a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5H21a2 2 0 010-4h2a4 4 0 014 4v9.5a.5.5 0 00.5.5H29a2 2 0 110 4z"></path>
      break;

    case 'jar-cream': 
      path = <><path d="M2.5 22.2h43a1 1 0 011 1v16a4 4 0 01-4 4h-37a4 4 0 01-4-4v-16a1 1 0 011-1zm7-6h29a2 2 0 012 2v4h-33v-4a2 2 0 012-2z"></path>
      <path d="M10.5 16.2c0-4.5 3-9 7.5-9h9a7.298 7.298 0 007-4.739 1.49 1.49 0 012.572-.382c5.902 7.906.928 14.124.928 14.124m-27 12h27v9h-27v-9 0z"></path></>
      break;
      
    case 'jar-cream-drop': 
      path =  <path d="M25.5 37.203h-18a6 6 0 01-6-6v-7.5a1.5 1.5 0 011.5-1.5h27m-24 0v-3a3 3 0 013-3h24a3 3 0 013 3v1.5m-27-4.5c0-4.5 3-9 7.5-9h6a7.298 7.298 0 007-4.742 1.49 1.49 0 012.572-.382C37.974 9.99 33 16.203 33 16.203m13.5 22.6a7.5 7.5 0 01-15 0c0-4.942 6.25-13.588 7.5-13.588s7.5 8.638 7.5 13.588z"></path>
      break;

    case 'keyboard-arrow-right': 
      path = <path d="M15.5 8.331H.5m12.5 2.5l2.5-2.5-2.5-2.5"></path>
      break;

    case 'keyboard-arrow-left': 
      path = <path d="M15.5 8.331H.5m2.5 2.5l-2.5-2.5 2.5-2.5"></path>
      break;

    case 'laptop-cross': 
      path = <><path d="M4.506 28.499V4.506c0-.398.076-.78.228-1.148a2.97 2.97 0 01.65-.973 2.97 2.97 0 01.973-.65c.368-.152.75-.229 1.148-.229h32.99c.398 0 .78.077 1.148.229.368.152.692.369.973.65s.498.605.65.973c.153.367.229.75.229 1.148v23.993"></path>
      <path d="M29.97 28.499c-.499 0-.899.2-1.199.6a5.922 5.922 0 01-3.397 2.238 5.922 5.922 0 01-2.743 0 5.922 5.922 0 01-2.451-1.233 5.913 5.913 0 01-.946-1.005c-.3-.4-.7-.6-1.2-.6H1.507v2.999a5.986 5.986 0 001.01 3.332 5.98 5.98 0 002.693 2.21 5.983 5.983 0 002.295.456h32.99a5.979 5.979 0 003.333-1.01 5.988 5.988 0 002.55-3.818c.078-.386.116-.776.116-1.17v-3H29.971v0zm1.528-14.996a1.484 1.484 0 00-.926-1.386 1.486 1.486 0 00-.574-.114H27V9.004a1.485 1.485 0 00-.44-1.06 1.486 1.486 0 00-1.06-.44h-3a1.485 1.485 0 00-1.06.44 1.486 1.486 0 00-.44 1.06v3h-2.998a1.485 1.485 0 00-1.06.439 1.487 1.487 0 00-.44 1.06v3a1.485 1.485 0 00.44 1.06 1.486 1.486 0 001.06.439H21V21a1.485 1.485 0 00.439 1.06 1.487 1.487 0 001.06.44h3a1.485 1.485 0 001.06-.44 1.486 1.486 0 00.44-1.06v-3h2.998a1.486 1.486 0 001.06-.439 1.484 1.484 0 00.44-1.06v-2.999z"></path></>
      break;

    case 'loading-circle': 
      path = <path d="M11.628 8.506l3.528 4.854M4 19.006l5.706 1.854M4 31.984l5.706-1.854m1.922 12.354l3.528-4.854m8.816 8.864v-6m12.344 1.99l-3.528-4.854m11.156-5.646l-5.706-1.854m5.706-11.124l-5.706 1.854M36.316 8.506l-3.528 4.854M23.972 1.494v12"></path>
      break;

    case 'location-pin': 
      path = <><path d="M10.543 19.573a9.011 9.011 0 00.688 3.458 9.022 9.022 0 003.328 4.056 9.021 9.021 0 005.02 1.522 9.011 9.011 0 003.459-.688 9.025 9.025 0 004.89-4.89 9.015 9.015 0 00.688-3.458 9.011 9.011 0 00-.688-3.458 9.014 9.014 0 00-3.328-4.056 9.021 9.021 0 00-5.02-1.523 9.011 9.011 0 00-3.459.688 9.014 9.014 0 00-4.055 3.329 9.029 9.029 0 00-1.523 5.02z"></path>
      <path d="M37.652 19.573c0 15.864-13.92 24.762-17.328 26.708-.497.284-.995.284-1.492 0-3.408-1.948-17.326-10.848-17.326-26.708a18.026 18.026 0 011.377-6.914 18.028 18.028 0 013.919-5.862 18.025 18.025 0 015.862-3.916 18.031 18.031 0 016.915-1.375 18.029 18.029 0 016.915 1.375 18.03 18.03 0 015.862 3.916 18.028 18.028 0 013.919 5.862 18.03 18.03 0 011.377 6.914h0z"></path></>
      break;

    case 'location-circle': 
      path = <><path d="M16.503 15.004a5.985 5.985 0 001.01 3.332 5.986 5.986 0 003.817 2.55 5.984 5.984 0 003.465-.341 5.985 5.985 0 002.692-2.209 5.981 5.981 0 001.01-3.332 5.984 5.984 0 00-1.01-3.332 5.979 5.979 0 00-2.692-2.209 5.982 5.982 0 00-3.465-.341 5.982 5.982 0 00-3.07 1.641 5.985 5.985 0 00-1.642 3.071 5.984 5.984 0 00-.115 1.17z"></path>
      <path d="M22.5 1.51a13.46 13.46 0 015.165 1.027 13.455 13.455 0 014.377 2.925 13.455 13.455 0 012.925 4.378 13.457 13.457 0 011.028 5.164c0 6.5-10.26 21.044-12.887 24.663a.742.742 0 01-.607.308.743.743 0 01-.608-.308C19.266 36.048 9.007 21.503 9.007 15.004a13.461 13.461 0 011.027-5.164 13.444 13.444 0 012.925-4.378 13.469 13.469 0 014.378-2.925A13.463 13.463 0 0122.5 1.51h0z"></path>
      <path d="M34.453 35.563c5.461 1.081 9.038 2.886 9.038 4.93 0 3.312-9.396 5.997-20.99 5.997-11.595 0-20.991-2.685-20.991-5.997 0-2.04 3.56-3.84 8.996-4.924"></path></>
      break;

    case 'location-pill': 
      path = <><path d="M19.577 1.506a18.027 18.027 0 016.915 1.376 18.026 18.026 0 015.863 3.917 18.022 18.022 0 013.917 5.862 18.027 18.027 0 011.376 6.916c0 15.862-13.919 24.76-17.326 26.705-.497.283-.995.283-1.492 0-3.407-1.944-17.324-10.843-17.324-26.705a18.026 18.026 0 011.375-6.916A18.03 18.03 0 016.8 6.8a18.022 18.022 0 015.862-3.917 18.027 18.027 0 016.916-1.376h0z"></path>
      <path d="M21.183 27.255a6.007 6.007 0 01-3.085 1.651 6.013 6.013 0 01-3.482-.341 6.01 6.01 0 01-2.706-2.219 6.016 6.016 0 01-.902-4.524 6.008 6.008 0 011.648-3.086l5.325-5.323a6.004 6.004 0 013.093-1.71 5.993 5.993 0 012.376-.021 6.016 6.016 0 013.88 2.58 6.01 6.01 0 011.007 3.388 6.018 6.018 0 01-1.839 4.28l-5.315 5.325h0zm-5.865-11.18l8.518 8.518"></path></>
      break;

    case 'lock': 
      path = <><path d="M37.5 19.5h-27a3 3 0 00-3 3v21a3 3 0 003 3h27a3 3 0 003-3v-21a3 3 0 00-3-3zm-24 0V12a10.5 10.5 0 1121 0v7.5M24 30v6"></path></>
      break;

    case 'lock-unlock': 
      path = <path d="M1.5 19.5V12a10.5 10.5 0 0121 0v7.5m21 0h-27a3 3 0 00-3 3v21a3 3 0 003 3h27a3 3 0 003-3v-21a3 3 0 00-3-3zM30 30v6"></path>
      break;
      
    case 'logout': 
      path = <path d="M15 24.008h31.5m-7.5 7.5l7.5-7.5-7.5-7.5M31.5 33v9a2.874 2.874 0 01-2.728 3H4.226A2.874 2.874 0 011.5 42V6a2.872 2.872 0 012.726-3h24.546A2.874 2.874 0 0131.5 6v9"></path>
      break;
      
    case 'medical-check-cross':
      path = <><path d="M17.844 10.953l-4.26 5.678a1.487 1.487 0 01-.986.584 1.485 1.485 0 01-1.27-.425l-2.32-2.309m8.836 9.957l-4.26 5.677a1.485 1.485 0 01-.986.584 1.485 1.485 0 01-1.27-.424l-2.32-2.312m37.456 3.872a1.318 1.318 0 00-.39-.943 1.318 1.318 0 00-.942-.39h-6.659v-6.658a1.315 1.315 0 00-.39-.943 1.317 1.317 0 00-.942-.39h-5.326a1.317 1.317 0 00-.943.39 1.32 1.32 0 00-.39.943v6.658h-6.657a1.318 1.318 0 00-.943.39 1.316 1.316 0 00-.39.943v5.326a1.316 1.316 0 00.39.942 1.318 1.318 0 00.943.39h6.658v6.658a1.317 1.317 0 00.39.943 1.32 1.32 0 00.942.39h5.326a1.317 1.317 0 00.943-.39 1.317 1.317 0 00.39-.943v-6.658h6.658a1.318 1.318 0 00.942-.39 1.318 1.318 0 00.39-.942v-5.326 0z"></path>
      <path d="M16.499 40.47H4.513a2.97 2.97 0 01-1.147-.227 2.967 2.967 0 01-.972-.65 2.967 2.967 0 01-.65-.972 2.969 2.969 0 01-.227-1.147V4.513c0-.398.076-.78.228-1.147.152-.367.368-.691.65-.972.28-.281.604-.498.971-.65.367-.152.75-.228 1.147-.228h21.233a2.888 2.888 0 012.118.88L33.6 8.13a2.89 2.89 0 01.877 2.12v6.249"></path>
      <path d="M24.493 2.23v9.274h9.985"></path></>
      break;

    case 'microscope-advance':
      path = <><path d="M35.814 17.894a12.696 12.696 0 013.224 4.16 12.695 12.695 0 011.267 5.11 12.698 12.698 0 01-.91 5.185 12.698 12.698 0 01-2.927 4.375 13.266 13.266 0 01-4.272 2.795 13.26 13.26 0 01-5.01.98 13.261 13.261 0 01-5.009-.98 13.267 13.267 0 01-4.271-2.795 12.627 12.627 0 01-3.234-5.224"></path>
      <path d="M31.674 21.96a1.88 1.88 0 01-.61.4 1.88 1.88 0 01-.716.14 1.88 1.88 0 01-.715-.14 1.881 1.881 0 01-.61-.4l-2.653-2.6a1.8 1.8 0 01-.404-.595 1.802 1.802 0 010-1.41 1.8 1.8 0 01.404-.595l7.954-7.82a1.88 1.88 0 01.61-.4c.23-.093.469-.14.716-.14a1.881 1.881 0 011.326.54l2.652 2.6c.175.171.31.37.405.595a1.8 1.8 0 01.143.705 1.8 1.8 0 01-.143.705 1.8 1.8 0 01-.404.595l-7.954 7.82h0z"></path>
      <path d="M37.935 9.88l5.377-5.38-3-3m6 6l-3-3m-33.134 27h10.5m-8.866 15h28.5m-13.501-6v6m-6-45h6v6"></path>
      <path d="M26.812 1.5L14.544 13.548a1.886 1.886 0 01-1.326.538 1.886 1.886 0 01-1.326-.538l-2.504-2.46a1.88 1.88 0 00-.61-.4 1.88 1.88 0 00-.716-.14 1.88 1.88 0 00-.716.14 1.88 1.88 0 00-.61.4L1.5 16.23"></path></>
      break;

    case 'monitor':
      path = <><path d="M31.5 46.5h-15l1.5-9h12l1.5 9zm-19.5 0h24m-34.5-17h45"></path>
      <path d="M7.5 1.5h33a5.982 5.982 0 013.333 1.011 5.985 5.985 0 012.21 2.693A5.98 5.98 0 0146.5 7.5v24a5.982 5.982 0 01-1.011 3.333 5.985 5.985 0 01-2.693 2.21 5.98 5.98 0 01-2.296.457h-33a5.982 5.982 0 01-3.333-1.011 5.985 5.985 0 01-2.21-2.693A5.98 5.98 0 011.5 31.5v-24a5.982 5.982 0 011.011-3.333 5.985 5.985 0 012.693-2.21A5.98 5.98 0 017.5 1.5v0z"></path></>
      break;

    case 'monitor-cross':
      path = <><path d="M31.5 46.499h-15l1.5-9h12l1.5 9zm-19.499 0H36M1.501 29.5h44.998"></path>
      <path d="M7.5 1.501h33a5.986 5.986 0 013.333 1.011 5.988 5.988 0 012.21 2.693 5.976 5.976 0 01.456 2.296V31.5a5.981 5.981 0 01-1.011 3.333 5.99 5.99 0 01-3.818 2.551 5.99 5.99 0 01-1.17.115h-33a5.989 5.989 0 01-4.242-1.757A5.989 5.989 0 011.501 31.5v-24a5.985 5.985 0 011.011-3.333 5.986 5.986 0 012.693-2.21A5.98 5.98 0 017.5 1.502v0z"></path>
      <path d="M31.496 13.998a1.485 1.485 0 00-.44-1.06 1.487 1.487 0 00-1.06-.44h-2.999V9.5a1.484 1.484 0 00-.926-1.385A1.485 1.485 0 0025.498 8h-3a1.485 1.485 0 00-1.06.44 1.487 1.487 0 00-.44 1.06v2.999H18a1.485 1.485 0 00-1.06.439 1.486 1.486 0 00-.44 1.06v3a1.486 1.486 0 00.44 1.06 1.484 1.484 0 001.06.44h2.999v2.998a1.485 1.485 0 00.439 1.06 1.486 1.486 0 001.06.44h3a1.486 1.486 0 001.06-.44 1.484 1.484 0 00.44-1.06v-2.999h2.998a1.484 1.484 0 001.385-.926c.077-.183.115-.375.115-.573v-3z"></path></>
      break;
      
    case 'multiple-ppl-1':
      path = <path d="M6.754 10.752a8.232 8.232 0 001.39 4.582 8.227 8.227 0 003.702 3.038 8.23 8.23 0 004.765.47 8.212 8.212 0 002.974-1.232 8.23 8.23 0 003.038-3.702 8.229 8.229 0 00.47-4.766A8.22 8.22 0 0021.86 6.17a8.23 8.23 0 00-3.701-3.038 8.236 8.236 0 00-4.766-.47 8.221 8.221 0 00-2.973 1.232 8.228 8.228 0 00-3.038 3.702 8.229 8.229 0 00-.628 3.157v0zM1.505 35.497a13.465 13.465 0 011.027-5.166 13.463 13.463 0 012.926-4.378 13.459 13.459 0 014.38-2.926 13.469 13.469 0 015.164-1.028 13.465 13.465 0 015.166 1.028 13.46 13.46 0 014.379 2.926 13.467 13.467 0 012.925 4.378 13.467 13.467 0 011.028 5.166M28.703 15.25a6.734 6.734 0 001.136 3.752 6.73 6.73 0 003.03 2.488 6.723 6.723 0 002.583.514 6.727 6.727 0 003.75-1.138 6.73 6.73 0 002.486-3.031 6.738 6.738 0 00.513-2.584 6.734 6.734 0 00-1.136-3.752 6.736 6.736 0 00-3.03-2.487 6.727 6.727 0 00-3.9-.385 6.735 6.735 0 00-3.457 1.849 6.741 6.741 0 00-1.975 4.775h0zM31.625 25.135a11.056 11.056 0 0110.138 1.302 11.043 11.043 0 014.652 7.734c.054.44.08.882.08 1.325"></path>
      break;

    case 'multiple-ppl-2':
      path = <path d="M6.763 10.756a8.221 8.221 0 00.627 3.155 8.224 8.224 0 003.037 3.7 8.22 8.22 0 002.972 1.23 8.217 8.217 0 003.216 0 8.218 8.218 0 002.972-1.23 8.22 8.22 0 002.275-2.275 8.224 8.224 0 001.389-4.58 8.225 8.225 0 00-1.39-4.58 8.225 8.225 0 00-3.7-3.036 8.225 8.225 0 00-4.762-.47 8.225 8.225 0 00-4.222 2.257 8.226 8.226 0 00-2.256 4.22 8.222 8.222 0 00-.158 1.61h0zM1.517 35.488a13.462 13.462 0 011.026-5.162 13.456 13.456 0 012.925-4.377 13.452 13.452 0 014.376-2.924 13.464 13.464 0 015.163-1.027 13.46 13.46 0 015.162 1.027 13.467 13.467 0 014.377 2.924 13.458 13.458 0 012.924 4.377 13.462 13.462 0 011.027 5.162m.001-31.645a8.226 8.226 0 014.169-1.325 8.237 8.237 0 016.547 2.832 8.223 8.223 0 011.89 3.945 8.228 8.228 0 01-.395 4.356 8.228 8.228 0 01-2.568 3.541 8.225 8.225 0 01-4.017 1.73 8.222 8.222 0 01-4.336-.57 8.226 8.226 0 01-1.302-.691m1.51 4.671a13.5 13.5 0 0113.542 4.75 13.475 13.475 0 012.606 5.406 13.477 13.477 0 01.34 3"></path>
      break;

    case 'multiple-ppl-3':
      path = <><path d="M1.51 35.493a13.46 13.46 0 011.027-5.164 13.464 13.464 0 012.925-4.378 13.46 13.46 0 014.378-2.925A13.455 13.455 0 0115.004 22a13.46 13.46 0 015.164 1.027 13.465 13.465 0 014.378 2.925 13.463 13.463 0 012.925 4.378 13.455 13.455 0 011.028 5.164M7.896 6.567a13.63 13.63 0 003.282 2.504 13.633 13.633 0 003.878 1.414c1.365.274 2.74.339 4.124.194a13.632 13.632 0 003.994-1.041"></path>
      <path d="M6.757 10.754a8.225 8.225 0 00.628 3.155 8.238 8.238 0 004.463 4.463 8.242 8.242 0 008.987-1.787 8.237 8.237 0 002.415-5.832 8.222 8.222 0 00-.628-3.155 8.232 8.232 0 00-3.037-3.701 8.219 8.219 0 00-2.973-1.232 8.225 8.225 0 00-4.764.47 8.227 8.227 0 00-3.701 3.037 8.22 8.22 0 00-1.232 2.973 8.225 8.225 0 00-.158 1.609v0zM28.5 3.838a8.231 8.231 0 014.17-1.325 8.232 8.232 0 014.26.994 8.224 8.224 0 013.153 3.034 8.223 8.223 0 011.026 2.751 8.233 8.233 0 01-1.033 5.686 8.227 8.227 0 01-3.158 3.028 8.237 8.237 0 01-5.724.795 8.243 8.243 0 01-2.706-1.14m1.509 4.672a13.512 13.512 0 0112.538 3.623 13.522 13.522 0 013.614 6.536 13.529 13.529 0 01.341 3.001"></path>
      <path d="M28.494 8.672a13.347 13.347 0 007.251 2.082c1.884.001 3.691-.37 5.422-1.116"></path></>
      break;

    case 'multiple-ppl-wifi': 
      path = <><path d="M35.996 5.753a19.038 19.038 0 00-5.633-3.152 19.046 19.046 0 00-9.591-.818 19.036 19.036 0 00-6.086 2.154c-.946.53-1.84 1.135-2.683 1.816m4.999 7.228a11.106 11.106 0 015.115-2.315 11.113 11.113 0 015.594.477 11.111 11.111 0 013.287 1.838M1.506 27.75c0 .697.134 1.366.4 2.01a5.2 5.2 0 001.138 1.702c.492.492 1.06.871 1.702 1.138.643.266 1.313.4 2.009.4s1.365-.134 2.008-.4a5.201 5.201 0 001.703-1.138 5.2 5.2 0 001.138-1.703c.266-.643.4-1.312.4-2.008s-.134-1.366-.4-2.009a5.2 5.2 0 00-1.138-1.703 5.2 5.2 0 00-1.703-1.137 5.2 5.2 0 00-2.008-.4 5.2 5.2 0 00-2.009.4 5.2 5.2 0 00-1.702 1.137 5.199 5.199 0 00-1.138 1.703 5.199 5.199 0 00-.4 2.009v0zM15.03 39.963a8.972 8.972 0 00-2.346-3.23 8.977 8.977 0 00-3.512-1.897 8.976 8.976 0 00-3.988-.191 8.972 8.972 0 00-3.678 1.553m34.49-8.448c0 .697.133 1.366.4 2.01a5.2 5.2 0 001.137 1.702c.492.492 1.06.871 1.703 1.138.643.266 1.313.4 2.009.4s1.365-.134 2.008-.4a5.2 5.2 0 001.703-1.138 5.198 5.198 0 001.137-1.703 5.2 5.2 0 00.4-2.008 5.19 5.19 0 00-.4-2.009 5.2 5.2 0 00-1.137-1.703 5.2 5.2 0 00-1.703-1.137 5.2 5.2 0 00-2.008-.4 5.2 5.2 0 00-2.009.4 5.2 5.2 0 00-1.703 1.137 5.198 5.198 0 00-1.137 1.703 5.2 5.2 0 00-.4 2.009v0zM32.97 39.963a8.968 8.968 0 012.345-3.23 8.972 8.972 0 013.513-1.897 8.975 8.975 0 013.987-.191 8.971 8.971 0 013.678 1.553m-29.241-9.947a6.728 6.728 0 001.137 3.75 6.729 6.729 0 003.028 2.485 6.732 6.732 0 003.899.384 6.729 6.729 0 003.455-1.847 6.735 6.735 0 001.847-3.455 6.728 6.728 0 00-.384-3.9 6.727 6.727 0 00-2.486-3.028A6.73 6.73 0 0024 19.503a6.728 6.728 0 00-3.75 1.137 6.735 6.735 0 00-2.485 3.029 6.74 6.74 0 00-.514 2.582v0z"></path>
      <path d="M35.996 46.495a12.074 12.074 0 00-1.333-4.115 12.078 12.078 0 00-2.695-3.383 12.075 12.075 0 00-3.713-2.22A12.076 12.076 0 0024 36.004a12.077 12.077 0 00-4.256.773 12.085 12.085 0 00-5.212 3.79 12.07 12.07 0 00-2.047 3.81 12.067 12.067 0 00-.481 2.118"></path></>
      break;

    case 'nav-menu-dots':
      path = <path d="M2 10h44M28.5 2h-9a3 3 0 00-3 3v5h15V5a3 3 0 00-3-3zm-9 33.5v-15m9 15v-15m9.22 22.74A2.98 2.98 0 0134.74 46H13.26a2.98 2.98 0 01-2.98-2.76L7.5 10h33l-2.78 33.24z"></path>
      break;

    case 'network':
      path= <><path d="M46.239 24.028a22.045 22.045 0 01-13.606 20.363 21.985 21.985 0 01-6.275 1.572A22.045 22.045 0 013.107 30.426a22.051 22.051 0 01.729-14.832 22.022 22.022 0 016.38-8.604 22.026 22.026 0 019.683-4.58 22.013 22.013 0 018.6 0 22.052 22.052 0 0112.738 7.636 22.007 22.007 0 014.054 7.584 22.042 22.042 0 01.949 6.398h0z"></path>
      <path d="M20.336 45.551c-3.076-4.3-5.152-12.373-5.152-21.502s2.076-17.397 5.152-21.6m8.308 43.102c3.076-4.3 5.152-12.373 5.152-21.502s-2.076-17.397-5.152-21.6M2.938 24.49h43.103M4.898 34.776h38.204M4.898 14.204h38.204"></path></>;
      break;

    case 'network-download':
      path= <><path d="M22.501 34.5a11.973 11.973 0 00.913 4.593 11.97 11.97 0 002.602 3.893 11.97 11.97 0 003.893 2.601 11.973 11.973 0 004.592.914 11.973 11.973 0 004.592-.914 11.97 11.97 0 003.893-2.601 11.982 11.982 0 003.284-6.144 11.973 11.973 0 000-4.682 11.963 11.963 0 00-1.791-4.326 11.965 11.965 0 00-3.311-3.31 11.966 11.966 0 00-4.326-1.793 11.973 11.973 0 00-4.682 0 11.963 11.963 0 00-4.326 1.792 11.97 11.97 0 00-3.31 3.311 11.973 11.973 0 00-1.792 4.326 11.948 11.948 0 00-.231 2.34zm12-6v12m0 0l-4.5-4.5m4.5 4.5l4.5-4.5"></path>
      <path d="M20.931 46.293a22.447 22.447 0 01-7.854-2.62 22.457 22.457 0 01-6.375-5.282 22.448 22.448 0 01-4.033-7.23 22.452 22.452 0 01-1.145-8.2 22.459 22.459 0 011.898-8.06 22.462 22.462 0 014.684-6.826 22.452 22.452 0 016.836-4.671 22.455 22.455 0 018.062-1.883 22.457 22.457 0 018.197 1.161 22.461 22.461 0 017.223 4.047 22.456 22.456 0 015.27 6.385 22.45 22.45 0 012.605 7.859"></path>
      <path d="M18.579 45.843c-3.042-4.466-5.078-12.576-5.078-21.844s2.036-17.376 5.078-21.842M1.552 22.5h19.45m-15.003-12h36.002m-37.903 24H14.43M29.424 2.157a33.531 33.531 0 014.6 14.254"></path></>
      break;

    case 'network-heart':
      path = <><path d="M34.498 46.5L24.27 35.832a6.011 6.011 0 01-1.107-1.522 6.015 6.015 0 01-.587-1.788 6.013 6.013 0 01-.008-1.882 6.013 6.013 0 01.57-1.794 6.03 6.03 0 011.828-2.169 6.026 6.026 0 012.615-1.097 6.027 6.027 0 012.828.215 6.03 6.03 0 012.42 1.48l1.67 1.67 1.67-1.67a6.03 6.03 0 012.42-1.48 6.032 6.032 0 012.828-.215 6.035 6.035 0 012.615 1.097 6.032 6.032 0 011.829 2.17c.283.567.473 1.165.569 1.793a6.019 6.019 0 01-.01 1.882 6.017 6.017 0 01-.586 1.788 6.018 6.018 0 01-1.108 1.522L34.5 46.5h0z"></path>
      <path d="M23.998 46.5a22.447 22.447 0 01-8.373-1.617 22.446 22.446 0 01-7.17-4.616 22.448 22.448 0 01-4.936-6.952 22.452 22.452 0 01-1.996-8.29 22.446 22.446 0 011.234-8.438A22.45 22.45 0 017.04 9.214a22.455 22.455 0 016.72-5.25 22.442 22.442 0 018.19-2.37 22.45 22.45 0 018.486.847 22.45 22.45 0 017.56 3.943 22.445 22.445 0 015.55 6.474 22.448 22.448 0 012.743 8.074"></path>
      <path d="M18.576 45.842C15.534 41.378 13.498 33.266 13.498 24c0-9.266 2.036-17.376 5.078-21.842M1.548 22.5h19.046m-14.598-12h36.002m-37.904 24h12.404M29.42 2.158c2.696 3.956 4.6 10.772 5 18.718"></path></>
      break;

    case 'network-upload':
      path = <><path d="M22.499 34.5a11.973 11.973 0 00.914 4.592 11.97 11.97 0 002.6 3.893 11.982 11.982 0 006.145 3.285 11.973 11.973 0 004.682 0 11.963 11.963 0 004.326-1.792 11.965 11.965 0 003.31-3.311 11.966 11.966 0 001.792-4.326 11.973 11.973 0 000-4.682 11.963 11.963 0 00-1.791-4.326 11.965 11.965 0 00-3.311-3.31 11.973 11.973 0 00-4.326-1.792 11.973 11.973 0 00-4.682 0 11.963 11.963 0 00-4.326 1.792 11.97 11.97 0 00-3.31 3.31 11.973 11.973 0 00-1.792 4.326 11.95 11.95 0 00-.231 2.341v0zm12 6v-12m0 0l-4.5 4.5m4.5-4.5l4.5 4.5"></path>
      <path d="M20.968 46.298a22.455 22.455 0 01-7.749-2.547 22.46 22.46 0 01-6.332-5.141 22.447 22.447 0 01-4.082-7.06 22.45 22.45 0 01-1.298-8.053 22.45 22.45 0 011.657-7.986 22.453 22.453 0 014.396-6.87 22.453 22.453 0 016.556-4.852 22.457 22.457 0 017.855-2.197 22.451 22.451 0 018.122.748 22.455 22.455 0 017.321 3.593 22.464 22.464 0 015.56 5.968 22.458 22.458 0 013.068 7.557"></path>
      <path d="M18.577 45.842C15.535 41.378 13.499 33.266 13.499 24c0-9.266 2.036-17.376 5.078-21.842M1.55 22.5h19.046m-14.599-12H42m-37.904 24h10.332M29.422 2.158a33.572 33.572 0 014.6 14.294"></path></>
      break;

    case 'patho-blood-drop':
      path = <><path d="M29.85 32.612a13.818 13.818 0 01-1.055 5.3 13.815 13.815 0 01-3.002 4.493 13.82 13.82 0 01-4.493 3.002A13.817 13.817 0 0116 46.46a13.817 13.817 0 01-5.3-1.054 13.822 13.822 0 01-4.493-3.002 13.823 13.823 0 01-3.002-4.493 13.815 13.815 0 01-1.055-5.3c0-9.993 9.127-25.057 12.561-30.367.14-.22.325-.393.554-.518.229-.125.474-.188.735-.188.26 0 .506.063.734.188.23.125.414.298.554.518C20.723 7.555 29.85 22.62 29.85 32.612v0z"></path>
      <path d="M16 40.312a7.672 7.672 0 01-2.946-.586 7.662 7.662 0 01-2.497-1.67 7.666 7.666 0 01-1.667-2.498 7.672 7.672 0 01-.584-2.946"></path></>
      break;
      
    case 'patho-microscope':
      path = <><path d="M33.386 17.895a12.705 12.705 0 013.224 4.16 12.706 12.706 0 011.266 5.109 12.7 12.7 0 01-.908 5.184 12.703 12.703 0 01-2.928 4.373 13.26 13.26 0 01-4.27 2.795 13.254 13.254 0 01-5.009.98 13.265 13.265 0 01-5.009-.98 13.265 13.265 0 01-4.27-2.794 12.625 12.625 0 01-3.233-5.224"></path>
      <path d="M29.247 21.96a1.85 1.85 0 01-.61.4c-.23.094-.468.14-.716.14a1.882 1.882 0 01-1.326-.54l-2.651-2.6a1.801 1.801 0 01-.405-.594 1.8 1.8 0 01-.142-.705 1.807 1.807 0 01.547-1.3l7.953-7.818a1.882 1.882 0 011.325-.54 1.882 1.882 0 011.326.54l2.651 2.6a1.8 1.8 0 01.547 1.3 1.8 1.8 0 01-.142.704c-.095.226-.23.424-.405.595l-7.952 7.818v0zm6.261-12.077l5.375-5.38-3-2.999m5.999 5.999l-3-3M7.755 31.499h10.498M1.86 46.496h36.023m-13.497-5.999v5.999m-8.914-29.03a6.964 6.964 0 01-1.177 3.88 6.978 6.978 0 01-5.807 3.103 6.964 6.964 0 01-3.88-1.177 6.963 6.963 0 01-2.572-3.134 6.963 6.963 0 01-.532-2.672c0-5.039 4.602-12.635 6.334-15.312a.76.76 0 01.28-.261.759.759 0 01.37-.095c.132 0 .255.031.37.095a.76.76 0 01.28.26c1.732 2.678 6.334 10.274 6.334 15.313v0zM5.794 16.924s-.07 2.976 3.027 2.81"></path></>
      break;

    case 'pencil':
      path = <path d="M44.38 3.62a7.278 7.278 0 00-10.34.07l-29 29L1.5 46.5l13.81-3.542 29-29a7.274 7.274 0 00.07-10.338v0zm-11.168.9L43.48 14.788m-38.438 17.9l10.278 10.26"></path>
      break;

    case 'phone':
      path = <path d="M30.08 44.78l.024.014a11.066 11.066 0 0013.768-1.51l1.548-1.548a3.692 3.692 0 000-5.218L38.898 30a3.692 3.692 0 00-5.218 0v0a3.686 3.686 0 01-5.216 0L18.028 19.562a3.692 3.692 0 010-5.218v0a3.685 3.685 0 000-5.216L11.508 2.6a3.692 3.692 0 00-5.218 0L4.742 4.148A11.07 11.07 0 003.23 17.916l.016.024A99.87 99.87 0 0030.08 44.78v0z"></path>
      break;
      
    case 'pill-bottle-2':
      path = <><path d="M38 10.5H2v-6a2.994 2.994 0 01.878-2.121 2.97 2.97 0 01.974-.65A2.96 2.96 0 015 1.499h30c.398 0 .78.077 1.148.23.368.152.692.368.973.65.282.28.498.605.65.973.153.367.23.75.23 1.148v6H38zm-3 0H5v30a5.982 5.982 0 001.011 3.333 5.985 5.985 0 002.693 2.21A5.98 5.98 0 0011 46.5h18a5.982 5.982 0 003.333-1.011 5.985 5.985 0 002.21-2.693A5.98 5.98 0 0035 40.5v-30 0z"></path>
      <path d="M35 19.5H5v18h30v-18zM20 24v9m-4.5-4.5h9"></path></>
      break;
    
    case 'pill-bottle':
      path = <><path d="M18 43.5a3.005 3.005 0 003 3h18a2.994 2.994 0 002.121-.878 2.97 2.97 0 00.65-.974c.153-.367.23-.75.23-1.148v-33H18v12"></path>
      <path d="M43.5 10.5h-27a1.486 1.486 0 01-1.06-.44A1.487 1.487 0 0115 9V4.5a2.994 2.994 0 01.878-2.121 2.97 2.97 0 01.974-.65c.367-.153.75-.23 1.148-.23h24c.398 0 .78.077 1.148.23.368.152.692.368.973.65.282.28.498.605.65.973.153.367.23.75.23 1.148V9a1.486 1.486 0 01-.44 1.06 1.487 1.487 0 01-1.06.44H43.5zM9.31 45.16a4.53 4.53 0 01-1.484.992 4.531 4.531 0 01-1.751.348c-.607 0-1.19-.116-1.75-.348a4.532 4.532 0 01-1.485-.992 4.53 4.53 0 01-.992-1.484 4.53 4.53 0 01-.348-1.751c0-.607.116-1.19.348-1.75a4.528 4.528 0 01.992-1.485L13.2 28.34a4.53 4.53 0 011.484-.992 4.531 4.531 0 011.75-.348c.608 0 1.191.116 1.752.348a4.55 4.55 0 011.484.992c.429.429.76.924.992 1.484.232.56.348 1.144.348 1.751s-.116 1.19-.348 1.75a4.532 4.532 0 01-.992 1.485L9.31 45.16v0zM8.016 33.516l6.468 6.468M42 22.5H27v15h15v-15z"></path></>
      break;

    case 'pills-sheet':
      path = <><path d="M2.317 25.97L25.97 2.318c.261-.262.563-.463.904-.605a2.761 2.761 0 011.067-.212c.37 0 .725.07 1.067.212.342.142.643.343.904.605l15.77 15.77a2.771 2.771 0 01.817 1.971c0 .37-.07.725-.212 1.067a2.761 2.761 0 01-.605.904L22.03 45.683a2.762 2.762 0 01-.904.605 2.761 2.761 0 01-1.067.212c-.37 0-.726-.07-1.067-.212a2.76 2.76 0 01-.904-.605l-15.77-15.77a2.762 2.762 0 01-.605-.904 2.761 2.761 0 01-.212-1.067c0-.37.07-.725.212-1.067a2.76 2.76 0 01.605-.904z"></path>
      <path d="M25.972 10.2c.264-.253.565-.447.904-.581.339-.135.69-.2 1.056-.196a2.762 2.762 0 011.942.817 2.764 2.764 0 01.816 1.943 2.778 2.778 0 01-.775 1.958l-3.943 3.944a2.76 2.76 0 01-.904.604 2.762 2.762 0 01-1.067.213c-.37 0-.725-.071-1.067-.212a2.763 2.763 0 01-.904-.605 2.76 2.76 0 01-.605-.904 2.762 2.762 0 01-.212-1.066c0-.37.07-.726.212-1.067.14-.342.342-.643.603-.905l3.944-3.944v0zm7.884 7.885a2.76 2.76 0 01.905-.604 2.763 2.763 0 011.066-.212c.37 0 .725.071 1.067.213.341.141.643.342.904.604.261.26.463.562.604.904.142.34.213.696.213 1.066 0 .37-.07.725-.211 1.067a2.763 2.763 0 01-.604.904l-3.942 3.944a2.762 2.762 0 01-.904.604 2.762 2.762 0 01-1.067.212 2.75 2.75 0 01-1.067-.213 2.763 2.763 0 01-.904-.604 2.76 2.76 0 01-.604-.905 2.762 2.762 0 01-.212-1.067c0-.37.07-.725.212-1.066.141-.342.343-.643.604-.905l3.94-3.942h0zm-19.712 3.942c.262-.26.563-.462.905-.604a2.762 2.762 0 011.067-.211c.37 0 .725.07 1.066.212a2.79 2.79 0 011.509 3.643 2.763 2.763 0 01-.605.904l-3.942 3.942a2.76 2.76 0 01-.904.604 2.76 2.76 0 01-1.067.213c-.37 0-.725-.071-1.067-.212a2.763 2.763 0 01-.904-.605 2.76 2.76 0 01-.605-.904 2.762 2.762 0 01-.212-1.066 2.779 2.779 0 01.815-1.972l3.944-3.944h0zm7.884 7.886a2.76 2.76 0 01.905-.604 2.76 2.76 0 011.066-.212c.37 0 .726.07 1.067.212.342.141.643.343.905.604.261.261.463.563.604.904.142.342.213.697.213 1.067s-.07.725-.212 1.067a2.761 2.761 0 01-.604.904L22.028 37.8a2.763 2.763 0 01-.903.58c-.34.134-.691.2-1.056.196a2.762 2.762 0 01-1.052-.218 2.76 2.76 0 01-.89-.598 2.762 2.762 0 01-.6-.892 2.764 2.764 0 01-.217-1.051 2.763 2.763 0 01.196-1.056c.134-.34.328-.64.58-.904l3.942-3.944h0z"></path></>
      break;

    case 'pills-single':
      path = <path d="M1.516 13.643a11.912 11.912 0 00.909 4.57 11.91 11.91 0 002.588 3.873 11.92 11.92 0 006.114 3.268 11.91 11.91 0 004.66 0 11.905 11.905 0 004.304-1.783 11.92 11.92 0 003.294-3.294 11.901 11.901 0 001.783-4.305 11.909 11.909 0 000-4.659 11.905 11.905 0 00-1.783-4.304 11.914 11.914 0 00-3.294-3.295 11.914 11.914 0 00-4.305-1.783 11.913 11.913 0 00-4.659 0 11.913 11.913 0 00-4.304 1.783 11.914 11.914 0 00-3.295 3.295 11.91 11.91 0 00-1.783 4.304c-.153.769-.23 1.546-.23 2.33zm.376-2.986h23.13m-23.13 5.971h23.13m6.422 27.667a7.451 7.451 0 01-5.22 2.002 7.448 7.448 0 01-4.042-1.275 7.44 7.44 0 01-2.696-3.27 7.444 7.444 0 01-.48-4.21 7.438 7.438 0 011.02-2.66c.253-.406.543-.784.87-1.134L33.56 21.082a7.437 7.437 0 012.423-1.73 7.44 7.44 0 012.906-.644 7.437 7.437 0 012.926.542 7.45 7.45 0 013.429 2.801 7.45 7.45 0 011.238 4.25 7.454 7.454 0 01-2.374 5.33L31.444 44.295h0zM27.22 27.407l10.557 10.556"></path>
      break;

    case 'pin':
      path = <><path d="M16.5 16.5a7.5 7.5 0 1015 0 7.5 7.5 0 00-15 0z"></path>
      <path className={cl} d="M24 1.5a15 15 0 0115 15c0 6.814-10.148 23.9-13.75 29.33a1.5 1.5 0 01-2.5 0C19.148 40.4 9 23.314 9 16.5a15 15 0 0115-15v0z"></path></>
      break;

    case 'question-circle-bold':
      path = <path fill={color} d="M24 0a24 24 0 100 48 24 24 0 000-48zm0 38a3 3 0 110-5.999A3 3 0 0124 38zm3.2-12.16a2 2 0 00-1.2 1.84 2 2 0 01-4 0 6 6 0 013.6-5.5A4 4 0 1020 18.5a2 2 0 01-4 0 8 8 0 1111.2 7.34z"></path>
      break;

    case 'refresh-arrow': path = <><path d="M21.1 44c-3.8 0-7.5-1.1-10.6-3.2-3.1-2.1-5.6-5.1-7-8.6-1.4-3.5-1.8-7.3-1.1-11s2.6-7.1 5.2-9.8 6.1-4.5 9.8-5.2c3.7-.7 7.6-.4 11 1.1 3.5 1.4 6.5 3.9 8.6 7 2.1 3.1 3.2 6.8 3.2 10.6v1.5" className={cl}></path>
      <path d="M46.1 20.5l-5.9 5.9-5.9-5.9"></path></>
      break;

    case 'retail-counter': path = <><path d="M1.499 44.5v-9a3.005 3.005 0 011.852-2.771A2.97 2.97 0 014.5 32.5h39.002c.397 0 .78.076 1.148.229a2.983 2.983 0 011.623 1.623c.152.368.229.75.229 1.148v9"></path>
      <path d="M5 32.5v-9a3.84 3.84 0 01.248-1.494c.182-.482.45-.91.802-1.285a3.843 3.843 0 011.233-.88c.47-.212.962-.326 1.476-.341h13.48a3.864 3.864 0 012.71 1.22c.352.376.62.804.802 1.286.182.481.265.98.249 1.494v9m-15.5-13V13m.5 12.5h4m27.5-18a5.983 5.983 0 01-1.01 3.333 5.982 5.982 0 01-2.693 2.21 5.986 5.986 0 01-3.467.342 5.987 5.987 0 01-3.073-1.643 5.997 5.997 0 01-1.3-6.539 5.981 5.981 0 012.21-2.693A5.982 5.982 0 0136.5 1.5a5.985 5.985 0 013.334 1.01 5.987 5.987 0 012.21 2.693 5.987 5.987 0 01.457 2.296zm3 18c0-.89-.13-1.762-.39-2.614a8.951 8.951 0 00-1.133-2.388 8.949 8.949 0 00-1.777-1.956 8.955 8.955 0 00-2.27-1.355 8.95 8.95 0 00-2.565-.638 8.949 8.949 0 00-2.64.135 8.951 8.951 0 00-2.486.896 8.955 8.955 0 00-2.119 1.58M4.499 6.04h12c.277 0 .512.097.708.292a.964.964 0 01.293.707v5a.964.964 0 01-.293.708.963.963 0 01-.707.293H4.499a.964.964 0 01-.707-.293.964.964 0 01-.293-.707v-5c0-.277.098-.512.293-.708a.963.963 0 01.707-.293z"></path></>
      break;

    case 'road-sign-warning-bold': 
      path = <path fill={color} stroke="none" d="M46.5 46.46a1.501 1.501 0 001.32-2.2l-22.5-42a1.56 1.56 0 00-2.64 0l-22.5 42a1.46 1.46 0 001.28 2.2H46.5zM24 40.96a3 3 0 11.001-6.002A3 3 0 0124 40.96zm0-24.5a2 2 0 012 2V29.4a2 2 0 01-4 0V18.46a2 2 0 012-2z"></path>
      break;

    case 'search':
      path = <><path d="M2.944 26.714a18.126 18.126 0 1033.364-14.18 18.126 18.126 0 00-33.364 14.18v0zm29.498 5.726L46.5 46.5"></path></>;
      break;

    case 'shield-tick':
      path = <><path d="M46.486 19.879a26.948 26.948 0 01-1.644 9.274 26.93 26.93 0 01-4.73 8.144 26.933 26.933 0 01-7.242 6.022A26.916 26.916 0 0124 46.485a26.914 26.914 0 01-8.87-3.166 26.918 26.918 0 01-7.24-6.022 26.933 26.933 0 01-4.732-8.144 26.936 26.936 0 01-1.643-9.274V4.513c0-.398.076-.78.228-1.148a2.97 2.97 0 01.65-.972 2.97 2.97 0 01.972-.65 2.97 2.97 0 011.148-.228h38.974c.398 0 .78.076 1.148.228.367.152.691.369.972.65s.498.605.65.972c.152.368.228.75.228 1.148v15.366h0zm-44.971-9.37h44.97"></path>
      <path d="M34.493 19.5L23.56 30.435a1.482 1.482 0 01-1.06.44 1.483 1.483 0 01-1.058-.44l-3.438-3.436"></path></>;
      break;

    case 'profile':
        path = <path d="M13.5 12a10.5 10.5 0 1021 0 10.5 10.5 0 00-21 0v0zm-9 34.5a19.5 19.5 0 0139 0"></path>
        break;

    case 'profile-square':
      path = <><path d="M40.5 1.5h-33a3 3 0 00-3 3v39a3 3 0 003 3h33a3 3 0 003-3v-39a3 3 0 00-3-3z"></path>
      <path d="M24 26.386a8.22 8.22 0 100-16.44 8.22 8.22 0 000 16.44zm13.5 11.668a15.26 15.26 0 00-27 0"></path></>
      break;
    
    case 'profile-circle':
      path = <><path d="M13.5 19.5a10.5 10.5 0 1021 0 10.5 10.5 0 00-21 0v0zm24.326 22.252a19.493 19.493 0 00-27.652 0"></path>
      <path d="M1.5 24a22.5 22.5 0 1045 0 22.5 22.5 0 00-45 0v0z"></path></>
      break;

    case 'smartphone-chat': path = <><path d="M35.993 1.505a10.394 10.394 0 00-2.965.415 10.39 10.39 0 00-2.725 1.24 10.398 10.398 0 00-2.262 1.96 10.398 10.398 0 00-1.614 2.522 10.398 10.398 0 00-.832 2.876 10.43 10.43 0 00.884 5.86 10.37 10.37 0 001.642 2.503l-1.313 7.556 6.843-4.22a10.459 10.459 0 004.385.07 10.451 10.451 0 004.03-1.73 10.453 10.453 0 002.97-3.226 10.463 10.463 0 001.393-4.158 10.457 10.457 0 00-.43-4.364A10.462 10.462 0 0043.825 5a10.457 10.457 0 00-3.542-2.585 10.459 10.459 0 00-4.29-.911h0zM9.716 30.749a6.732 6.732 0 001.137 3.749 6.736 6.736 0 003.028 2.485 6.735 6.735 0 005.165 0 6.736 6.736 0 003.652-3.652A6.744 6.744 0 0022.074 27a6.736 6.736 0 00-3.028-2.485 6.742 6.742 0 00-6.331.623 6.74 6.74 0 00-2.87 4.295c-.086.435-.13.873-.13 1.317v0zm17.2 15.732a12.11 12.11 0 00-7.343-5.58 12.112 12.112 0 00-10.455 2.074 12.131 12.131 0 00-3.115 3.52M35.993 28.5v17.995m-34.488 0V7.504a5.984 5.984 0 011.01-3.333 5.98 5.98 0 012.693-2.21 5.986 5.986 0 012.295-.456h14.994M1.505 13.502h17.994M13.5 7.504h6"></path>
      <path d="M40.491 11.145a4.176 4.176 0 01-.447 1.57 4.176 4.176 0 01-1.01 1.283 4.172 4.172 0 01-1.421.803 4.173 4.173 0 01-1.62.201 4.175 4.175 0 01-1.62-.201 4.176 4.176 0 01-1.422-.803 4.172 4.172 0 01-1.01-1.283 4.173 4.173 0 01-.447-1.57"></path></>
      break;

    case 'settings-slider': path = <><path d="M43.5 1.5h-39a3 3 0 00-3 3v39a3 3 0 003 3h39a3 3 0 003-3v-39a3 3 0 00-3-3zM26 31.5h11.5m-27 0H16"></path>
      <path d="M26 31.5a5 5 0 11-10 0 5 5 0 0110 0v0z" clipRule="evenodd"></path>
      <path d="M28 16.5H10.5"></path>
      <path d="M38 16.5a5 5 0 10-10 0 5 5 0 0010 0z" clipRule="evenodd"></path></>
      break;

    case 'smartphone-cross-1': path = <><path d="M7.003 1.507h17.994c.597 0 1.17.115 1.722.343a4.457 4.457 0 011.46.975c.421.422.746.908.974 1.46.229.55.343 1.124.343 1.72v35.989c0 .596-.114 1.17-.343 1.721a4.456 4.456 0 01-.975 1.46 4.455 4.455 0 01-1.46.975 4.454 4.454 0 01-1.72.342H7.002a4.46 4.46 0 01-1.721-.342 4.456 4.456 0 01-1.46-.975 4.455 4.455 0 01-.975-1.46 4.455 4.455 0 01-.342-1.721V6.006c0-.597.114-1.17.342-1.722a4.456 4.456 0 01.975-1.459 4.455 4.455 0 011.46-.975 4.455 4.455 0 011.721-.343v0zM2.505 37.495h26.99"></path>
      <path d="M23.497 18.002a1.486 1.486 0 00-.439-1.06 1.485 1.485 0 00-1.06-.44h-3v-2.999a1.485 1.485 0 00-.439-1.06 1.485 1.485 0 00-1.06-.44H14.5a1.485 1.485 0 00-1.06.44 1.485 1.485 0 00-.44 1.06v3h-2.998a1.487 1.487 0 00-1.06.439 1.485 1.485 0 00-.44 1.06v2.999a1.486 1.486 0 00.44 1.06 1.485 1.485 0 001.06.44H13V25.5a1.485 1.485 0 00.44 1.06 1.485 1.485 0 001.06.44h3a1.485 1.485 0 001.06-.44 1.485 1.485 0 00.439-1.06v-3h2.999a1.485 1.485 0 001.06-.439 1.485 1.485 0 00.44-1.06v-2.999z"></path></>
      break;

    case 'smartphone-cross-2': path = <><path d="M25.999 34.498v6.533a5.454 5.454 0 01-1.6 3.865A5.443 5.443 0 0121.6 46.39c-.352.07-.708.105-1.066.105H7.468a5.454 5.454 0 01-3.865-1.6 5.445 5.445 0 01-1.495-2.799 5.456 5.456 0 01-.105-1.066V9.968a5.455 5.455 0 011.6-3.864 5.45 5.45 0 012.799-1.496 5.45 5.45 0 011.066-.105H17M2.003 37.536h23.996"></path>
      <path d="M43.995 12.002a1.485 1.485 0 00-.439-1.06 1.486 1.486 0 00-1.06-.44h-7.5V3.004a1.485 1.485 0 00-.438-1.06 1.485 1.485 0 00-1.06-.44h-6a1.485 1.485 0 00-1.06.44 1.486 1.486 0 00-.44 1.06v7.498H18.5a1.485 1.485 0 00-1.06.44 1.485 1.485 0 00-.44 1.06v5.999a1.485 1.485 0 00.44 1.06 1.486 1.486 0 001.06.44h7.498v7.498a1.483 1.483 0 00.44 1.06 1.485 1.485 0 001.06.44h6a1.485 1.485 0 001.06-.44 1.486 1.486 0 00.44-1.06v-7.498h7.498a1.485 1.485 0 001.06-.44 1.485 1.485 0 00.44-1.06v-5.999 0z"></path></>
      break;

    case 'smartphone-cross-hand': 
      path = <> <path d="M31.497 46.492l-5.855-4.686a3.21 3.21 0 01-1.058-1.51 3.21 3.21 0 01-.074-1.841 3.265 3.265 0 011.808-2.18 3.267 3.267 0 012.832.046l2.347 1.174V26.999c0-.398.076-.78.229-1.148.152-.367.368-.691.65-.973.28-.281.605-.498.972-.65A2.97 2.97 0 0134.496 24a3 3 0 012.999 2.999v5.998l3.985.663c.7.117 1.359.35 1.976.701s1.155.797 1.614 1.339a5.95 5.95 0 011.055 1.812 5.95 5.95 0 01.367 2.064v6.916m-23.992 0H6.008c-.597 0-1.17-.114-1.722-.343a4.454 4.454 0 01-1.46-.975 4.455 4.455 0 01-.974-1.46 4.456 4.456 0 01-.343-1.72V6.006c0-.597.114-1.17.343-1.722a4.455 4.455 0 01.975-1.46 4.455 4.455 0 011.46-.974 4.456 4.456 0 011.72-.343H24c.597 0 1.17.114 1.721.343a4.455 4.455 0 011.46.975c.422.422.747.908.975 1.46.228.55.343 1.124.343 1.72v13.496M1.508 37.495h17.994"></path>
      <path d="M22.5 18.002a1.485 1.485 0 00-.439-1.06 1.487 1.487 0 00-1.06-.44h-2.999v-2.998a1.485 1.485 0 00-.44-1.06 1.484 1.484 0 00-1.06-.44h-2.998a1.485 1.485 0 00-1.06.44 1.485 1.485 0 00-.44 1.06v2.999H9.005a1.485 1.485 0 00-1.385.926 1.485 1.485 0 00-.114.573v3a1.485 1.485 0 00.44 1.06 1.486 1.486 0 001.06.439h2.998v2.998a1.485 1.485 0 00.44 1.06 1.486 1.486 0 001.06.44h2.999a1.485 1.485 0 001.06-.44 1.485 1.485 0 00.439-1.06v-2.998h2.999c.199 0 .39-.038.574-.115a1.485 1.485 0 00.811-.811c.076-.184.114-.375.114-.574v-2.999 0z"></path></>
      break;

    case 'smartwatch-heart':
      path = <><path d="M7.5 7.5h21a5.982 5.982 0 013.333 1.011 5.985 5.985 0 012.21 2.693A5.98 5.98 0 0134.5 13.5v21a5.982 5.982 0 01-1.011 3.333 5.985 5.985 0 01-2.693 2.21 5.98 5.98 0 01-2.296.457h-21a5.982 5.982 0 01-3.333-1.011 5.985 5.985 0 01-2.21-2.693A5.98 5.98 0 011.5 34.5v-21a5.982 5.982 0 011.011-3.333 5.985 5.985 0 012.693-2.21A5.98 5.98 0 017.5 7.5v0z"></path>
      <path d="M28.5 7.5h-21v-3a2.994 2.994 0 01.878-2.121 2.97 2.97 0 01.974-.65c.367-.153.75-.23 1.148-.23h15c.398 0 .78.077 1.148.23.368.152.692.368.973.65.282.28.498.605.65.973.153.367.23.75.23 1.148v3H28.5zm-3 39h-15c-.398 0-.78-.076-1.148-.228a2.971 2.971 0 01-.973-.65 2.97 2.97 0 01-.65-.974A2.972 2.972 0 017.5 43.5v-3h21v3c0 .398-.076.78-.228 1.148a2.971 2.971 0 01-.65.973 2.97 2.97 0 01-.974.65c-.367.153-.75.229-1.148.229v0zm.36-27.938a3.762 3.762 0 00-1.255-.872 3.761 3.761 0 00-1.496-.306c-.52 0-1.019.102-1.496.306a3.762 3.762 0 00-1.255.872L18 21l-2.358-2.438a3.762 3.762 0 00-1.255-.872 3.761 3.761 0 00-1.496-.306c-.52 0-1.019.102-1.496.306a3.762 3.762 0 00-1.255.872c-.366.383-.647.82-.844 1.313A4.082 4.082 0 009 21.407c0 .53.098 1.04.296 1.532.197.492.478.93.844 1.313l7.022 7.262a1.146 1.146 0 00.838.359 1.147 1.147 0 00.838-.359l7.022-7.262c.366-.383.647-.82.844-1.313A4.082 4.082 0 0027 21.407c0-.53-.099-1.04-.296-1.532a4.083 4.083 0 00-.844-1.313z"></path></>;
      break;

    case 'sperm':
      <path d="M24 9c0 3 4.5 7.5 12 7.5 5.8 0 10.5-3.358 10.5-7.5 0-4.142-4.7-7.5-10.5-7.5C28.5 1.5 24 6 24 9zm-4.5 21c0 1.8 2.7 4.5 7.2 4.5 3.48 0 6.3-2.014 6.3-4.5s-2.82-4.5-6.3-4.5c-4.5 0-7.2 2.7-7.2 4.5zM24 9c-3 0-4.5-3-7.5-3S12 10.5 9 10.5 4.5 9 1.5 9m18 21c-2.4 0-3.6-3-6-3s-3.6 4.5-6 4.5-3.6-1.5-6-1.5"></path>
      break;

    case 'sperm-egg':
      <><path d="M46.5 25.5c-13.253 0-23.997-10.745-23.997-24m23.997 9a9 9 0 01-8.999-9M15.496 31.007c1.172 1.171 3.546.696 5.303-1.061 1.758-1.758 2.233-4.132 1.061-5.303-1.171-1.172-3.546-.697-5.303 1.06-1.757 1.758-2.232 4.132-1.06 5.304z"></path>
      <path d="M15.498 31A8.083 8.083 0 007.5 34.494c-2.613 3.922 1.5 7.5-5.999 12m24.495-3.489c1.17 1.173 3.544.7 5.302-1.057 1.758-1.757 2.235-4.131 1.064-5.304l-.002-.003c-1.17-1.172-3.545-.7-5.303 1.058-1.758 1.756-2.234 4.13-1.064 5.303l.003.003v0zM26.004 43a8.084 8.084 0 00-8 3.5"></path></>
      break;

    case 'star-full':
      <path d="M24.866 11.036l3.978 7.954 7.652.766c.407.04.679.252.817.637.138.385.062.722-.227 1.011l-6.306 6.304 2.338 8.566c.109.404.002.738-.321 1.003-.324.265-.673.305-1.047.119L24 33.52l-7.75 3.88c-.375.187-.724.148-1.047-.118-.323-.266-.43-.6-.32-1.004l2.337-8.57-6.306-6.308c-.29-.289-.365-.626-.227-1.01.138-.386.41-.598.817-.638l7.652-.766 3.978-7.954c.179-.357.468-.534.867-.534.399.001.687.18.865.538v0z"></path>
      break;

    case 'star-half':
      path = <path d="M24 1.497c-.6 0-1.2.4-1.4.798l-6.8 13.372L3 17.064c-1 0-1.6.799-1.4 1.597 0 .4.2.798.4.998l10.6 10.578-4 14.17c-.2.799.2 1.797 1.2 1.996.4.2.8 0 1.2-.2l13-6.386V1.497z"></path>
      break; 

    case 'star-plus':
      path = <> <path d="M22.504 34.498a11.977 11.977 0 00.913 4.593 11.965 11.965 0 002.601 3.893 11.981 11.981 0 006.143 3.285 11.97 11.97 0 004.682 0 11.96 11.96 0 004.325-1.792 11.964 11.964 0 003.31-3.311 11.969 11.969 0 001.791-4.327 11.977 11.977 0 000-4.683 11.967 11.967 0 00-1.791-4.326 11.966 11.966 0 00-3.31-3.312 11.977 11.977 0 00-6.666-2.023 11.97 11.97 0 00-4.592.914 11.974 11.974 0 00-3.892 2.602 11.971 11.971 0 00-2.6 3.894 11.976 11.976 0 00-.914 4.593v0zm11.998-6.001v12.002m-5.999-6.001H40.5"></path>
      <path d="M45.465 20.22l.556-.551a1.58 1.58 0 00.437-.792c.069-.306.05-.607-.058-.902a1.578 1.578 0 00-.535-.73 1.585 1.585 0 00-.844-.325l-12.878-1.276-6.681-13.252a1.608 1.608 0 00-.604-.651 1.607 1.607 0 00-.854-.24c-.307 0-.592.08-.854.24-.262.16-.463.377-.604.65l-6.69 13.253-12.878 1.277a1.577 1.577 0 00-.844.325c-.25.19-.428.433-.536.729a1.58 1.58 0 00-.058.902c.069.307.215.57.438.792l10.598 10.508-3.911 14.278c-.082.31-.074.617.025.92.099.305.273.558.52.76.25.202.532.32.85.355.319.034.62-.02.906-.164l7.473-3.703"></path></>
      break;

    case 'star-tick':
      path = <><path d="M45.465 20.22l.556-.551a1.58 1.58 0 00.437-.792c.069-.306.05-.607-.058-.902a1.578 1.578 0 00-.535-.73 1.585 1.585 0 00-.844-.325l-12.878-1.276-6.681-13.252a1.608 1.608 0 00-.604-.651 1.607 1.607 0 00-.854-.24c-.307 0-.592.08-.854.24-.262.16-.463.377-.604.65l-6.69 13.253-12.878 1.277a1.577 1.577 0 00-.844.325c-.25.19-.428.433-.536.729a1.58 1.58 0 00-.058.902c.069.307.215.57.438.792l10.598 10.508-3.911 14.278c-.082.31-.074.617.025.92.099.305.273.558.52.76.25.202.532.32.85.355.319.034.62-.02.906-.164l7.473-3.703"></path>
      <path d="M22.504 34.498a11.977 11.977 0 00.913 4.593 11.965 11.965 0 002.601 3.893 11.981 11.981 0 006.143 3.285 11.97 11.97 0 004.682 0 11.96 11.96 0 004.325-1.792 11.964 11.964 0 003.31-3.311 11.969 11.969 0 001.791-4.327 11.977 11.977 0 000-4.683 11.967 11.967 0 00-1.791-4.326 11.966 11.966 0 00-3.31-3.312 11.977 11.977 0 00-6.666-2.023 11.97 11.97 0 00-4.592.914 11.974 11.974 0 00-3.892 2.602 11.971 11.971 0 00-2.6 3.894 11.976 11.976 0 00-.914 4.593v0z"></path>
      <path d="M39.85 31.009l-5.81 7.747a1.495 1.495 0 01-1.094.597 1.487 1.487 0 01-.63-.091 1.486 1.486 0 01-.537-.344l-3-3"></path></>
      break;

    case 'star-rating-trio':
      path = <path d="M37.24 26.25l2.914 5.247h4.962c.278-.005.533.068.766.22.232.151.403.355.51.61.108.257.134.52.08.793-.054.272-.18.506-.377.7l-4.557 4.98 2.524 5.8c.114.272.138.552.071.84-.066.287-.21.528-.432.722-.222.195-.48.307-.774.335a1.342 1.342 0 01-.823-.18L36 42.88l-6.1 3.432a1.35 1.35 0 01-.824.178 1.344 1.344 0 01-.773-.335 1.344 1.344 0 01-.433-.722 1.343 1.343 0 01.069-.84l2.526-5.8-4.556-4.973a1.329 1.329 0 01-.378-.701 1.33 1.33 0 01.08-.792 1.33 1.33 0 01.51-.611c.233-.152.488-.225.766-.22h4.962l2.913-5.246c.257-.507.67-.76 1.239-.76.568 0 .98.255 1.237.762h0zm-24.004 0l2.913 5.247h4.962c.278-.005.532.07.765.22.232.152.402.356.51.611.107.256.134.52.08.791a1.33 1.33 0 01-.374.702l-4.559 4.978 2.526 5.8c.113.272.136.552.07.839a1.344 1.344 0 01-.434.722c-.222.195-.48.306-.773.335a1.344 1.344 0 01-.823-.178l-6.1-3.438-6.104 3.432a1.342 1.342 0 01-.82.177 1.342 1.342 0 01-.771-.333 1.342 1.342 0 01-.433-.72 1.342 1.342 0 01.066-.836l2.525-5.8-4.557-4.978a1.33 1.33 0 01-.378-.701 1.33 1.33 0 01.08-.792 1.33 1.33 0 01.51-.611 1.33 1.33 0 01.766-.22h4.963l2.914-5.246c.257-.507.67-.76 1.238-.76.569 0 .981.253 1.238.76v0zM25.238 2.26l2.91 5.24h4.962a1.33 1.33 0 01.765.22c.232.152.403.356.51.612.108.256.135.52.08.792-.054.272-.18.506-.377.7L29.533 14.8l2.524 5.8c.114.272.138.552.071.84a1.34 1.34 0 01-.432.723c-.222.194-.48.306-.774.334a1.342 1.342 0 01-.823-.18L24 18.882l-6.099 3.43c-.255.148-.53.208-.823.18a1.341 1.341 0 01-.773-.335 1.342 1.342 0 01-.433-.724 1.342 1.342 0 01.071-.84l2.524-5.793-4.554-4.974a1.33 1.33 0 01-.378-.701 1.328 1.328 0 01.08-.792 1.33 1.33 0 01.51-.611 1.33 1.33 0 01.766-.22h4.958l2.915-5.24c.257-.506.67-.759 1.237-.759.567 0 .98.253 1.237.759h0z"></path>
      break;

    case 'steth': 
      path = <><path d="M19.502 4.51H22.5a2.999 2.999 0 013 2.998v11.994c0 6.625-5.37 11.994-11.994 11.994S1.51 26.126 1.51 19.502V7.508A2.999 2.999 0 014.509 4.51h2.999m0-2.999v7.496m11.994-7.496v7.496m14.993 10.495a5.997 5.997 0 1011.994 0 5.997 5.997 0 00-11.994 0z"></path>
      <path d="M13.505 31.496v1.5c0 7.452 6.041 13.493 13.493 13.493s13.494-6.041 13.494-13.493v-7.497"></path></>;
      break;

    case 'steth-heart':
      path = <><path d="M14.106 35.95a27.704 27.704 0 003.58-1.537M8.348 46.501a6.848 6.848 0 100-13.696 6.848 6.848 0 000 13.696z"></path>
      <path d="M8.37 40.631a1 1 0 010-2m0 2a1 1 0 000-2"></path>
      <path d="M42.313 44.583l-.836 1.768-10.01-6.366a4.432 4.432 0 01-1.625-5.636 4.428 4.428 0 015.4-2.314L46.5 35.741l-.839 1.768"></path>
      <path d="M27.2 28.091c5.2-4.09 12.452-10.868 12.452-16.748 0-10.73-13.466-13.656-17.6-3.902C17.9-2.313 4.434.613 4.434 11.343c0 9.16 16.892 20.016 17.61 20.484 2.302 1.508 6.57 3.326 7.676 2.8"></path></>
      break;

    case 'upload-button':
      path = <><path d="M24.004 46.502v-30m9 9l-9-9-9 9"></path>
      <path d="M16.504 39H12a6 6 0 01-6-6V7.502a6 6 0 016-6h24a6 6 0 016 6V33a6 6 0 01-6 6h-4.5"></path></>
      break;

    case 'video-pause':
      path = <path d="M19.5 16.5v15m10.11-15v15M1.5 24c0 12.426 10.074 22.5 22.5 22.5S46.5 36.426 46.5 24 36.426 1.5 24 1.5 1.5 11.574 1.5 24z"></path>
      break;
      
    case 'video-play':
      path = <><path d="M50.474 28a22.475 22.475 0 11-44.95 0 22.475 22.475 0 0144.95 0v0z"></path>
      <path d="M22.007 35.212a3.191 3.191 0 00.514 1.774c.346.54.812.944 1.396 1.21a3.19 3.19 0 001.83.254 3.19 3.19 0 001.674-.781l9.349-8.17a2 2 0 00.499-2.321 1.977 1.977 0 00-.499-.677l-9.35-8.19a3.212 3.212 0 00-1.672-.76 3.212 3.212 0 00-1.82.26 3.211 3.211 0 00-1.392 1.198 3.213 3.213 0 00-.53 1.76v14.443h0z"></path></>
      break;
}

  return (
    <div className={`p-1 ${cont} ${cls||''} shrink-0 inline-block`}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" width="100%" className={svg_cls}>
        <g className={cl} style={style} clipPath={`url(#${name})`}>{path}</g><defs><clipPath id={name}><path fill="#fff" d="M0 0H48V48H0z"></path></clipPath></defs>
      </svg>
    </div>
  );
}
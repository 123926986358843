import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet"
import { Popup, Button, Container, Icon } from "semantic-ui-react";
import { ObjectInspector, chromeLight } from 'react-inspector';
import { DataConnector, ListTable, ListPagination } from 'xAppLib/DataTable';
import inventory_model from "../../models/inventory_model";
import AddInventory from "./AddInventory";
import { useAsync } from "../../xAppLib/Hooks/useAsync";

export default function InventoryList() {
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const [refresh, setRefresh] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const fields = useMemo(() => {
		return inventory_model.list_fields;
	}, []);

	const { loading, data } = useAsync(async () => inventory_model.formatDbData(await inventory_model.get()), [refresh]);

	return <Container className='wide-content pb-[50px] inventory' fluid>
		<Helmet>
			<title>Inventory Management System</title>
		</Helmet>

		<div className="mb-[48px] flex items-center justify-between">
			<div>
				<h1 className="mb-0">Clinic Inventory</h1>
				<p>Add new inventory and manage your stock levels</p>
			</div>
			<div>
				<Button icon compact size={"big"} color="green" onClick={_ => setShowModal(!showModal)}>
					<Icon name='plus' /> Add Inventory
				</Button>
			</div>
		</div>
		<DataConnector
			pageSize={pageSize}
			page={page}
			refresh={refresh}
			data={data}
		>
			{(data, _, pages, total, pageSize) => {
				return <>
					<ListPagination
						page={page}
						pages={pages}
						pageSize={pageSize}
						loading={loading}
						data={data}
						total={total}
						boundaryRange={3}
						showPageSizeSelector={true}
						onPageSizeChange={setPageSize}
						onPageChange={setPage} />

					<ListTable data={data} loading={loading}>
						{(row, index) => {
							return <>
								<ListTable.Cell row={row} index={index} label='No'>
									{(value, r, index) => (index + 1 + (page - 1) * pageSize)}
								</ListTable.Cell>

								{fields.map((f, fi) => (
									<ListTable.Cell row={row} index={index} key={fi} f={f} refresh={() => setRefresh(new Date())} />
								))}

								<ListTable.Cell
									visible={false}
									// visible={user_model.check_access('inventory_admin')}
									row={row} index={index} label='Full data'
								>
									{(value, row) => (<Popup
										trigger={<Button icon='add' />}
										position='left center'
										on='click'
										style={{ height: '80%', width: '80%', minWidth: 700, overflow: 'scroll' }}
									>
										<ObjectInspector
											data={row}
											expandLevel={2}
											theme={{ ...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 }) }}
										/>
									</Popup>)}
								</ListTable.Cell>
							</>
						}}


					</ListTable>

					<ListPagination
						page={page}
						pages={pages}
						pageSize={pageSize}
						loading={loading}
						data={data}
						total={total}
						boundaryRange={3}
						showPageSizeSelector={true}
						onPageSizeChange={setPageSize}
						onPageChange={setPage} />
				</>
			}}
		</DataConnector>

		<AddInventory setRefresh={setRefresh} isOpen={showModal} onClose={_ => setShowModal(false)} />
	</Container>
}
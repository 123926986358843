import React from 'react';
import { Link } from 'react-router-dom'
import Card from './Card';
import { get_price_exp } from '../../helpers/price_exp';

export default function CatCard(props) {
	const {el, cat_nm, srch_str, cat_loc, el_conf, cat_data_db } = props;
	console.log(el)
	const multiDayCert = ['med-cert-multiple', "ShortConsMC"].includes(el.c);
	const el_conf_cst = 
		cat_loc == 'certs' && !multiDayCert && get_price_exp('mcpt') || 
		el_conf('cst_ref') && app.site_status?.prs_mc?.[el_conf('cst_ref')] ||
		el_conf('cst');
	const el_conf_badge = el_conf('badge');

	const med_sub = app.app_data.ws_data?.cat_data?.[cat_nm]?.meds_sub_list?.[el.k];
	const CMI = el.CMI || med_sub?.CMI;
	const info_link = med_sub?.IL || app.app_data.ws_data?.db[`meds/${el.k}`]?.IL;
	const alt_name = el.alt_name || med_sub?.alt_name || el.n?.toLowerCase() === 'pre-exposure prophylaxis' && 'PrEP';
	
	const to = {
		pathname: el.t === 'r' ? `${app.consts.routes.med_route}/${cat_nm}/${el.k}/prescription` : (el.lnk || el.t == 'l' && el.x ||
			(
				(el.t == 'c' ? app.consts.routes.cat_route : el.t == 'm' ? app.consts.routes.med_route : '/err')
				//+ (el.t=='c' ? 'cat' : el.t=='m' ? 'med' : 'err')
				+ "/"
				+ (cat_nm)
				+ "/"
				+ (srch_str ? '' : cat_loc + (!cat_loc || cat_loc.endsWith('/') ? el.t === 'm' ? `${el.c || el.k}/` : '' : '/'))
				+ (srch_str && el.l || '')
				+ el.k)),
	}
	const state = {
		srch_str,
		c: el.c,
		cons_desc: el.t == 'l' && `Required Medication: ${el.n}` || undefined,
		...(['l', 'x'].includes(el.t) && {
			hdr_warn: // this is likely a link to a consult, so show a warning there from:
				// the current category
				cat_data_db?.[cat_loc]?.f?.cat_warn ||
				// or the category of the med the person is (probably) searching for
				cat_data_db?.[el.l?.split('/')[0]]?.f?.cat_warn
		})
	}
	let linkProps = {}, El
	if (to.pathname?.startsWith('https://')) {
		El = 'a'
		linkProps = {
			href: to.pathname,
			target: '_blank'
		}

	}
	else if(el?.to === 'login') {
		El = 'button'
		linkProps = {
			onClick: () => app.trigger(app.events['REQUEST_LOGIN'])
		}
	}
	else {
		El = Link
		linkProps = {
			state,
			to
		}
	}

	return (
		<Card
			{...props}
			El={El}
			props={linkProps}
			to={to}
			el_conf_badge={el_conf_badge}
			CMI={CMI}
			el_conf_cst={el_conf_cst}
			alt_name={alt_name}
			info_link={info_link}
			multiDayCert={multiDayCert}
		/>
	)
}

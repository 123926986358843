import React from 'react';
import { Helmet } from "react-helmet"
import { Container, Grid } from 'semantic-ui-react'
import IcosmHeader from './components/IcosmHeader';

const info = [
    {
        name: "alocuro",
    },
    {
        name:"galderma",
    },
    {
        name:"envogue",
    },
    {
        name:"teoxane",
    },
    {
        name: "croma",
    },
    {
        name:"allergan",
    },
    {
        name:"merz",
    },
]

const OurPartners = () => {

	return <div className="partners">

		<Helmet>
			<title>Our Partners</title>
		</Helmet>
        
        <IcosmHeader title="Our Partners"/>
		<Container className="px-2 py-12 sm:p-16 mx-auto">
           <div className="text-center font-bold text-icosm-gold-200 md:w-3/4 mx-auto mb-8 sm:pb-14">
                <p className='text-icosm_xl'>InstantCosmetics nurses have 24/7
                    access to quality products from the
                    best in the industry, at the most
                    competitive prices.<br className="md:hidden"/>
                    <br className="md:hidden"/> We partner with only the best
                    suppliers and we are dedicated
                    to delivering products our nurses and
                    clinics know and trust, backed by
                    scientific research,
                    innovation and education.</p>
            </div>

            <Grid stackable centered columns={info.length}>
                {info.map((el, i) =>
                    <Grid.Column style={{padding: '.5em'}} mobile={16} tablet={4} computer={2} key={i}>
                        <img src={`https://storage.googleapis.com/icosm/partners-${el.name}.png`} />
                    </Grid.Column>
                )}
            </Grid>
		</Container>
	</div>
}

export default OurPartners;

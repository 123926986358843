import React, { useEffect,useState } from 'react';
import { Loader, Modal,Header, Button, Icon } from 'semantic-ui-react'
import Support from 'xAppLib/UIelems/Support'
import SupportComplication from 'xAppLib/UIelems/SupportComplication'
import device_model from 'models/device_model'
import user_model from 'models/user_model'

const ENABLED_COMPLICATIONS = true
 
const SupportActions = _ => {
	
	if (!app.state.loaded)
		return null

	return <div className='support-actions' >
        
                <div>
				
				{ENABLED_COMPLICATIONS && device_model.check_access('cosm') && <SupportComplication /> }

				{ !user_model.check_access('show_support') && (app.settings.is_cbls || app.settings.is_cmg) &&
					<Button
							className="support-button" compact size="large" color="orange" icon="talk" content_="Contact Us"
							onClick={ _=> app.trigger( app.events.SHOW_POPUP, {
																				"tp": "pu",
																				"pt": "contact",
																				"txt": "Contact Us",
																				"u": "contact-us"
																		} )	}
						/>
				}

				{ user_model.check_access('show_support') &&
					<Support />
				}
                </div>

			</div>
};

export default SupportActions

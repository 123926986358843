import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Container, Grid } from 'semantic-ui-react';
import IcosmHeader from './components/IcosmHeader';

const info_icons = [
	{
		title: 'Affordable',
		desc: 'Affordable scripts and access to all aesthetic brands',
		icon: '/affordable-icon.png'
	},
	{
		title: 'Support',
		desc: 'Ongoing industry support and best practice standards',
		icon: '/support-icon.png'
	},
	{
		title: 'App',
		desc: 'Fast easy to use app for all scripts',
		icon: '/app-icon.png'
	},
	{
		title: 'Medical',
		desc: 'Instant medical authority within national guidelines',
		icon: '/medical-icon.png'
	},
	{
		title: 'Discounts',
		desc: 'VIP discounts for our industry partners and associates',
		icon: '/discount-icon.png'
	}
]

const HowWeWork = () => {

	return <div className='how-we-work'>

		<Helmet>
			<title>How We Work</title>
		</Helmet>

        <IcosmHeader title="How We Work"/>

		<div className="bg-icosm-gold-200 px-14 py-16 text-center">
			<Container>
				<Grid stackable columns={info_icons.length}>
					{info_icons.map((el, i) =>
						<Grid.Column key={i}>
							<img style={{height: '2.5em', margin: '0 auto'}} src={`https://storage.googleapis.com/icosm${el.icon}`}/>
							<h5 className="text-icosm-blue">{el.title}</h5>
							<p className="text-white" style={{lineHeight: '1.1'}}>{el.desc}</p>
						</Grid.Column>
					)}
				</Grid>
			</Container>
		</div>
		
		<div className="text-center bg-white px-8 sm:py-16 py-12">
			<div className="info-box text-left text-icosm-gold-200 bg-cover bg-no-repeat m-auto bg-left-top px-4 py-4 lg:w-3/4"></div>

			<h4 className="text-icosm-gold-200">Contact our friendly team to find out more!</h4>
			<Link to="/contact" className="cta text-icosm-gold-50 bg-icosm-gold-200">Contact Us</Link>
		</div>
	</div>
}

export default HowWeWork;

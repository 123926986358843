import React, { Component, useState, useEffect } from 'react';
import {
		Menu,
		Image,
	} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

import { Deploy } from 'cordova-plugin-ionic';
import { slide as MenuContainer } from 'react-burger-menu'

import AdminMenu from './AdminMenu'
import MainMenuItems from './MainMenuItems'
import { useUser } from 'xAppLib/Hooks';
import MegaMenu from '../MenuNav/MegaMenu';

const SideMenu = ({showMenu, onMenuChange}) => {
	const user = useUser()
	const [devChannel,setDevChannel] = useState(false)
	const [showSideMenu, setSideMenu] = useState(true);

	useEffect(()=>{
		async function get_channel() {
			try {
				const info = await Deploy.getCurrentVersion()
				setDevChannel({devChannel:info?.channel=='dev'})
			} catch (e) {
				console.log(e)
			} 
		}
		if (app.settings.is_ionic) {
			get_channel()
		}
	},[])

	return (
		<MenuContainer pageWrapId={ "page-wrap" } 
			outerContainerId={ "app" }
			isOpen={ showMenu } 
			customBurgerIcon={ false }
			onStateChange={ ({isOpen}) => onMenuChange?.(isOpen) }
			width={ 400 }
			className={user.user_in ? 'user-in' : ''}
			right
			>
			{(!app.settings.icosm && !app.settings.iscr) && <Image
					bordered={false}
					src={app.runtime.sidemenuLogo || "images/logos/instant-scripts-logo2-wide-white.svg"}
					className="side-logo"
					
				/>}
			<Menu 
				inverted vertical fluid className='side-menu'
				onClick={_=>{onMenuChange(false)}}
				>
					{ app.settings.iscr && <MegaMenu inSideMenu setMainSideMenu={(v) => {setSideMenu(v)}} />}
					{ showSideMenu && <MainMenuItems inSideMenu />}
					{app.settings.icosm && 
						<Menu.Item
						content={'Contact Us'}
						as={Link}
						to={'/contact'}
						key={'header_menu_mob_contact'} />}

			</Menu>
			
			{(user.claims.admin && showSideMenu) && <AdminMenu inverted vertical inSideMenu/>}
			{app.settings.is_ionic && <Menu 
				inverted vertical fluid className='side-meta'
				 onClick={_=>{onMenuChange?.(false)}}
				>
				<Menu.Item fitted='vertically'>© {new Date().getFullYear()} InstantScripts</Menu.Item>
				<Menu.Item className='version'>Build: {__GIT_HASH__} - {__BUILD__} {devChannel&& "dev"}
				{app.runtime.org&&<i><br/>{app.runtime.org.name}</i> || null}
				{app.dvc.org&&<><br/>{app.dvc.org.name}</> || null}</Menu.Item>
			</Menu>}
			
		</MenuContainer>
	)
}
 
export default SideMenu;

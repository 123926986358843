import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Button } from 'semantic-ui-react'
import IcosmHeader from './components/IcosmHeader';

const WhatYouNeed = () => {

	return <div className="what-you-need bg-icosm-gold-50">

		<Helmet>
			<title>What you Need</title>
		</Helmet>

        <IcosmHeader title="What you Need"/>

		<Container className="px-4 py-8 sm:p-12 text-center">
            <div className="text-center text-icosm-gold-200 px-8 mb-8">
                <h3>Ready to get started with InstantCosmetics?</h3>
                <h5>Download our Welcome kit to learn more and our nurse application form to get started.</h5>
            </div>

			<Button as='a' href="https://storage.googleapis.com/icosm/InstantCosmetics-Welcome.pdf" className="cta">Welcome Document</Button>
			<Button as='a' href="https://storage.googleapis.com/icosm/InstantCosmetics-NurseApplication.pdf" className="cta">Nurse Application Form</Button>
		</Container>
	</div>
}

export default WhatYouNeed;

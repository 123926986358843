import React, {Component} from 'react'
import { Link } from 'react-router-dom'

import {
		Segment,
		Header,
		Form,
		Input,
		Icon,
		Dropdown,
		Message,
		Loader,
	} from 'semantic-ui-react'


import UniForm from '../UniForm/UniForm'
import UniFormSUI from '../UniForm/UniForm-SUI'

import user_model from 'models/user_model'
import AppendIf from '../UIelems/AppendIf'
import ProfileEdit from './ProfileEdit'
import UserAvatar from './UserAvatar'
import { cls } from 'views/NUI/utils'
import Experiment from '../AB/Experiment'

// import MessageBar from '../UIelems/MessageBar'

const ENABLE_GOOGLE = true;
const ENABLE_FACEBOOK = true;
const FACEBOOK_TEMP_DEACTIVATE = false;
const ENABLE_APPLE = true;

const Spacer = () => {
	return (<div style={{height:'.75em'}}></div>);
}


const LoginWrap = ({children, className}) => {
	return <div className={cls('login-page', app.settings.icann && 'icann', className)}>
		<aside>{app.state.loaded && children || <Loader active inline='centered' />}</aside>
	</div>
}

const Button = ({children, className, content, onClick, loading, fluid = true, primary, as : As = 'button', ...restOfProps }) => {
	const c = cls(
					'ui button',
					primary ? 'primary' : 'basic',
					className,
					fluid&&'w-full',
					loading&&'loading',
				)
	return <As onClick={onClick} className={c} {...restOfProps}>{children || content || 'Click Me'}</As>
}

const Divider = ({children}) => {
	return <div className=''>
			<div className="relative my-6">
			<div className="absolute inset-0 flex items-center" aria-hidden="true">
				<div className="w-full border-t border-gray-300" />
				</div>
			<div className="relative flex justify-center">
			<span className="px-2 bg-white text-sm text-gray-500">{children}</span>
			</div>
		</div>

	</div>
}

const BoxWrap = ({children}) => {
	return <div className={cls(app.runtime?.login_type=='popup' ? 'sm:shadow-none space-y-2' : 'shadow-md', 'bg-white p-4 md:p-6 space-y-4 rounded-lg')}>{children}</div>
}

 

export default class UserArea extends Component {

	constructor (props) {
		super(props)

		this.state = {
			show: props.show || 'login',

			init_loaded: false,

			user: null,

			loading:false,

			message:null,

			validate:false,

			email:'',
			password:'',
			signup_name:'',
			signup_email:'',
			signup_password:'',
			verify_password:'',
			signup_data: null,

			remember:app.settings.is_ionic
		}

	}

	// 		--------------------------------		--------------------------------		---------

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.show !== nextProps.show && nextProps.routed) {
			console.log('setting show from props', nextProps.show);
			return { show: nextProps.show };
		}
		return null;
	}

	// 		--------------------------------		--------------------------------		---------

	async componentDidMount() {
		app.on(app.events.USER_UPD, this.user_updated)
		app.on(app.events.USER_PROF, this.user_updated)

		if (this.state.show=='r_p') {

			this.setState({
							loading:true,
							message: {
										header: 'Loading',
										// content: scr_pts_data.err,
										// error: true,
									},
						})

			const scr_pts_data = await user_model.load_script_pts_bysid(this.props.sid, this.props.enc)
			// console.log('scr_pts_data', scr_pts_data);

			if (scr_pts_data.res=='ok') {
				this.setState({
					init_loaded: true,
					user: {...app.user},
					scr_pts_data,
					loading: false,
					message:null,
				})
			}
			else {
				if (scr_pts_data.err_act=='login')
				this.login()
				this.setState({
					init_loaded: true,
					user: {...app.user},
					message: {
							header: 'Error',
							content: scr_pts_data.err,
							error: true,
						},
					loading: false,
				})
			}
				

		} else {
			this.user_updated()
		}
	}

	// 		--------------------------------		--------------------------------		---------

	componentWillUnmount () {
		app.off(app.events.USER_UPD, this.user_updated)
		app.off(app.events.USER_PROF, this.user_updated)
		app.trigger( app.events.CLEAR_MESSAGE, 'user_auth_msg' )
		this.setState({loading:false})
		this.donotshow = true;
	}

	// 		--------------------------------		--------------------------------		---------

	setMessage (header, content, error = true) {
		if (!header) 
			app.trigger( app.events.CLEAR_MESSAGE, 'user_auth_msg' )	
		 else
		 	app.trigger(app.events.SHOW_MESSAGE, header, content, error?'negative':'positive', 'user_auth_msg')
	}

	gotoStep(step) {
		app.trigger( app.events.CLEAR_MESSAGE, 'user_auth_msg' )
		this.setState({
			message:null,
			show:step,
			loading:false
		})
	}

	login = () => {
		return app.runtime?.login_type=='popup' ? app.trigger(app.events.REQUEST_LOGIN) : app.history.push('/login');
	}

	login_btn = (type, content, size='mini', cls, icon) => {
		const path = app.runtime?.login_type=='popup' ? '#' : '/login';
		return type === 'link' ? 
		<Link as={Link} to={path} className='text-is-blue text-center block'  onClick={() => /*app.runtime?.login_type=='popup' ? this.login() :*/ this.gotoStep('login')}>
			{content}
		</Link>
		: 
		<Button className={cls} size={size} as={Link} to={path} onClick={() => /*app.runtime?.login_type=='popup' ? this.login() :*/ this.gotoStep('login')} fluid>
			{icon}
			{content}
		</Button>
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	user_updated = () => {

		!this.donotshow &&
			this.setState({
				init_loaded: true,
				user: {...app.user},
				loading:false
			})
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async frb_login () {

		const { email, password, remember } = this.state
		this.setState({loading:true,message:null})
		const result = await user_model.frb_login(email, password, !!remember)
		if (result instanceof Error) {
			if (result?.code !== 'auth/multi-factor-auth-required')
				this.showError(result)
			this.setState({loading:false})
		}


	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async complete_signup () {
		const { signupExp } = this.props

		app.state.in_sgup_st2 = false;
		!signupExp && app.trigger(app.events.SHOW_POPUP, {
					txt: 'Medical History',
					pn: 'medical-history-and-chro-cond',
					noCloseBtn: true,
					cst_modal: true
				})
}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async pts_signup (d) {

		this.setState({validate: true})

		// const { signup_last_name, signup_dob, signup_password, verify_password } = this.state

		// if (!signup_last_name || !signup_dob || !signup_password) {
		// 	this.setMessage('All field are required!')
		// 	return
		// }

		if (d.password != d.verify_password) {
			this.setMessage("Passwords don't match!")
			return
		}

		this.setState({loading:true, message:null})

		const result = await user_model.pts_signup(this.props.sid, d)

		// console.log(result);

		if (result.res!='ok' || result instanceof Error) {
			this.setState({loading:false})
			this.setMessage('Error signing up.', result.err_msg=="no data" && 'Please fill all fields' || result.err_msg, true)
		} else {
			this.setState({loading:false})
			this.login()
			this.setMessage(`Account for ${d.email} created! \n${result.scripts_hist_fnd>0&&`scripts history found\n`||''} Please log in now.`, result.msg, false)

			// setTimeout(_=>{
			// 	this.setState({loading:false})
			// }, 500)
		}

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async pts_signup_1 () {

		this.setState({validate: true})

		const { signup_last_name, signup_dob, signup_password, signup_sex, verify_password } = this.state

		if (!signup_last_name || !signup_dob || !signup_password || !signup_sex) {
			this.setMessage('All field are required!')
			return
		}

		if (signup_password != verify_password) {
			this.setMessage("Passwords don't match!")
			return
		}

		this.setState({loading:true, message:null})

		const result = await user_model.pts_signup(this.props.sid, { last_name:signup_last_name, dob:signup_dob, sex:signup_sex, pass:signup_password })

		// console.log(result);

		if (result.res!='ok' || result instanceof Error) {
			this.setState({loading:false})
			this.setMessage('Error signing up.', result.err_msg=="no data" && 'Please fill all fields' || result.err_msg, true)
		} else {
			this.setState({loading:false})
			this.setMessage('Account created! Please log in now.', result.msg, false)

			// setTimeout(_=>{
			// 	this.setState({loading:false})
			// }, 500)
		}

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async usr_signup (d) {

		if(d.password.length < 8 || !/[\w]/g.test(d.password) || !/[\d]/g.test(d.password)){
			this.setMessage("Password is not strong enough!");
			return
		}

		if (d.password != d.verify_password) {
			this.setMessage("Passwords don't match!")
			return
		}

		this.setState({loading:true, message:null})

		const result = await user_model.usr_signup(d)

		// console.log('usr_signup', d, result);

		if (result.res!='ok' || result instanceof Error) {
			this.setState({loading:false})
			let err_msg 
			const brand = app.settings.iprep && 'HIVprevent' || app.settings.is_cmg && 'Haiku Health' || app.settings.is_cbls && 'BLS Clinics' || app.settings.icann && 'Benicura';

			if (!app.settings.iscr && result.err_code == 'account-exist')
				err_msg = `It appears as if you already have a ${brand} account with this email address. ${brand} is run by InstantScripts. If you already have an InstantScripts account please sign in to get started.`
			else 
				err_msg = result.err_msg=="no data" && 'Please fill all fields' || result.err_msg
			this.setMessage('Error signing up.', err_msg, true)
		} else {
			this.setState({loading:false})
			this.login()
			this.setMessage('Account for ' + d.email + ' created.', result.msg, false)
			
			app.runtime?.login_type=='popup' 
				? this.gotoStep('signup_step_2')
				: app.trigger(app.events.SHOW_POPUP, { txt: 'Medical History', pn: 'medical-history-and-chro-cond', noCloseBtn: true, cst_modal: true })

			setTimeout( _=> {
					app.signup = true;
					app.runtime?.login_type=='popup' && (app.state.in_sgup_st2 = true);
					user_model.frb_login(d.email, d.password)
				}, 1500)

			// setTimeout(_=>{
			// 	this.setState({loading:false})
			// }, 500)
		}

		return result

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async upd_prof (d) {

		this.setState({loading:true, message:null})

		const result = await user_model.save_prof(d, r => {return r})
		
		// user_model.save_prof(d, result => console.log('UserArea ProfileEdit onUpdate saved', d, result) )

		if (result.res!='ok' || result instanceof Error) {
			this.setState({loading:false})
			const err_msg = result.err_msg=="no data" && 'Please fill all fields' || result.err_msg || ''
			this.setMessage('Error updating profile', err_msg, true)
		} else {
			this.setState({loading:false})
			this.setMessage('Account updated', result.msg, false)
			
			setTimeout( _=> {
					this.gotoStep('signup_fin')
					this.complete_signup()
				}, 1500)
		}

		return result

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async frb_reset() {

		const { email } = this.state

		this.setState({loading:true})

		setTimeout(await (async _=>{
			const result = await user_model.frb_reset(email)
			this.setState({loading:false})
			if (result instanceof Error) {
				this.showError(result)
			} else {
				this.gotoStep('login')
				this.setMessage('Success','A new password reset email has been sent to you',false)
			}
		}),750)

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async g_login(e) {
		e && e.preventDefault()
		this.setMessage(null)
		const result = await user_model.g_login()
		if (result instanceof Error) {
			this.showError(result);
		}
	}
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async fb_login(e) {
		e && e.preventDefault()
		this.setMessage(null)
		const result = await user_model.fb_login()
		console.log(result)
		if (result instanceof Error) {
			this.showError(result);
		}
	}
	
	async apple_login(e) {
		e && e.preventDefault()
		this.setMessage(null)
		const result = await user_model.apple_login()
		if (result instanceof Error) {
			this.showError(result);
		}
	}

	async onepass_login(e) {
		e && e.preventDefault()
		this.setMessage(null)
		const result = await user_model.onepass_login()
		if (result instanceof Error) {
			this.showError(result);
		}
	}
	
	showError(error) {
		let { message } = error
		if ( ['auth/popup-closed-by-user','auth/cancelled-popup-request','auth/popup-blocked', '1001', '-5', '12501'].includes(error.code) )
			return
		if (error.code==='auth/internal-error')
			message = 'Password cannot be empty.'
		if (error.code === 'auth/invalid-login-credentials')
			message = 'Invalid email or password.'
		this.setMessage ("Login Error",message.replace('Firebase:', '').split('(')[0].trim(),true)
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	setData = (e,{name,value})=>{
		this.setState({[name]:value})
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	login_form_render () {
		const { size='mini' } = this.props
		const { email, password,loading} = this.state

		return <LoginWrap>
					<Form loading={loading} className="user_area pt-2">
					<h3 className="text-2xl font-bold">Log in{app.runtime.app=='iprep' && ' with HIVprevent OR Instantscripts credentials' || ''} to your account</h3>
					{!app.runtime.app=='iscr' && <p>{app.runtime.app=='iprep' && 'A HIVprevent OR Instantscripts' || 'An'} account is required to use {app.runtime.name}. If you already have an account please use this form to log in now. Otherwise tap the Create Account button.</p>}
					<BoxWrap>
		
						<h5>Log In</h5>
		
						<div>
						<Input className='login-email' fluid  placeholder='Email address'  name='email' data-testid='input-email' value={email} onChange={this.setData} size={size} />
						<Input className='login-password' fluid   placeholder='Password'  name='password' data-testid='input-password' value={password} onChange={this.setData}  type='password'  size={size} />
						</div>
						<div className="flex items-center justify-between">
							<div className="flex items-center">
								<input
									id="remember-me"
									name="remember-me"
									type="checkbox"
									checked={this.state.remember}
									className="h-4 w-4 text-is-blue focus:ring-is-blue border-gray-300 rounded"
									onChange={event=>this.setData(null,{name:'remember',value:event.target.checked})}
								/>
								<label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
									Remember me
								</label>
								</div>

								<div className="text-sm">
								{/*<Link to={app.runtime?.login_type=='popup' ? '#' : '/login/reset'}  onClick={ () => app.runtime?.login_type=='popup' ?  app.trigger(app.events.REQUEST_PASS_RESET_VERI) : this.gotoStep('reset') }  className="font-medium text-is-blue hover:text-is-blue-2">*/}
								<Link to={app.runtime?.login_type=='popup' ? '#' : '/login/reset'}  onClick={ () => this.gotoStep('reset') }  className="font-medium text-is-blue hover:text-is-blue-2">
									Forgot your password?
								</Link>
							</div>
						</div>

						<Button size={size} primary fluid content='Log in' data-testid='button-login' onClick={this.frb_login.bind(this)}/>
						{ app.settings.can_create_account && <>

						{/*							
							{app.runtime?.login_type=='popup' ? 
								<Button size={size} fluid  type="button" content='Create Account' onClick={() => app.trigger(app.events.REQUEST_JOIN) } /> : 
								<Button size={size} as={Link} to='/login/signup' fluid  type="button"  content='Create Account'  onClick={ () => this.gotoStep('signup') } />
							}
						*/}
						<br/>
						<Button size={size} as={app.runtime?.login_type=='popup' ? Button : Link} to='/login/signup' fluid type="button" content='Create Account' data-testid='button-create-account' onClick={() => this.gotoStep('signup')} />
						
						<Divider>Or continue with</Divider>
						{this.social_logins()}
							
						</>}
					</BoxWrap>
				</Form>
			</LoginWrap>
	}

	// 		--------------------------------		--------------------------------		---------

	reset_form_render () {
		const { size='mini' } = this.props
		const { email, loading } = this.state

		return <LoginWrap>
					<Form loading={loading} className='user_area pt-2'>
					<h3>Reset Password</h3>
					<BoxWrap>
						<Input  fluid  placeholder='Email'  name='email' value={email} onChange={this.setData}  />
						<Button size={size} fluid content='Reset' primary  onClick={this.frb_reset.bind(this)}  />
						{this.login_btn('link', 'Back to Login')}
					</BoxWrap>
				</Form>
			</LoginWrap>
	}

	social_logins() {
		const { size='mini' } = this.props
		return (
			<div>
				{(ENABLE_GOOGLE || app.enable_google) && (
					<>
						<Button
							size={size}
							fluid
							className="social"
							onClick={this.g_login.bind(this)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="22"
								height="22"
								viewBox="0 0 22 22"
								>
								<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
									<path
									fill="#4285F4"
									d="M21.5 11.2c0-.8-.1-1.5-.2-2.2H11v4.2h5.9c-.3 1.4-1 2.5-2.2 3.3v2.7h3.5c2.2-1.8 3.3-4.6 3.3-8z"
									></path>
									<path
									fill="#34A853"
									d="M11 21.9c3 0 5.4-1 7.2-2.6l-3.5-2.7c-1 .7-2.2 1-3.7 1-2.8 0-5.3-1.9-6.1-4.5H1.3v2.8c1.8 3.6 5.5 6 9.7 6z"
									></path>
									<path
									fill="#FBBC05"
									d="M4.9 13.1c-.2-.7-.3-1.4-.3-2.1 0-.7.1-1.4.3-2.1V6.1H1.3C.5 7.6.1 9.2.1 11c0 1.8.4 3.4 1.2 4.9l3.6-2.8z"
									></path>
									<path
									fill="#EA4335"
									d="M11 4.4c1.6 0 3 .6 4.2 1.6l3.1-3.1C16.5 1.1 14 .1 11 .1c-4.3 0-8 2.5-9.8 6l3.7 2.8c.9-2.6 3.3-4.5 6.1-4.5z"
									></path>
								</g>
								</svg>
							<span>Sign in with Google</span>
						</Button>
						<Spacer />
					</>
				)}
				{(ENABLE_FACEBOOK || app.enable_facebook) && (
					<>
						{(!FACEBOOK_TEMP_DEACTIVATE ||
							window.FACEBOOK_TEMP_REACTIVATE) && (
							<Button
								size={size}
								fluid
								color="facebook"
								className="social"
								onClick={this.fb_login.bind(this)}
							>
								<svg
									height="24"
									width="24"
									fill="none"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_206:8172)">
										<path
											d="M23.4 12.0672C23.4 5.73401 18.296 0.599976 12 0.599976C5.70392 0.599976 0.599976 5.73401 0.599976 12.0672C0.599976 17.7908 4.76879 22.5348 10.2187 23.395V15.3819H7.32419V12.0672H10.2187V9.5408C10.2187 6.66684 11.9207 5.07935 14.5247 5.07935C15.7719 5.07935 17.0765 5.30331 17.0765 5.30331V8.12532H15.639C14.2229 8.12532 13.7812 9.00925 13.7812 9.9161V12.0672H16.9429L16.4375 15.3819H13.7812V23.395C19.2312 22.5348 23.4 17.7908 23.4 12.0672Z"
											fill="#1877F2"
											fillRule="evenodd"
										/>
									</g>
									<defs>
										<clipPath id="clip0_206:8172">
											<rect
												height="22.8"
												width="22.8"
												fill="white"
												transform="translate(0.599976 0.599976)"
											/>
										</clipPath>
									</defs>
								</svg>
								<span>Sign in with Facebook</span>
							</Button>
						)}

						<Spacer />
					</>
				)}
				{(ENABLE_APPLE || app.enable_apple) && (
					<>
						<Button
							size={size}
							fluid
							color="black"
							className="social"
							onClick={this.apple_login.bind(this)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="17"
								viewBox="0 0 17 17"
								>
								<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
									<path
									fill="#000"
									fillRule="nonzero"
									d="M8.874 3.787c.741 0 1.67-.501 2.222-1.169.501-.605.866-1.45.866-2.295 0-.114-.01-.23-.031-.323-.824.031-1.815.553-2.41 1.252-.47.532-.897 1.366-.897 2.222 0 .125.021.25.031.292.053.01.136.02.22.02zM6.267 16.409c1.011 0 1.46-.678 2.722-.678 1.283 0 1.565.657 2.692.657 1.105 0 1.846-1.022 2.545-2.024.782-1.147 1.106-2.274 1.127-2.326-.073-.02-2.191-.886-2.191-3.317 0-2.107 1.67-3.057 1.763-3.13-1.106-1.585-2.785-1.627-3.244-1.627-1.242 0-2.254.751-2.89.751-.688 0-1.596-.71-2.67-.71C4.076 4.006 2 5.696 2 8.889c0 1.982.772 4.079 1.721 5.435.814 1.147 1.523 2.086 2.546 2.086z"
									></path>
								</g>
							</svg>
							<span>Sign in with Apple</span>
						</Button>

						<Spacer />
					</>
				)}
				{app.settings.onepass_avail && (
					<>
						<Button
							size={size}
							fluid
							color="black"
							className="social"
							onClick={this.onepass_login.bind(this)}
						>
							<img src="https://avatars.slack-edge.com/2022-05-16/3513428079223_1bcf24a16dcd4594a471_34.png" height={24} width={24} />
							<span>Sign in with OnePass</span>
						</Button>
                        <p className='mt-4' style={{textAlign: 'left', fontSize: 12}}>By activating OnePass you agree that InstantScripts and OnePass will confirm you have an account with the other to enable your accounts to be linked and you to receive your benefits and communications. Your personal information will be handled in accordance with the InstantScripts <Link onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: "cx_txt", txt: 'Privacy', pn: 'privacy-policy' })}>Privacy Policy</Link>.</p>
					</>
				)}
			</div>
		);
	}

	// 		--------------------------------		--------------------------------		---------

	signup_form_render () {
		const { loading } = this.state
		const { size='mini' } = this.props
		const { signup_name, signup_email, signup_password, signup_data, show } = this.state
		const step1 = show != 'signup_step_2'
		const step2 = !step1;
		const signup_fields = step1 ? ['email', 'first_name', 'last_name', 'password', 'verify_password', 'consent'] : ['dob', 'mobile', 'sex', 'full_address', 'atsi', 'medicare', 'conc_card', 'dva', 'em_con_name', 'em_con_mobile', 'em_con_rel'];

		return <LoginWrap className='signup'>
		<div className='user_area pt-2'>
			<h3 className="text-2xl font-bold">Get started with {app.runtime.name}</h3>

			<BoxWrap>

				<h5>{step1 ? 'Create Account' : 'Update Profile (optional)'}</h5>

				{step1 && 
					<>
						{this.social_logins()}
						<Divider>Or</Divider>
					</>
				}
				<ProfileEdit
					fields={user_model.PROF_FIELDS_SGUP.filter( f => signup_fields.includes(f?.name) )}
					btnSave={step1 ? 'Create Account' : 'Update Profile'}
					btnCancel={false}
					btnSaveAs={Button}
					requireAddress={false}
					step={step1 ? 'signup': 'signup_step_2'}
					// nextStep={() => step1 && this.gotoStep('signup_step_2')}
					onUpdate={async d => { step1 && await this.usr_signup(d) || step2 && await this.upd_prof(d) }}
				/>
				{step1 && this.login_btn('button', 'Already have an account? Log in here', size, 'social',  
					<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
					</svg>)
				}
				{step2 && <Button size={size} fluid  type="button"  content='Skip' data-testid='button-skip' onClick={ () => this.complete_signup()} />}
			
			</BoxWrap>
		</div>
		</LoginWrap>
	}

	// 		--------------------------------		--------------------------------		---------

	pts_reg_form_render () {
		const { loading, validate } = this.state
		const { size='mini' } = this.props
		const { scr_pts_data, signup_last_name, signup_dob, signup_sex, signup_password, verify_password } = this.state

		const itm_data = scr_pts_data && {
							first_name:scr_pts_data.spd_data__first_name,
							email:scr_pts_data.spd_data__email,
						}

		return !scr_pts_data ? <Form loading={loading} /> :
			<Segment loading={loading} className='user_area'>

				<h3>Welcome, {scr_pts_data.spd_data__first_name}!</h3>
				<h4>
					Thank you for using InstantScripts for 
					{scr_pts_data.alt_cat!="_cosm" && 
						` your prescription for ${scr_pts_data.med_db_data__name} from ${scr_pts_data.org_data__name}`}
					{scr_pts_data.alt_cat=="_cosm" && 
						` Cosmetic Treatment with ${scr_pts_data.med_db_data__name} at ${scr_pts_data.org_data__name}`}
					{' '} on {require('moment')(scr_pts_data.add_tm, "D/M/YY HH:mm:ss").format('DD/MM/YYYY')}
				</h4>
				<Header as='h4'>Having an account enables you to request prescriptions and medical certificates anytime 24/7.</Header>
				<Header as='h4'>Please confirm your details and choose password to create an account.</Header>

				<UniForm 
										
						// onActForm = { p => console.log('UniForm returned', p) }
						onActForm = { p => this.pts_signup(p) }

						// onActForm = { d=> onSave_dets( d, _=> this.setState({ mode: 'view' }) ) }

						btn_text = "Save"

						className = "itm_det_edit"

						// valid_not_required = {true}
	
						// render_type = 'list'
						render_children = {true}

						fields = { user_model.PROF_FIELDS_PTS_SGUP
										.map( (f, i) => { return {
															// label: f.label,
															// name: f.name,
															type: "text",
															// // placeholder: view_fields[k],
															...f,
															param_name: f.name,
															req_label: "*",
															value: itm_data&&itm_data[f.name],
															// read_only: !Object.keys(edit_fields).includes(k),
														} }									
											)
								}

						>
						{
							(values, valids, uf_this) => <>
							
								<UniFormSUI

							 		// fields={user_model.PROF_FIELDS_PTS_SGUP}
							 		fields={[
								 				...user_model.PROF_FIELDS_PTS_SGUP
								 			]}

									values={values}
									valids={valids}
									uf_this={uf_this}

									show='fields_form'

									size = {size}
									show_form={true}

									btns={
											<Button 
													size={size} 
													fluid 
													content='All my details are correct. Create an account now.' 
													color="green"  
													onClick={ _=> uf_this.actForm() }
													// onClick={ _=> this.pts_signup() } 
												 />
										}
								 />
								 
							 </>
						}

				</UniForm>


				<div className="row">
					Have an account already?
					<Button as={Link} to={'/login'} size="small" type="button" className="link-button"  basic secondary >
						<Icon name='chevron left' />
						Back to Login
					</Button>
				</div>

				{ (ENABLE_GOOGLE || app.enable_google) && <>
					<Divider>Or</Divider>

					<Button size={size} fluid color='google plus'  onClick={this.g_login.bind(this)} >
						<Icon name='google' /> Login with Google
					</Button>
				</>}
				
				<p className='disclaimer' style={{marginTop: 20}}>
					  <small>By clicking Create an account now, you accept our Privacy Policy. You will be added to our subscriber list, you can unsubscribe at any time.</small>
				  </p>
			</Segment>

	}

	// 		--------------------------------		--------------------------------		---------

	show_user_render () {
		const { user } = this.state
		const { showName=true, showRole=true } = this.props
		
		const password = user && user.user_det && user.user_det.providerData[0].providerId == 'password'
		
		const email = user.user_det&&user.user_det.email||user.prof&&user.prof.email
		const name = user.user_det.displayName||user.prof&&`${user.prof.first_name} ${user.prof.last_name}`||''


		const role = 	app.user.claims && 
							user_model.role_name(app.user.claims, true) 


		return <Dropdown item trigger={<UserAvatar />} icon={false}>
					<Dropdown.Menu direction='left'>
						<Dropdown.Header>
							<AppendIf
								cont={showName && (name || email)}
							 />

							<AppendIf
								cont={app.user.org && app.user.org.name}
								beforeContent={<br />}
							 />

							 <AppendIf
								cont={showRole && role}
								beforeContent={<React.Fragment><br />(</React.Fragment>}
								afterContent=")"
							 />
						</Dropdown.Header>
						<Dropdown.Divider />

						<Dropdown.Item icon='user' as={Link} to='/profile' content="My profile" />
						{password&&<Dropdown.Item icon='lock' as={Link} to='/profile-password' content="Change password" />}
						<Dropdown.Item icon='user close' onClick={ ()=>user_model.logout() } content="Logout" />

					</Dropdown.Menu>
				</Dropdown>
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


	render () {
		const { init_loaded, user, message, show  } = this.state
		const { header  } = this.props

		// const { user_obj, user_det, user_prof } = user

		const loggedIn = user && user.user_in
		return <React.Fragment >
					{/*<MessageBar  login={true} />*/}

				{!header && message && show!='signup' && show!='signup_step2' && 
					<Message
						header={message.header}
						content={message.content}
						negative={!!message.error}
						className="user_error"
						/>
				}
					{!init_loaded && <Dropdown item text='checking user' loading />}

					{ init_loaded && (loggedIn || show=='details') && !app.state.in_sgup_st2 && this.show_user_render()}

					{ init_loaded && !loggedIn && !header && show=='login' && this.login_form_render() }

					{ init_loaded && !loggedIn && !header && show=='signup' && this.signup_form_render() }
					
					{ init_loaded && !header && show=='signup_step_2' && this.signup_form_render() }

					{ init_loaded && !header && show=='r_p' && this.pts_reg_form_render() }

					{ init_loaded && !loggedIn && !header && show=='reset' && this.reset_form_render() }

				{!header && message && (show=='signup' || show=='signup_step2') && 
					<Message
						header={message.header}
						content={message.content}
						negative={!!message.error}
						className="user_error"
						/>
				}
		</React.Fragment>
	}

}

import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import IcosmHeader from './components/IcosmHeader';
import IcosmTiles from './components/icosmTiles';
	
const page = "huddle"
const img_path = "https://storage.googleapis.com/icosm/";
const info = [
	{
		title: <>Become<br/> a member</>,
		img: 'huddle-become.jpg',
		link: '/login/signup',
		link_text: 'Sign Up',
	},
	{
		title: <>Already<br/> a member?</>,
		img: 'huddle-already.jpg',
		link: 'https://thehuddle.worldmanager.com/',
		link_text: 'Go to login',
		target: '_blank'
	}
]
const list = [
		'Weekly featured brands and product', 
		'Induction Training and reference guides',
		'Purchasing stock with just one click',
		'Contact for Customer Service support',
		'Supplier information and Compliance',
		'Notices with reminders and updates',
		'Events calendar with training Invites',
		'Emails to notify, confirm and remind'
]

const Huddle = () => {

	return <div className={page}>

		<Helmet>
			<title>The Huddle</title>
		</Helmet>

        <IcosmHeader title="The Huddle" img={page} link="#"/>

		<div className="bg-icosm-gold-50">
			<Container className="px-4 py-8 sm:p-12 md:w-3/4">
				<div className="grid grid-cols-1 xl:grid-cols-2 lg:gap-8 xl:gap-12">
					<div className="col-span-1 text-left font-bold text-icosm-gold-200 mx-auto mb-8">
						<h3>What is The Huddle?</h3>
						<div id="learnmore">
							<p className="text-icosm_base md:text-icosm_xl font-bold">
								InstantCosmetics is committed to working with
								our nurses to ensure business success, quick
								access to information and smooth prompt
								communication.<br/>
								<br/>
								That is why we created the Huddle. It is designed
								to help you improve your business with great
								customer service support, self-development, and
								training opportunities to help you enhance client
								experience within your clinic.<br/>
								<br/>
								When you sign up to the Huddle, these are the
								features you will have exclusive access to in one
								handy location:
							</p>

							<ul>
								{list.map((el, i) => <li key={i} className="text-icosm_base md:text-icosm_xl font-bold">{el}</li> )}
							</ul>
		
						</div>
					</div>
					<div className="col-span-1 mt-2">
						<IcosmTiles cols={1} tile_data={info}/>
					</div>
				</div>
			</Container>
		</div>
	</div>

}

export default Huddle;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Menu, Grid, Icon } from "semantic-ui-react";

import DeviceOrgSelector from "./DeviceOrgSelector";

const menu = [
	{
	  name: "About Us",
	  link: "/about-instantcosmetics",
	},
	/*!app.user.user_in && {
		name: "Get Started",
		link: "/get-started",
	},
	{
		name: "FAQs",
		link: "/faqs",
	},*/
	{
		name: "The Huddle",
		link: "/the-huddle",
	},
	{
		name: "Contact",
		link: "/contact",
	},
	{
		name: "Privacy Policy",
		link: "#",
	},
	{
		name: "Partners",
		link: "/partners",
	},
	{
	  name: "Our Team",
	  link: "/instantcosmetics-team",
	},
	{
		name: "Customer Terms & Conditions",
		link: "#",
	},
	{
		name: "Platform Terms & Conditions",
		link: "#",
	},
];

const Footer = () => {

  return (
    <div className='py-4 sm:py-14' id="icosm-site-footer">
        <Container>
			<Grid divided={false} inverted stackable>
				<Grid.Row>
					<Grid.Column mobile={12} computer={5} className="footer-lg flex-col p-0">
						<Menu.Item header as={Link} to='/' className="ml-0">
							<img className='logo' src={app.runtime.headerLogo} />
							
							<p className="mt-4">Level 8 / 637 Flinders St.,<br />
								Docklands VIC 3008</p>
							<p>Copyright © {new Date().getFullYear()} {app.runtime.name}
								
							</p>
						</Menu.Item>
					</Grid.Column>

					<Grid.Column mobile={12} computer={7}>
						<div className='menu-items-wrap top text-center md:text-left'>
							<Menu.Menu className='footer-menu menu-items top flex flex-col md:flex-row items-center flex-wrap w-full m-auto'>
								<div className="grid grid-cols-1 md:grid-cols-3 gap-1">
									{menu.map(({ name, link }, i) => {
										switch (name) {
										case 'Privacy Policy':
											return (
											<Link className="item main" key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Privacy', pn:'privacy-policy'})} to={link}>{name}</Link>
											);
										case 'Customer Terms & Conditions':
											return (
											<Link className="item main" key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Customer Terms and Conditions', pn:'customer-terms-and-conditions'})} to={link}>{name}</Link>
											);
										case 'Platform Terms & Conditions':
											return (
											<Link className="item main leading-none" key={name + i} onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Platform Terms and Conditions', pn:'platform-terms-and-conditions'})} to={link}>{name}</Link>
											);
										default:
											return (
											<Link className="item main" key={name + i} to={link}>{name}</Link>
											);
										}})}
								</div>
							</Menu.Menu>
						</div>
					</Grid.Column>

					<Grid.Column mobile={12} computer={3}>
						<p className="text-center md:text-left lg:text-right pt-2">Follow us </p>
						<div className="flex flex-row lg:justify-end md:justify-start justify-center">
							<a href="https://www.facebook.com/InstantCosmetics/" target="_blank">
								<img src="https://storage.googleapis.com/icosm/facebook-white.png" />
							</a>
							<a href="https://www.instagram.com/instantcosmeticsau/" target="_blank">
								<img src="https://storage.googleapis.com/icosm/insta-white.png" />
							</a>
						</div>
					</Grid.Column>

				</Grid.Row>

				{(true || app.acl.is_nurse || app.acl.is_admin || app.acl.is_supp || app.acl.is_doc) && <Grid.Row>
					<Grid.Column className="text-center md:text-left">
						<DeviceOrgSelector />
						{app.runtime.org?.oid!='app'&&<i>{app.runtime.org?.name}<br/></i> || null}
						Build: {__GIT_HASH__} - {__BUILD__}
						{app.dev_env &&  ' - dev' || app.test_env &&  ' - test' || ''}
						&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
						<Icon
							compact="true"
							name="refresh"
							title="Refresh"
							circular
							onClick={_ => location.reload(true)}
							style={{cursor: 'pointer'}}
						/>
					</Grid.Column>
				</Grid.Row>}
			</Grid>
        </Container>
	</div>
  );
};

export default Footer;

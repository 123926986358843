import React from 'react'
import API_service from 'xAppLib/providers/API_service'

import { Link } from 'react-router-dom'

import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Container,
		Segment,
		Header,
		Confirm,
		TextArea,
		Image,
		Tab,
		IconGroup,
	} from 'semantic-ui-react'

import moment from 'moment-timezone'
import currency from 'currency.js'

import { ObjectInspector, chromeLight } from 'react-inspector';

import db_lib from 'xAppLib/libs/db_lib';
import user_model from 'models/user_model'
import org_model from 'models/org_model'

import {ListTable} from 'xAppLib/DataTable'

import EditScript from 'views/admin/scripts/EditScript'
import MedicareClaim from 'views/medicare/MedicareClaim'
import AddResult from 'views/admin/scripts/AddResult'
import UserFlag from 'xAppLib/Users/UserFlag'
import FieldActBtns from 'views/UIelems/fields/ActBtns'
import FieldAttach from 'views/UIelems/fields/Attach'
import FieldEdit from 'views/UIelems/fields/Edit'
import FieldMore from 'views/UIelems/fields/More'
import FieldPayments from 'views/UIelems/fields/Payments'
import FieldRequest from 'views/UIelems/fields/Request'
import FieldReqTm from 'views/UIelems/fields/ReqTm'
import FieldPDFs from 'views/UIelems/fields/PDFs'
import FieldUserFlag from 'views/UIelems/fields/UserFlag'
import FieldAnswerFlags from 'views/UIelems/fields/AnswerFlags';
import ScriptSummary from 'views/scripts/fields/ScriptSummary';
import { DOCID_NODR, PARTN } from "../constants";
import {pick} from "../helpers/json_functions";
import { AppScriptEnrolmentIndicator } from "../views/UIelems/AppScriptEnrolmentIndicator";
import FieldMetaNotes from "../views/UIelems/fields/FieldMetaNotes";
import EvermedOrderDetails from '../xAppLib/Users/EvermedOrderDetails'
import FieldMHCPConfirm from 'views/UIelems/fields/MHCPConfirm'

import Patient from 'views/patients/Patient'

import LoadShowJson from 'xAppLib/UIelems/LoadShowJson'
import LoadShowRTDBJson from 'xAppLib/UIelems/LoadShowRTDBJson'
import DocCons_stats from 'views/doc/DocCons_stats'

import SimpleShowMore from 'xAppLib/UIelems/SimpleShowMore'
import device_model from "./device_model";
import { color_levels, Duration, format_hm } from "../xAppLib/UIelems/Duration";
import { from_melb_ui_tm } from "../helpers/datetime";
import { AnswersList } from "../xAppLib/Users/AnswersList";

import gravatar_url from 'xAppLib/Gravatar/gravatar_url'
import { LoadVidCallLogSummary } from "../views/UIelems/VidCallLogSummary";
import { NetworkTestResults } from "../views/UIelems/OpenTokNetworkTest";
import Alert from "../views/NUI/Alert";
import EvermedActions from '../xAppLib/Users/EvermedActions'
import Age from '../views/patients/Age'
import { maxMissedCallsText, numberOfMissedCalls } from '../views/doc/util'
import Badge from '../views/NUI/Badge'
import { MyHRListBtn } from "views/myhr/GetMyHRPatientRecords"
import StateToTime from '../xAppLib/UIelems/StateToTime';
import instcons_model from "./instcons_model";
import script_model from "./script_model";

import { getClientBookingTimezone } from '../views/booking/shared';


const _FRDB_LOC = 'MedQsForm';

// Shouldn't need this any more, but leaving just for existing treatment plans
const SUBSC_TYPE_DICT = {
	subconsasm: 'Asthma Treat Plan',
	subconshb: 'Heartburn Treat Plan',
	subconschol: 'Cholesterol Treat Plan',
	doccons_fu: 'Treat Plan FU Req'
};

const PATIENT_TIME_INTERVAL = 1000 * 60;

export const TYPE_DICT = (s, r) => s=='doccons' && r=='followup' && "Dr Follow Up"
				|| s=='doccons' && r=='docord' && "Dr FU Req"
				|| s=='doccons' && r=='doccons_fu' && "Pt FU Req"
				|| s=='doccons' && r=='followup_cron' && "Sched Follow Up"
				|| s=='doccons' && r=='followup_res' && "Result Follow Up"
				|| s=='doccons' && r=='docconswl' && "Wght Loss Consult"
				|| s=='doccons' && r=='docconswli' && "Wght Loss Cons (Injection)"
				|| s=='doccons' && r=='docconswlo' && "Wght Loss Cons (Oral)"
				|| s=='pathoreq' && ['docconswl','docconswli','docconswlo'].includes(r) && "Wght Loss Patho Req"
				|| s=='doccons' && r=='docconsref' && "Spec Ref Consult"
				|| s=='doccons' && r=='docconssc' && "Smok Cess Cons"
				|| s=='doccons' && r=='ileg' && "Legal Ref Consult"
				|| s=='doccons' && r=='icann' && "Cannabis Consult"
				|| s=='doccons' && r=='ihair' && "Hair Consult"
				|| s=='doccons' && r=='dc_av' && "Antiviral Consult"
				|| s=='doccons' && r=='dc_rh' && "RoseHeal Consult"
				|| s=='doccons' && r=='dc_uti' && "UTI Consult"
				|| s=='doccons' && r=='dc_skin' && "Skin Consult"
				|| s=='doccons' && r=='dc_acne' && "Acne Consult"
				|| s=='doccons' && r=='dc_pe' && "PE Consult"
				|| s=='doccons' && r=='dc_fertility' && "Fertility Cons"
				|| s=='doccons' && r=='dc_sti' && "STI Consult"
				|| s=='doccons' && r=='mhcp' && "MHCP Consult"
				|| s=='doccons' && r=='mhcprev' && "MHCP Review"
				|| s=='doccons' && r=='dcs_cert' && 'Short Cons Cert'
				|| s=='doccons' && r=='dcs_refr' && 'Short Cons Spec Ref'
				|| s=='doccons' && r=='dcs_patho' && 'Short Cons Patho Ref'
				|| s=='doccons' && r=='dcs_scr' && 'Short Cons Script'
				|| s=='doccons' && "Dr Consult"
				|| s=='subcons' && SUBSC_TYPE_DICT[r]
				|| s=='medsub' && "Subscr Med"
				|| s=='subcons' &&  "Treatment Plan Consult"
				|| s=='docscr' && "Dr Script"
				|| s=='docrefr' && r=='doccons' && "Dr Referral"
				|| s=='docrefr' && "Expr Dr Refr"
				|| s=='doclett' && "Dr Letter"
				|| s=='mentplan' && "MHCP"
				|| s=='fertilityrefr' && "Adora Fertility Refr"
				|| s=='mentrev' && "MHCP Review"
				|| s=='sleeprefr' && "Dr Sleep Refr"
				|| s=='medcert' && r=='doccons' && "Dr MedCert"
				|| s=='medbuy' && "Med Deliv"
				|| s=='medclick' && "Med Click"
				|| s=='exprbuy' && "Med Deliv+Cons"
				|| s=='exprclick' && "Med Click+Cons"
				|| s=='qualbuy' && "Med Deliv+Review"
				|| s=='qualclick' && "Med Click+Review"
				|| s=='medcons' && r=='medcert' && "App MedCert"
				|| s=='medcons' && "App Script"
				|| s=='exprcons'&& r=='medcert'  && "App MedCert+Cons"
				|| s=='exprcons' && "App Script+Cons"
				|| s=='qualcons' && "App Script+Review"
				|| s=='medcert' && r=='pharm' && "Pharm MedCert"
				|| s=='pharm' && r=='medcert' && "Pharm MedCert"
				|| s=='pharm' && "Pharm Script"
				|| s=='instcons' && r=='cosm' && "InstCons Cosm"
				|| s=='cosm' && "Cosm Script"
				|| s=='tpcons' && r == 'cosm' && 'Cosmetic Treatment Plan Consult'
				|| s=='custadm' && "Admin Script"
				|| s=='pathoreq' && ['premed', 'remed'].includes(r) && 'Patho Req fr App'
				|| s=='pathoreq' && r=='doccons' && 'Patho Req fr Doc'
				|| s=='medimgreq' && r=='doccons' && 'MedImg Req fr Doc'
				|| s + '-' + r;


/*const UserFlagPopup = ({ lvl, cont, desc, cre_by, cre_tm }) => (
	<Popup trigger={<UserFlagIcon lvl={lvl} />}>
		<UserFlagContent {...{ lvl, cont, desc, cre_by, cre_tm }} />
	</Popup>
);*/

const UserFlagAlert = ({ lvl, cont, desc, cre_by, cre_tm }) => (
	<Alert  warning={lvl == 'warn'} error={lvl == 'alert'} info={lvl == 'info'} doc={lvl == 'consult'}
			bg="bg-gray-100" className="max-w-md"
		    header={cont} 
		    content={<>
				{desc && <p style={{textWrap: 'wrap'}}>{desc}</p>}
				<p className="text-sm text-is-black-50">by {cre_by.n} on {moment(cre_tm).tz("Australia/Melbourne").format('D/M/YY')}</p>
			</>}
	/>
);

const isBulkBill = (row) => {
	if (!row) return;
	const { script_type, paym, paym__price, partial } = row;
	return script_type === 'doccons' && !partial && !paym?.paymentMethod && !paym?.mdcr_itm && !paym?.mdcr_extras?.length && !paym__price;
};

const OperatorDetailPopup = ({userProf}) =>{

	if(!userProf) return null;

	return <><br/>
						<Popup 
								on="click"
								trigger={ 
									<u style={{cursor:'pointer'}}>Operator's Details</u>}
							>
								
								<Table celled color={'violet'} > 
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell colSpan='2' textAlign='center'>Operator's Details</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
									<Table.Body>
											<Table.Row>
												<Table.Cell>Name: </Table.Cell>
												<Table.Cell>{userProf.first_name + " " + userProf.last_name}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>Mobile: </Table.Cell>
												<Table.Cell><a href={`tel:${userProf.mobile}`}>{userProf.mobile}</a></Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>Email: </Table.Cell>
												<Table.Cell><a href={`mailto:${userProf.email}`}>{userProf.email}</a></Table.Cell>
											</Table.Row>
										</Table.Body>
									</Table>
						</Popup>
					</>
}

const APPOINTMENT_FORMAT = 'DD/MM/YYYY h:mm a';

const SPEC_FIELDS = {
					add_tm_wait_cons:
						{
							name: 'Type',
							type: 'compound',
							parts : [
								{
									name: 'add_tm',
									jpath: 'add_tm',
								},
								{
									name: 'script_type',
									jpath: 'script_type'
								},
								{
									name: 'status',
									jpath: 'status'
								},
								{
									name: 'appointment',
									jpath: 'appointment'
								},
							],
							template: ({ add_tm, script_type, status, appointment }) => {
								const tryDuration =
									status === 'flagged_urgent' || !appointment?.active;

								if (tryDuration &&
									((
										['doccons', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick', 'subcons'].includes(script_type) && ['in_doccall', 'await_doccall', 'noans_doccall', 'wrong_number', 'could_not_call'].includes(status)
									) || status === 'flagged_urgent')
								) {
									return (
										<>
											<Duration live
												compact
												icon
												format={format_hm}
												iconColor={((() => {
													// urgent care, skip green and blue
													if (status === 'flagged_urgent')
														return color_levels([0, 0, 60, 5 * 60])

													if (['exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick'].includes(script_type)) {
														return color_levels([2 * 60, 10 * 60, 20 * 60, 30 * 60])
													}

													return color_levels([30 * 60, 60 * 60, 90 * 60, 120 * 60]);
												})())}
												start={from_melb_ui_tm(add_tm)}
											/>
											<br />
										</>
									);
								}
								if (appointment?.appointment_id) {
									const nowMoment = moment();
									const startMoment = moment(appointment.start_time);
									const contextText =
										startMoment.isAfter(nowMoment)
										? 'time'
										: 'time since';

									const path =
										(app.user.claims.admin||app.user.claims.supp)
											? `/admin/booking/appointments?appointment_id=${appointment.appointment_id}`
											: `/booking/appointments?appointment_id=${appointment.appointment_id}`;

									const clientTimeZone = getClientBookingTimezone();
									return (
										<>
											<Link to={path}>
												Appointment
												<div>
													<span className='pr-2'>{contextText}:</span>
													<Duration live
														compact
														icon
														format={format_hm}
														iconColor={((() => {
															return color_levels([
																60 * 60 * -1,  // in 1 hour
																30 * 60 * -1,  // in 30 minutes
																15 * 60 * 60 * -1, // in 15 minutes
																5 * 60 * 60 * -1 // within 5 minutes or expired
															]);
														})())}
														start={from_melb_ui_tm(startMoment)}
													/>
													<div title='Scheduled start time'>
														<small>S: {startMoment.format(APPOINTMENT_FORMAT)}</small>
														<div><small>({clientTimeZone} time)</small></div>
													</div>
												</div>
											</Link>
											<br />
										</>
									)
								}
								return null;
							},
					},

					mb_post_addr:
						{
							name: 'Address',
							type: 'compound',
							parts : [
								{
									name: 'address',
									jpath: 'form_data.shipping_address_components.address',
									jpath1: 'form_data.shipping_address',
									jpath2: 'form_data.full_address.formatted',
								},
								{
									name: 'address2',
									jpath: 'form_data.shipping_address_components.address2'
								},
								{
									name: 'suburb',
									jpath: 'form_data.shipping_address_components.suburb'
								},
								{
									name: 'state',
									type: 'dict',
									jpath: 'form_data.shipping_address_components.state',
									vals: {
											'Victoria': 'VIC',
											'New South Wales': 'NSW',
											'Tasmania': 'TAS',
											'Queensland': 'QLD',
											'South Australia': 'SA',
											'Australian Capital Territory': 'ACT',
											'Northern Territory': 'NT',
											'Western Australia': 'WA',
										},
								},
								{
									name: 'postcode',
									jpath: 'form_data.shipping_address_components.postcode'
								},
							],
							template : ({address, address2, suburb, state, postcode})=> <React.Fragment>
												{address2 && <>{address2} &nbsp;/ &nbsp;</>}
												{address} <br/>
												{suburb} <br/>
												{state}, {postcode}
											</React.Fragment>
						},

				}

const COMM_FIELDS = {
					time:
						{
							name: 'Time',
							jpath: 'add_tm'
						},

					snum:
						{
							name: 'Snum',
							jpath: 'snum'
						},

					time_type:
						{
							name: 'Time / Type',
							type: 'multiline',
							lines: [
										SPEC_FIELDS.add_tm_wait_cons,
										{jpath: 'add_tm'},
										{type: 'br'},
										{
											name: 'Type',
											type: 'compound',
											parts : [
												{
													name: 'script_type',
													jpath: 'script_type'
												},
												{
													name: 'req_type',
													jpath: 'req_type'
												},
												{
													name: 'paym',
													jpath: 'paym'
												},
												{
													name: 'cado',
													jpath: 'cado'
												},
												{
													name: 'phts',
													jpath: 'phts'
												},
												{
													name: 'escr',
													jpath: 'epresc__escr',
												},
												{
													name: 'partn_name',
													jpath: 'partn.org.name',
													jpath1: 'partn__org__name',
												},
												{
													name: 'usr',
													jpath: 'usr',
												},
												
											],
											template : ({script_type, req_type, paym, cado, phts, escr, partn_name, row})=> <React.Fragment>
																<Icon name={
																			script_type=='pharm' && 'dna' ||
																			script_type=='cosm' && 'syringe' ||
																			['medcons', 'exprcons', 'qualcons'].includes(script_type) && escr && 'qrcode' ||
																			['medcons', 'exprcons', 'qualcons'].includes(script_type) && 'mobile alternate' ||
																			script_type=='doccons' && 'doctor' ||
																			script_type=='subcons' && 'doctor' ||
																			script_type=='medsub' && 'calendar alternate outline' ||
																			script_type=='docscr' && 'file alternate' ||
																			['medbuy', 'exprbuy', 'qualbuy'].includes(script_type) && 'mail square' ||
																			['medclick', 'exprclick', 'qualclick'].includes(script_type) && 'building' ||
																			script_type=='pathoreq' && 'lab' ||
																			script_type=='medimgreq' && 'image'
																		} 
																	/>
																<AppScriptEnrolmentIndicator enrolled={row?.meta?.enrol_meds || row?.meta?.enrol_app_scripts}
																							 message={row?.meta?.enrol_msg} />
																{paym && <Icon name={'dollar sign'} />}
																{cado && <Icon name={'call'} />}
																{phts?.length > 0 && <Icon name={'camera'} />}
																{TYPE_DICT(script_type, req_type)}
																{/*<br />
																{script_type}
																<br />
																{req_type}*/}
																{partn_name && <><br /><Icon name={'handshake'} /> {partn_name}</>}
																{/*{partn_name && usr && <OperatorDetailPopup userProf={usr?.prof} />}*/}
															</React.Fragment>
										},
										{type: 'br'},
										{jpath: 'snum'},
									]
						},

					scr_type:
						{
							name: 'Rec Type',
							type: 'compound',
							parts : [
								{
									name: 'script_type',
									jpath: 'script_type'
								},
								{
									name: 'req_type',
									jpath: 'req_type'
								},
							],
							template : ({script_type, req_type})=> TYPE_DICT(script_type, req_type)
						},

					tm_snum:
						{
							name: 'Time / Snum',
							type: 'multiline',
							lines: [
										{jpath: 'add_tm'},
										{type: 'br'},
										{jpath: 'snum', type:'copy', altpath : 'sid'},
										{type: 'br'},
										{jpath: 'ur', type:'copy'},
										{type: 'br', show_if: { jpath: 'epresc.scid' } },
										{type: 'icon', ico:'qrcode', show_if: { jpath: 'epresc.scid', jpath1: 'epresc__scid',  } },
										{jpath: 'epresc.scid', jpath1: 'epresc__scid', type:'show_more', cut_length:5},
									]
						},

					tm_snum_adm:
						{
							name: 'Time / Snum',
							type: 'multiline',
							lines: [
										SPEC_FIELDS.add_tm_wait_cons,
										{jpath: 'add_tm', title: 'added time'},
										{type: 'bool', jpath: 'is_script'},
										{type: 'br'},
										{jpath: 'snum', type:'copy', altpath : 'sid'},
										{type: 'br'},
										{jpath: 'ur', type:'copy'},
										{type: 'br', show_if: { jpath: 'epresc.scid' } },
										{type: 'icon', ico:'qrcode', show_if: { jpath: 'epresc.scid' } },
										{jpath: 'epresc.scid', type:'show_more', cut_length:5},
									]
						},


					is_pts:
						{
							name: 'Pts?',
							jpath: 'pts_uid',
							type: 'bool',
						},

					is_scr:
						{
							name: 'Script?',
							jpath: 'is_script',
							type: 'bool',
						},

					is_phts:
						{
							name: 'Photos',
							jpath: 'phts',
							type: 'bool',
						},


					type_adm:
						{
							name: 'Type',
							type: 'compound',
							parts : [
								{
									name: 'script_type',
									jpath: 'script_type'
								},
								{
									name: 'req_type',
									jpath: 'req_type'
								},
								{
									name: 'paym',
									jpath: 'paym',
									type: 'bool_data',
								},
								{
									name: 'disc',
									jpath: 'paym.discount',
									type: 'bool_data',
								},
								{
									name: 'cado',
									jpath: 'cado',
									type: 'bool_data',
								},
								{
									name: 'phts',
									jpath: 'phts',
									type: 'bool_data',
								},
								{
									name: 'form_errs',
									jpath: 'form_errs',
									type: 'bool_data',
								},
								{
									name: 'err',
									jpath: 'err',
									type: 'bool_data',
								},
								{
									name: 'err_desc',
									jpath: 'err_desc',
									type: 'bool_data',
								},
								{
									name: 'partn_name',
									jpath: 'partn.org.name',
									jpath2: 'partn__org__name',
								},
								{
									name: 'escr',
									jpath: 'epresc.escript',
								},

								{
									name: 'escr_h',
									jpath: 'form_data.has_escript',
								},
								{
									name: 'escr_c',
									jpath: 'form_data.can_escript',
								},
								{
									name: 'escr_w',
									jpath: 'form_data.delivery',
									type: 'if_val_bool',
									if_val: 'escript',
								},
								{
									name: 'escr_g',
									jpath: 'epresc.scid',
								},
								{
									name: 'alt_cat',
									jpath: 'alt_cat',
								},
								{
									name: 'is_script',
									jpath: 'is_script',
								},

							],
							template : ({script_type, req_type, paym, disc, cado, phts, form_errs, err, err_desc, partn_name, escr, escr_h, escr_c, escr_w, escr_g, alt_cat, is_script, row})=> <React.Fragment>
												<Icon name={
															script_type=='pharm' && req_type!='medcert' && 'dna'
															|| script_type=='cosm' && 'syringe'
															|| ['medcons', 'exprcons', 'qualcons'].includes(script_type) && escr && 'qrcode'
															|| ['medcons', 'exprcons', 'qualcons'].includes(script_type) && 'mobile alternate'
															|| script_type=='doccons' && 'doctor'
															|| script_type=='subcons' && 'calendar alternate outline'
															|| script_type=='medsub' && 'calendar alternate outline'
															|| ['medbuy', 'exprbuy', 'qualbuy'].includes(script_type) && 'mail square'
															|| ['medclick', 'exprclick', 'qualclick'].includes(script_type) && 'building'
															|| script_type=='docscr' && 'clipboard outline'
															|| script_type=='medcert' && 'certificate'
															|| req_type=='medcert' && 'certificate'
															|| script_type=='docrefr' && 'paper plane'
															|| script_type=='doclett' && 'file alternate outline'
															|| script_type=='sleeprefr' && 'bed'
															|| script_type=='custadm' && 'user md'
															|| script_type=='pathoreq' && 'lab'
															|| script_type=='medimgreq' && 'image'
														} 
													/>
												{(form_errs?.length || err || err_desc) && <Icon name={'exclamation triangle'} />}
												{cado && <Icon name={'call square'} />}
												{(['doccons', 'subcons'].includes(script_type)||req_type=='doccons') && <Icon name={'call'} />}
												{phts?.length > 0 && <Icon name={'camera'} />}
												{paym && <Icon name={'dollar sign'} />}
												{disc && <Icon name={'arrow down cart'} />}
												{partn_name && <Icon name={'handshake'} />}
												<AppScriptEnrolmentIndicator enrolled={row?.meta?.enrol_meds || row?.meta?.enrol_app_scripts}
																			 message={row?.meta?.enrol_msg} />
												<br />
												{TYPE_DICT(script_type, req_type)}
												<br />
												{script_type}
												<br />
												{req_type}
												{partn_name && <><br />w/ {partn_name}</>}
												{/*{partn_name && <OperatorDetailPopup userProf={row.usr?.prof} />}*/}
												{!alt_cat 
													&& !['doccons', 'subcons', 'pathoreq'].includes(script_type)
													&& !['doccons', 'medcert'].includes(req_type) 
													&& <><br /><br />
														<Icon name={'database'} color={escr_h?'green':'red'} size='small' fitted /> <Icon 
															name={'id badge outline'} color={escr_c?'green':'grey'} size='small' fitted /> <Icon 
															name={'wikipedia w'} color={escr_w?'green':'grey'} size='small' fitted /> <Icon 
															name={'qrcode'} color={is_script&&escr_h&&escr_c&&escr_w&&!escr_g&&'red' || is_script&&escr_h&&escr_c&&!escr_w&&!escr_g&&'yellow' || escr_g&&'green' || 'grey'} size='small' fitted />
													</>}
											</React.Fragment>
						},



					pts_dets_adm:
						{
							name: 'Patient',
							type: 'compound',
							parts : [
								{
									name: 'First',
									jpath: 'spd_data.first_name'
								},
								{
									name: 'Last',
									jpath: 'spd_data.last_name'
								},
								{
									name: 'DOB',
									jpath: 'spd_data.dob',
									type: 'age',
								},
								{
									name: 'Email',
									jpath: 'spd_data.email',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'Mobile',
									jpath: 'spd_data.mobile',
									// type: 'show_more'
								},
								{
									name: 'Address',
									jpath: 'spd_data.address',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'State',
									jpath: 'spd_data.full_address.state',
								},
								{
									name: 'Medicare',
									jpath: 'spd_data.medicare'
								},
								{
									name: 'Sex',
									jpath: 'spd_data.sex'
								},
								{
									name: 'Conc',
									jpath: 'spd_data.conc_card'
								},
								// {
								// 	name: 'pts_view_link',
								// 	jpath: [{val:'/patient/ui/'}, 'user_iding', {val:'/'}, 'usr.prof.ihi_ihinumber', {val:'/'}, 'pts_uid'],
								// 	type: 'link',
								// 	icon: 'search',
								// },
								{
									name: 'user_iding',
									jpath: 'user_iding',
								},
								{
									name: 'ihi_num',
									jpath: 'epresc.ihi.ihi',
								},
								{
									name: 'pts_uid',
									jpath: 'pts_uid',
								},
								{
									name: 'is_pts',
									jpath: 'pts_uid',
									type: 'bool_data',
								},
								{
									name: 'is_dep_acc',
									jpath: 'usr.prof.parent',
									type: 'bool_data',
								},
								{
									name: 'mc_vf',
									jpath: 'meta.medicare_verified',
								},
								{
									name: 'cn_vf',
									jpath: 'meta.concession_verified',
								},
								{name:'mdcr_first_name',jpath: 'spd_data.mdcr_first_name'},
								{name:'mdcr_last_name',jpath: 'spd_data.mdcr_last_name'},

								{
									name: 'ihi',
									jpath: 'epresc.ihi',
								},

								{ name: 'n__type', jpath: 'n__type',
									type: 'or',	jpath1: 'notes.type' },
								{ name: 'n__lvl', jpath: 'n__lvl',
									type: 'or',	jpath1: 'notes.lvl' },
								{ name: 'n__cont', jpath: 'n__cont',
									type: 'or',	jpath1: 'notes.cont' },
								{ name: 'n__desc', jpath: 'n__desc',
									type: 'or',	jpath1: 'notes.desc' },
								{ name: 'n__cre_by', jpath: 'n__cre_by',
									type: 'or',	jpath1: 'notes.cre_by' },
								{ name: 'n__cre_tm', jpath: 'n__cre_tm',
									type: 'or',	jpath1: 'notes.cre_tm' },
								{
									name: 'atsi',
									jpath: 'spd_data.atsi'
								},
								{
									name: 'em_con_name',
									jpath: 'spd_data.em_con_name'
								},
								{
									name: 'em_con_mobile',
									jpath: 'spd_data.em_con_mobile'
								},
								{
									name: 'em_con_rel',
									jpath: 'spd_data.em_con_rel'
								},
								{
									name: 'myhr',
									jpath: 'myhr'
								},
							],
							template : ({row, First,Last,DOB,Medicare, Conc,Sex, Email, Mobile, Address, State, pts_view_link, is_pts, is_dep_acc, mc_vf, cn_vf, mdcr_first_name,mdcr_last_name, n__type, n__lvl, n__cont, n__desc, n__cre_by, n__cre_tm, ihi, user_iding, ihi_num, pts_uid, atsi, em_con_name, em_con_mobile, em_con_rel, hide_desc,myhr,onReloadRow})=> <React.Fragment>
												{n__type && n__lvl && <UserFlagAlert lvl={n__lvl} cont={n__cont} desc={n__desc} cre_by={n__cre_by} cre_tm={n__cre_tm} />}
												{pts_view_link}
												{app.user.claims.root &&
													<Link to = {`/patient/ui/?d=${user_iding}&i=${ihi_num}&u=${pts_uid}`}>
														,
													</Link>
												}
                                                {!hide_desc && <>
												<Link to = {`/patient/dp/${btoa(`d=${user_iding}&i=${ihi_num}&u=${pts_uid}`)}`}>
													<Icon name = {'search'} />
												</Link>
												&nbsp;
                                                </>}
												<Icon
													disabled={!is_pts}
													name={is_pts?'user':'user times'}
													color={is_pts?'green':'grey'}
												 /> 
												{ is_dep_acc && 
													<Icon
													// disabled={!is_pts}
													name='child'
													color='pink'
													size='small'
												 /> }
												&nbsp;
												{First} {Last} 
												&nbsp;
												<br/>
												{mdcr_first_name && <React.Fragment>({mdcr_first_name} {mdcr_last_name}) <Popup content='Real name confirmed by Medicare' trigger={ <Icon name="question circle outline"/>} /><br/></React.Fragment>}
												<span className="flex flex-row">
													<span><Image size='mini' avatar src={row.usr?.photoURL || gravatar_url({ email: row.spd_data?.email, name: First+' '+Last, }) || null} /></span>
													<span>
														{Sex && <Icon name={Sex=='F' && 'woman' || Sex=='M' && 'man' || 'question'} color={Sex=='F' && 'pink' || Sex=='M' && 'blue' || 'green'} />}{DOB}
														<br/>{Mobile}
													</span>
												</span>
												{Email}
												{Address && (app.acl.is_admin || app.acl.is_supp) && <React.Fragment><br/>{Address}</React.Fragment>}
												{State && app.user?.prof?.full_address?.state !== State && <><br/><strong>Local time: <StateToTime state={State} interval={PATIENT_TIME_INTERVAL} /></strong></>}
												{(em_con_name || em_con_mobile || em_con_rel) && <i><br /><SimpleShowMore cut_length={20} content={`Emergency: ${em_con_name}, ${em_con_mobile}, ${em_con_rel}`} /></i>}
												{atsi && <><br />ATSI: {user_model.get_atsi_label(atsi)}</>}
												{!hide_desc && <><br />
												<Popup
													content={
														<Patient
																embedded
																by_par = 'ui'
																by_id = {user_iding}
																by_uid = {pts_uid}
																by_ihi = {ihi_num}

																hide_act_btns = {true}
															/>
													}
													on='click'
													positionFixed
													trigger={<Button icon='user' size='tiny' label='Patient Details' />}
													style={{maxHeight:'60vh',overflow:'scroll',width:'40vw'}}
												/></>}
												{Medicare && <React.Fragment><br/>Medicare: {Medicare} &nbsp;
													<Icon 
														name={mc_vf=='ok'&&'check'||mc_vf=='fail'&&'minus'||'question'} 
														color={mc_vf=='ok'&&'green'||mc_vf=='fail'&&'red'||'yellow'} 
													/></React.Fragment>}
												{Conc && <React.Fragment><br/>Conc: {Conc} &nbsp;
													<Icon 
														name={cn_vf=='ok'&&'check'||cn_vf=='fail'&&'minus'||'question'} 
														color={cn_vf=='ok'&&'green'||cn_vf=='fail'&&'red'||'yellow'} 
													/>
													</React.Fragment>}

												{ihi && <><br/><b>IHI:</b> {ihi.ihi||ihi.status=='Timeout'&&'T/O'||ihi.err&&`err: ${JSON.stringify(ihi.err)}`||''} <Icon 
															name={ihi.status=='ok'&&'check'||'minus'} 
															color={ihi.status=='ok'&&'green'||'red'||'yellow'} />
														</>}
												<br/><MyHRListBtn pts_uid={pts_uid} myhr={myhr} row={row} onRefresh={onReloadRow} />
												
											</React.Fragment>
						},

					pts_dets_wlink:
						{
							name: 'Patient',
							type: 'compound',
							parts : [
								{
									name: 'First',
									jpath: 'spd_data.first_name'
								},
								{
									name: 'Last',
									jpath: 'spd_data.last_name'
								},
								{
									name: 'DOB',
									jpath: 'spd_data.dob',
									type: 'age',
								},
								{
									name: 'Email',
									jpath: 'spd_data.email',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'Sex',
									jpath: 'spd_data.sex',
								},
								{
									name: 'Mobile',
									jpath: 'spd_data.mobile',
								},
								{
									name: 'Medicare',
									jpath: 'spd_data.medicare'
								},
								{
									name: 'Conc',
									jpath: 'spd_data.conc_card'
								},
								{
									name: 'is_dep_acc',
									jpath: 'usr.prof.parent',
									type: 'bool_data',
								},
								{
									name: 'user_iding',
									jpath: 'user_iding',
								},
								{
									name: 'ihi_num',
									jpath: 'ihi__num',
								},
								{
									name: 'pts_uid',
									jpath: 'pts_uid',
								},

								{
									name: 'mc_vf',
									jpath: 'meta__medicare_verified',
									jpath1: 'meta.medicare_verified',
								},
								{
									name: 'cn_vf',
									jpath: 'meta__concession_verified',
									jpath1: 'meta.concession_verified',
								},
								{name:'mdcr_first_name',jpath: 'spd_data.mdcr_first_name'},
								{name:'mdcr_last_name',jpath: 'spd_data.mdcr_last_name'},

								{ name: 'n__type', jpath: 'n__type',
									type: 'or',	jpath1: 'notes.type' },
								{ name: 'n__lvl', jpath: 'n__lvl',
									type: 'or',	jpath1: 'notes.lvl' },
								{ name: 'n__cont', jpath: 'n__cont',
									type: 'or',	jpath1: 'notes.cont' },
								{ name: 'n__desc', jpath: 'n__desc',
									type: 'or',	jpath1: 'notes.desc' },
								{ name: 'n__cre_by', jpath: 'n__cre_by',
									type: 'or',	jpath1: 'notes.cre_by' },
								{ name: 'n__cre_tm', jpath: 'n__cre_tm',
									type: 'or',	jpath1: 'notes.cre_tm' },
								{
									name: 'atsi',
									jpath: 'spd_data.atsi'
								},
								{
									name: 'em_con_name',
									jpath: 'spd_data.em_con_name'
								},
								{
									name: 'em_con_mobile',
									jpath: 'spd_data.em_con_mobile'
								},
								{
									name: 'em_con_rel',
									jpath: 'spd_data.em_con_rel'
								},
							],
							template : ({First,Last,DOB,Email,Sex,Mobile,Medicare, Conc, pts_view_link, mc_vf, is_dep_acc,cn_vf,mdcr_first_name,mdcr_last_name, n__type, n__lvl, n__cont, n__desc, n__cre_by, n__cre_tm, user_iding, ihi_num, pts_uid, atsi, em_con_name, em_con_mobile, em_con_rel})=> <React.Fragment>
												{n__type && n__lvl && <UserFlagAlert lvl={n__lvl} cont={n__cont} desc={n__desc} cre_by={n__cre_by} cre_tm={n__cre_tm} />}
												{pts_view_link}
												<Link to = {`/patient/dp/${btoa(`d=${user_iding}&i=${ihi_num}&u=${pts_uid}`)}`}>
													<Icon name = {'search'} />
												</Link>
												&nbsp;
												{ is_dep_acc &&
													<Icon
													// disabled={!is_pts}
													name='child'
													color='pink'
													size='small'
												 /> }
												{First} {Last}
												&nbsp;
												<br/>
												{mdcr_first_name && <React.Fragment>({mdcr_first_name} {mdcr_last_name}) <Popup content='Real name confirmed by Medicare' trigger={ <Icon name="question circle outline"/>} /><br/></React.Fragment>}
												{Email} <br/>
												{Sex && <Icon name={Sex=='F' && 'woman' || Sex=='M' && 'man' || 'question'} color={Sex=='F' && 'pink' || Sex=='M' && 'blue' || 'green'} />}
												{DOB} <br/>
												{Mobile && <a href={'tel:'+Mobile}>{Mobile}</a>}
												{(em_con_name || em_con_mobile || em_con_rel) && <i><br /><SimpleShowMore cut_length={20} content={`Emergency: ${em_con_name}, ${em_con_mobile}, ${em_con_rel}`} /></i>}
												{atsi && <><br />ATSI: {user_model.get_atsi_label(atsi)}</>}
												<br />
												<Popup
													content={
														<Patient
																embedded
																by_par = 'ui'
																by_id = {user_iding}
																by_uid = {pts_uid}
																by_ihi = {ihi_num}

																hide_act_btns = {true}
															/>
													}
													on='click'
													positionFixed
													trigger={<Button icon='user' size='tiny' label='Patient Details' />}
													style={{maxHeight:'60vh',overflow:'scroll',width:'40vw'}}
												/>
												{Medicare && <React.Fragment><br/>Medicare: {Medicare} &nbsp;
													<Icon 
														name={mc_vf=='ok'&&'check'||mc_vf=='fail'&&'minus'||'question'} 
														color={mc_vf=='ok'&&'green'||mc_vf=='fail'&&'red'||'yellow'} 
													/></React.Fragment>}
												{Conc && <React.Fragment><br/>Conc: {Conc} &nbsp;
													<Icon 
														name={cn_vf=='ok'&&'check'||cn_vf=='fail'&&'minus'||'question'} 
														color={cn_vf=='ok'&&'green'||cn_vf=='fail'&&'red'||'yellow'} 
													/>
													</React.Fragment>}
												{ihi_num && <><br/>IHI: {app.user.claims.doc && ihi_num || <Icon name={'check'} color={'green'} />} </>}
											</React.Fragment>
						},

					pts_dets:
						{
							name: 'Patient',
							type: 'compound',
							parts : [
								{
									name: 'First',
									jpath: 'spd_data.first_name'
								},
								{
									name: 'Last',
									jpath: 'spd_data.last_name'
								},
								{
									name: 'DOB',
									jpath: 'spd_data.dob'
								},
								{
									name: 'Sex',
									jpath: 'spd_data.sex',
								},
								{
									name: 'Medicare',
									jpath: 'spd_data.medicare'
								},
								{
									name: 'Conc',
									jpath: 'spd_data.conc_card'
								},
							],
							template : ({First,Last,DOB,Sex,Medicare, Conc})=> <React.Fragment>
												{First} {Last} <br/>
												DOB: <Age dob={DOB} />
												Sex: {Sex}
												{Medicare && <React.Fragment><br/>Medicare: {Medicare}</React.Fragment>}
												{Conc && <React.Fragment><br/>Conc: {Conc}</React.Fragment>}
											</React.Fragment>
						},

					pts_dets_mb:
						{
							name: 'Patient',
							type: 'compound',
							parts : [
								{
									name: 'First',
									jpath: 'spd_data.first_name'
								},
								{
									name: 'Last',
									jpath: 'spd_data.last_name'
								},
								{
									name: 'DOB',
									jpath: 'spd_data.dob'
								},
								{
									name: 'Sex',
									jpath: 'spd_data.sex',
								},
								{
									name: 'Medicare',
									jpath: 'spd_data.medicare'
								},
								{
									name: 'Conc',
									jpath: 'spd_data.conc_card'
								},
								{
									name: 'Mobile',
									jpath: 'spd_data.mobile'
								},
								{
									name: 'Email',
									jpath: 'spd_data.email'
								},
								{
									name: 'pts_view_link',
									jpath: [{val:'/patient/ui/'}, 'user_iding'],
									type: 'link',
									icon: 'search',
								},

								{
									name: 'user_iding',
									jpath: 'user_iding',
								},
								{
									name: 'ihi_num',
									jpath: 'epresc.ihi.ihi',
									jpath1: 'ihi__num',
								},
								{
									name: 'pts_uid',
									jpath: 'pts_uid',
								},
							],
							template : ({First,Last,DOB,Sex,Medicare, Conc,Mobile,Email, pts_view_link, user_iding, ihi_num, pts_uid})=> <React.Fragment>
												{First} {Last} &nbsp;
												<Link to = {`/patient/dp/${btoa(`d=${user_iding}&i=${ihi_num}&u=${pts_uid}`)}`}>
													<Icon name = {'search'} />
												</Link>
												<br/>
												DOB: <Age dob={DOB} /> <br/>
												Sex: {Sex} <br/>
												{Medicare && <React.Fragment>Medicare: {Medicare}<br/></React.Fragment>}
												{Conc && <React.Fragment>Conc: {Conc}<br/></React.Fragment>}
												{Mobile}<br/>
												{Email}
												<br />
												<Popup
													content={
														<Patient
																embedded
																by_par = 'ui'
																by_id = {user_iding}
																by_uid = {pts_uid}
																by_ihi = {ihi_num}

																hide_act_btns = {true}
															/>
													}
													on='click'
													positionFixed
													trigger={<Button icon='user' size='tiny' label='Patient Details' />}
													style={{maxHeight:'60vh',overflow:'scroll',width:'40vw'}}
												/>
											</React.Fragment>
						},

					pts_dets_post:
						{
							name: 'Patient',
							type: 'compound',
							parts : [
								{
									name: 'FullName',
									type: 'concat',
									parts: [
												{jpath: 'spd_data.first_name'},
												{type: 'val', val: ' '},
												{jpath: 'spd_data.last_name'},
											]
								},
								{
									name: 'Mobile',
									jpath: 'spd_data.mobile'
								},
								{
									name: 'Email',
									jpath: 'spd_data.email'
								},
							],
							template : ({FullName, First,Last,Mobile,Email})=> <React.Fragment>
												{FullName} <br/>
												{Email} <br/>
												{Mobile}
											</React.Fragment>
						},

						appointment:
						{
							name: 'Appointment',
							type: 'compound',
							parts: [
								{
									name: 'appointment_id',
									jpath: 'appointment.appointment_id'
								},
								{
									name: 'booking_type',
									jpath: 'appointment.booking_type'
								},
								{
									name: 'booked_uid',
									jpath: 'appointment.booked_uid'
								},
								{
									name: 'start_time',
									jpath: 'appointment.start_time'
								},
								{
									name: 'end_time',
									jpath: 'appointment.end_time'
								},
								{
									name: 'active',
									jpath: 'appointment.active'
								},
							],
							template: ({
								appointment_id, booking_type, start_time, end_time, active, booked_uid
							}) => {
								if (!appointment_id || !active) {
									return null;
								}

								const path =
									(app.user.claims.admin||app.user.claims.supp)
									? `/admin/booking/appointments?appointment_id=${appointment_id}`
									: `/booking/appointments?appointment_id=${appointment_id}`;

								const clientTimeZone = getClientBookingTimezone();
								return (
									<div>
										<Link to={path}>
											<div className="flex flex-start flex-wrap text-center">
												<Icon name="clock" />
												{booking_type}
											</div>
											<div><code>Start {moment(start_time).format(APPOINTMENT_FORMAT)}</code></div>
											<div><code>&nbsp;&nbsp;End {moment(end_time).format(APPOINTMENT_FORMAT)}</code></div>
											<div><small><code>({clientTimeZone} time)</code></small></div>
											{booked_uid
												? (booked_uid === app?.user?.uid
													? <strong>Booked to you</strong>
													: 'Booked'
												)
												: 'Not booked to staff memeber'}
										</Link>
									</div>
								)
							}
						},


					med_treat:
						{
							name: 'Medication / Treatm',
							type: 'compound',
							parts: [
								{
									name: 'Medicine',
									jpath: 'med_db_data__name',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'Size',
									jpath: 'med_db_data__size',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'med_db_data',
									jpath: 'med_db_data',
								},
								{
									name: 'Quantity',
									jpath: 'cosm_det.qua',
									type: 'show_more'
								},
								{
									name: 'Location',
									jpath: 'cosm_det.loc',
									type: 'show_more'
								},
								{
									name: 'Notes',
									jpath: 'cosm_det.note',
									type: 'show_more'
								},

								{
									name: 'cons_desc',
									jpath: 'form_data.cons_desc',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'dcs_cert_dates',
									jpath: 'form_data.dcs_cert_dates',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'dcs_med_desc',
									jpath: 'form_data.dcs_med_desc',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'dcs_refr_spec',
									jpath: 'form_data.dcs_refr_spec',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'dcs_refr_dets',
									jpath: 'form_data.dcs_refr_dets',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'dcs_patho_dets',
									jpath: 'form_data.dcs_patho_dets',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'cons_medhist',
									jpath: 'form_data.cons_medhist',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'cons_ref_spec',
									jpath: 'form_data.cons_ref_spec',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'cons_allergy',
									jpath: 'form_data.cons_allergy',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'cons_meds',
									jpath: 'form_data.cons_meds',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'cons_phone',
									jpath: 'form_data.cons_phone',
									// type: 'show_more'
								},
								{
									name: 'send_to',
									jpath: 'med_db_data.send_to',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'med_items',
									jpath: 'med_db_data.items',
									// type: 'show_more',
									// cut_length: 20,
								},
								{
									name: 'selected_items',
									jpath: 'form_data.selected_items',
									// type: 'show_more',
									// cut_length: 20,
								},
								{
									name: 'med_req_txt',
									jpath: 'med_db_data.req_txt',
									// type: 'show_more',
									// cut_length: 20,
								},
								{
									name: 'call_time',
									jpath: 'form_data.call_time'
								},
								{
									name: 'dontcall_time',
									jpath: 'form_data.dontcall_time'
								},
								{
									name: 'hide_med',
									jpath: 'hide_med'
								},
								{
									name: 'hide_desc',
									jpath: 'hide_desc'
								},
							],
							template : ({Medicine,hide_med,Size,Quantity,Location,Notes, cons_desc, dcs_cert_dates, dcs_med_desc, dcs_refr_spec, dcs_refr_dets, dcs_patho_dets, cons_medhist, cons_ref_spec, cons_phone,cons_allergy, cons_meds, call_time, dontcall_time, send_to, med_db_data, med_items, selected_items, med_req_txt,row, hide_desc,onReloadRow,onRefreshList})=> hide_med ? <></> : <React.Fragment>
											<ScriptSummary row={row} modal trigger={<Icon name='qrcode'/>}/> <b>{Medicine}</b> {Size}<br/>
											{Quantity && <React.Fragment>Quantity: {Quantity}<br/></React.Fragment>}
											{Location && <React.Fragment>Location: {Location}<br/></React.Fragment>}
											{Notes && <React.Fragment>Notes: {Notes}</React.Fragment>}
											{cons_phone && <>Phone: {cons_phone}<br/></> ||''}
											{call_time && <>Preferred Call Time: {call_time}<br/></> ||''}
											{dontcall_time?.length > 0 && <>Don't call at: {dontcall_time.join(', ')}<br/></> ||''}
											{cons_desc && !hide_desc && <>Desc: <b>{cons_desc}</b><br/></> ||''}
											{dcs_cert_dates && !hide_desc && <>Dates: <b>{dcs_cert_dates}</b><br/></> ||''}
											{dcs_med_desc && !hide_desc && <>Med: <b>{dcs_med_desc}</b><br/></> ||''}
											{dcs_refr_spec && !hide_desc && <>Specialist: <b>{dcs_refr_spec}</b><br/></> ||''}
											{dcs_refr_dets && !hide_desc && <>Details: <b>{dcs_refr_dets}</b><br/></> ||''}
											{dcs_patho_dets && !hide_desc && <>Pathology: <b>{dcs_patho_dets}</b><br/></> ||''}
											{cons_medhist && <>History: <b>{cons_medhist}</b><br/></> ||''}
											{cons_ref_spec && <>Ref to: {cons_ref_spec}<br/></> ||''}
											{cons_allergy && <>Allergy: {cons_allergy}<br/></> ||''}
											{cons_meds && <>Current Meds: {cons_meds}<br/></> ||''}
											{send_to && <>send to: {send_to}<br/></> ||''}
											{med_db_data && <ul>{[
																	'alt_name', 
																	'qnty', 
																	'dose', 
																	'repeats', 
																	'days_limit', 
																	'authority_code', 
																	'phone_appr', 
																	'extra', 
																	'mc_pr', 
																	'mc_mb_pr', 
																	'mc_ph_pr', 
																].map( (f, i) => med_db_data[f] && <li key={i}><i>{f}</i> - <SimpleShowMore cut_length={10} content={med_db_data[f] || ''} /></li>)}</ul> ||''}
											{med_items && <>{med_items.map( (it, i) => (!selected_items || selected_items[it.key]) && <li key={i}><i><SimpleShowMore cut_length={25} content={it.name || ''} /></i> </li>)}</> ||''}
											{med_req_txt && <b><br/><li><SimpleShowMore cut_length={25} content={med_req_txt} /></li></b>||''}
											{
												['mhcp','mhcprev'].includes(row.req_type) && row.med_db_data.xtra?.copay && <FieldMHCPConfirm script={row} onReloadRow={onReloadRow} onRefreshList={onRefreshList} />

											}
										</React.Fragment>
						},

					med_min:
						{
							name: 'Medication',
							type: 'multiline',
							lines: [
									{
										name: 'Medicine',
										jpath: 'med_db_data__name',
										// type: 'show_more',
										// cut_length: 20,
									},
									{
										name: 'Size',
										jpath: 'med_db_data__size',
										// type: 'show_more',
										// cut_length: 15,
									},
								]
						},

					med_dets:
						{
							name: 'Medication',
							type: 'compound',
							parts: [
								{
									name: 'Medicine',
									type: 'show_more',
									jpath: 'med_db_data__name',
									cut_length: 25,
								},
								{
									name: 'Size',
									type: 'show_more',
									jpath: 'med_db_data__size'
								},
								{
									name: 'qnty',
									type: 'show_more',
									jpath: 'med_db_data__qnty'
								},
								{
									name: 'dose',
									type: 'show_more',
									jpath: 'med_db_data__dose',
									cut_length: 15,
								},
							],
							template : ({Medicine,Size,qnty, dose})=> <React.Fragment>
											<b>{Medicine}</b> {Size} 
											{qnty && <><br/>Qty: {qnty}</>}
											{dose && <><br/>Dose: {dose}</>}
										</React.Fragment>
						},


					med_dets_mb:
						{
							name: 'Medication',
							type: 'compound',
							parts: [
								{
									name: 'Medicine',
									type: 'show_more',
									jpath: 'med_db_data__name',
									cut_length: 25,
								},
								{
									name: 'Size',
									type: 'show_more',
									jpath: 'med_db_data__size'
								},
								{
									name: 'qnty',
									type: 'show_more',
									jpath: 'med_db_data__qnty'
								},
								{
									name: 'dose',
									type: 'show_more',
									jpath: 'med_db_data__dose',
									cut_length: 15,
								},
								{
									name: 'gen',
									// type: 'show_more',
									jpath: 'form_data.want_gen',
									// cut_length: 15,
								},
							],
							template : ({Medicine,Size,qnty, dose, gen})=> <React.Fragment>
											<b>{Medicine}</b> {Size} 
											{qnty && <><br/>Qty: {qnty}</>}
											{dose && <><br/>Dose: {dose}</>}
											<br/><b>{gen==true ? 'Generic' : 'Branded'}</b>
										</React.Fragment>
						},

					treat:
						{
							name: 'Description',
							type: 'compound',
							parts: [
								{
									name: 'Quantity',
									jpath: 'cosm_det.qua',
									type: 'show_more'
								},
								{
									name: 'Location',
									jpath: 'cosm_det.loc',
									type: 'show_more'
								},
								{
									name: 'Notes',
									jpath: 'cosm_det.note',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'cons_desc',
									jpath: 'form_data.cons_desc',
									jpath1: 'form_data__cons_desc',
									type: 'show_more',
									cut_length: 30,
								},
								{
									name: 'cons_medhist',
									jpath: 'form_data.cons_medhist',
									jpath1: 'form_data__cons_medhist',
									type: 'show_more',
									cut_length: 30,
								},
								{
									name: 'cons_ref_spec',
									jpath: 'form_data.cons_ref_spec',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'cons_allergy',
									jpath: 'form_data.cons_allergy',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'cons_meds',
									jpath: 'form_data.cons_meds',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'cons_phone',
									jpath: 'form_data.cons_phone',
									// type: 'show_more'
								},
							],
							template : ({cons_desc,cons_medhist,cons_ref_spec, cons_phone,cons_allergy,cons_meds, Quantity,Location,Notes})=> <React.Fragment>
											{cons_phone && <>{cons_phone}<br/></> ||''}
											{cons_desc && <b>{cons_desc}<br/></b> ||''}
											{cons_medhist && <>History: {cons_medhist}<br/></> ||''}
											{cons_ref_spec && <>Ref to: {cons_ref_spec}<br/></> ||''}
											{cons_allergy && <>Allergy: {cons_allergy}<br/></> ||''}
											{cons_meds && <>Current Meds: {cons_meds}<br/></> ||''}
											{Quantity && <React.Fragment>Quantity: {Quantity}<br/></React.Fragment>}
											{Location && <React.Fragment>Location: {Location}<br/></React.Fragment>}
											{Notes && <React.Fragment>Notes: {Notes}</React.Fragment>}
										</React.Fragment>
						},



					org:
						{
							name: 'Org',
							type: 'compound',
							parts: [
								{
									name: 'Org',
									jpath: 'org__name',
									jpath1: 'org_data.name',
									jpath2: 'org_db_data.name',
								},
							],
							template : ({Org,By,User})=> <React.Fragment>
											<b>{Org || '-'}</b><br/>
											{(By || User) && <React.Fragment>{By}<br/>{User}</React.Fragment>}
										</React.Fragment>
						},


					by_from:
						{
							name: 'Pharmacy / Clinic',
							type: 'compound',
							parts: [
								{
									name: 'Org',
									jpath: 'org__name',
									jpath1: 'org_data.name',
									jpath2: 'org_db_data.name',
									type: 'show_more',
									cut_length: 35,
								},
								{
									name: 'By',
									jpath: 'usr__displayName'
								},
								{
									name: 'User',
									jpath: 'usr__email',
									type: 'show_more',
									cut_length: 30,
								},

								{
									name: 'org_data__type',
									jpath: 'org_data.type',
									jpath1: 'org_data__type',
									jpath2: 'org_db_data.type',
									type: 'or',
								},
								{
									name: 'org__address',
									jpath: 'org_data.address',
									jpath1: 'org__address',
									jpath2: 'org_db_data.address',
									type: 'show_more',
									cut_length: 35,
								},
								{
									name: 'org__email',
									jpath: 'org_data.email',
									jpath1: 'org__email',
									jpath2: 'org_db_data.email',
									type: 'or',
								},
								{
									name: 'org__contact',
									jpath: 'org_data.contact',
									jpath1: 'org__contact',
									jpath2: 'org_db_data.contact',
									type: 'or',
								},
								{
									name: 'org__phone',
									jpath: 'org_data.phone',
									jpath1: 'org__phone',
									jpath2: 'org_db_data.phone',
									type: 'or',
								},

								{
									name: 'script_type',
									jpath: 'script_type'
								},
								{
									name: 'mb_post_addr',
									type: 'multiline',
									lines: [
										{type: 'br'},
										{type: 'br'},
										{type: 'label', label: 'Shipping Address:', },
										{type: 'br'},
										SPEC_FIELDS.mb_post_addr,
										{type: 'br'},
									]
								},
								{
									name: 'org__oid',
									jpath: 'org_data.oid',
									jpath2: 'org__oid',
									type: 'or',
								},
								{
									name: 'evermed_order_status',
									jpath: 'meta.evermed.orderStatus',
									type: 'or'
								},
								{
									name: 'evermed_delivery_status',
									jpath: 'meta.evermed.deliveryStatus',
									type: 'or'
								},
								{
									name: 'evermed_order_number',
									jpath: 'meta.evermed.order.orderNumber',
									type: 'or'
								},
								{
									name: 'ePrescription',
									jpath: 'epresc.epresc_data.ePrescription',
								},
								{
									name: 'col_pharm',
									jpath: 'form_data.col_pharm',
								},
								{
									name: 'is__escr',
									jpath: 'epresc.scid',
									jpath2: 'is__escr',
									type: 'or',
								},

							],
							template : ({Org,By,User, row, org_data__type, org_data, org__address, org__email, org__contact, org__phone, script_type, mb_post_addr, org__oid, evermed_order_status, evermed_delivery_status, evermed_order_number,ePrescription, col_pharm, is__escr}) => (script_type !== 'docscr' || !is__escr) && <>
											<b>{Org || '-'}</b><br/>
											{org_data__type=='cosm' && (By || User) && <>{By}<br/>{User}<br/>{org__phone}</>}
											{org_data__type=='pharm' && <>{org__address}<br/>{org__email}<br/>{org__contact}<br/>{org__phone}</>}
											{['medbuy', 'qualbuy', 'exprbuy', 'medsub'].includes(script_type) && mb_post_addr}
											{['medclick', 'qualclick', 'exprclick'].includes(script_type) && (
												<>
													<br/>
													<br/>
													<span><strong>Collecting From:</strong> {col_pharm?.name}</span>
												</>
											)}
											{['medbuy', 'qualbuy', 'exprbuy', 'medclick', 'qualclick', 'exprclick', 'medsub'].includes(script_type) && org__oid==PARTN.EVERMED &&
											<>
											<br/>
											<br/>
											<div className='flex space-x-4'>
												<div>
													<strong>Order Status:</strong> {evermed_order_status}<br/>
													<strong>Delivery Status:</strong> {evermed_delivery_status}
												</div>
												<div><EvermedOrderDetails orderNumber={evermed_order_number}/></div>
											</div>
											</>}


											 {(ePrescription?.Items?.[0]?.Item.Content.Pharmacy) &&
												((pharmacy,dispenseditem)=><p className='mt-5'>
															<strong>Dispensing Pharmacy</strong><br/>
															{pharmacy.PharmacyName}<br/>
															{pharmacy.PharmacyAddress1}<br/>
															{pharmacy.PharmacySuburb} {pharmacy.PharmacyState} {pharmacy.PharmacyPostcode}<br/>
															Pharmacist: {pharmacy.PharmacistFirstName} {pharmacy.PharmacistFamilyName}<br/>
															Phone: {pharmacy.PharmacyPhoneNumber}<br/>
															Date: {dispenseditem?.DispenseDate}<br/>
														</p>
												)(ePrescription?.Items[0].Item.Content.Pharmacy,ePrescription?.Items[0].Item.Content.DispensedItem)}
									</>
						},


					doc_skype:
						{
							name: 'Doctor',
							type: 'compound',
							parts: [
								{
									name: 'Doctor',
									jpath: 'doc_db_data__name'
								},
								{
									name: 'Skype',
									jpath: 'cosm_det__doc',
									type: 'notnull_bool_ne',
									ne_val: 'No',
								},
								{
									name: 'DocCons_History',
									jpath: 'hist',
									type: 'inline_hist_doccons',
								},
								{
									name: 'DocCons_calls',
									jpath: 'meta',
									type: 'inline_doccons_calls',
								},
								{
									name: 'script_type',
									jpath: 'script_type'
								},
								{
									name: 'status',
									jpath: 'status'
								},
							],
							template : ({row, Doctor, Skype, DocCons_History, DocCons_calls, script_type, status})=> <React.Fragment>
											{Doctor && <React.Fragment>{Doctor}<br/></React.Fragment>}
											{Skype && <React.Fragment>Skype: {Skype}<br/></React.Fragment>}
											{['subcons', 'doccons'].includes(script_type) && status!="done_doccall" && DocCons_History}
											{['exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick'].includes(script_type) && !['doc_approved', 'expr_declined', 'qual_declined'].includes(status) && DocCons_History}
											{(
												['subcons', 'doccons', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick'].includes(script_type)
												|| row.meta?.calls
											) && DocCons_calls}
											<br />
											{(
												['doccons', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick', 'subcons'].includes(script_type)
												|| row.meta?.cons_stats
											) && row?.meta?.cons_stats &&
													<DocCons_stats stats={row?.meta?.cons_stats} />
											}
										</React.Fragment>
						},

					doc:
						{
							name: 'Doctor',
							jpath: 'doc_db_data__name'
						},

					call_dets:
						{
							name: 'Consult',
							type: 'multiline',
							lines: [
										{type: 'val', val: 'Call:', },
										{jpath: 'cosm_det.doc', type: 'notnull_bool_ne', ne_val: 'No',},
										{type: 'br'},
										{type: 'val', val: 'Wait:', show_if: { jpath: 'wait_time' }, },
										{type: 'br', show_if: { jpath: 'wait_time' },},
										{jpath: 'wait_time'},
									],
						},

					scr_note:
						{
							name: 'Notes',
							jpath: 'meta.note',
							jpath1: 'meta__note',
							template: FieldMetaNotes,
							ico: 'file alternate outline',
							compact: true,
						},

					history:
						{
							name: 'History',
							jpath: 'hist',
							type: 'popup_hist',
							ico: 'history',
							compact: true,
						},

					adm_paym_sum:
						{
							name: 'Payment',
							type: 'compound',
							parts : [
								{
									name: 'paym',
									jpath: 'paym',
								},
								{
									name: 'price',
									jpath: 'paym__price',
								},
								{
									name: 'medi_price',
									jpath: 'paym.mdcr_itm.amount',
								},
								// {
								// 	name: 'paid',
								// 	jpath: 'cado.charge.paid',
								// 	type: 'bool',
								// },
								{
									name: 'status',
									jpath: 'paym_status',
								},
								{
									name: 'env',
									jpath: 'paym.CC_tok.PAY_ENV',
									jpath1: 'paym.charge.PAY_ENV'
								},
								{
									name: 'copay',
									jpath: 'med_db_data.xtra.copay',
								},
								{
									name: 'disc_code',
									jpath: 'paym.discount.code',
								},
								{
									name: 'disc_price',
									jpath: 'paym.discount.price',
								},
								{
									name: 'paym_js',
									jpath: 'paym',
									type: 'popup_json_obj',
									ico: 'clipboard list',
									compact: true,
									expLev: 1,
								},
								{
									name: 'items',
									jpath: 'paym.items',
									type: 'popup_json_obj',
									ico: 'shopping basket',
									compact: true,
								},
                                {
									name: 'onepass',
									jpath: 'form_data.onepass'
								},
							],
							template : ({ price, status, env, disc_code, disc_price, paym, paym_js, copay, items, row, onepass }) => isBulkBill(row) ? <h2 style={{color:'red'}}>Bulk Bill!</h2> 
										: <React.Fragment>
												{ status == 'medicare' 
													&& <MedicareClaim summary scr_obj={row} />
													||
													<>
														{copay && <><strong>Co-pay: ${copay}</strong><br/></>}
														{price && <><Icon name='credit card outline' /> ${price} <br/> </>}
														{paym?.paymentMethod} <br/>
														{status} <br/>
														{env} <br/>
														{disc_code && <><Icon name='arrow down cart' /> {disc_code}<br /></>}
														{paym?.items?.find(i=>i.id=='CREDIT') && <><IconGroup className='ml-2'><Icon name='user' /><Icon corner='top right' name='dollar sign' /></IconGroup>{currency(paym.items.find(i=>i.id=='CREDIT').price).format()}<br /></>}
                                                        {onepass && <>
                                                            <Badge small className='bg-[#E6E6E6] px-2' color='gray'>
                                                                OnePass Tier: {onepass?.tier}
                                                            </Badge>
															<br />
                                                        </>}
														{ disc_price && (
															<div className="py-2">
																<div>
																	{currency(disc_price).format()}
																</div>
																<Button 
																	as={Link} 
																	to={{
																		pathname: '/admin/discounts',
																	}}
																	size='mini'
																	compact
																	icon="arrow down cart"
																/>
															</div>
														)}
													</> 
												}
												{paym_js}{items}
											</React.Fragment>
						},

					mb_paym_pharm:
						{
							name: 'Paid',
							type: 'compound',
							parts : [
								{
									name: 'status',
									jpath: 'paym_status',
								},
								{
									name: 'env',
									jpath: 'paym.CC_tok.PAY_ENV',
								},

								{
									name: 'items',
									jpath: 'paym__items',
									// type: 'popup_json_obj',
									// ico: 'shopping basket',
									// compact: true,
								},

								{
									name: 'paym__price',
									jpath: 'paym__price',
								},
							],
							template : ({status, env, items, paym__price, row}) => (items || paym__price) && <React.Fragment>
												{items && <><Icon name='credit card outline' /> ${JSON.parse(items).filter( i => i.id=='MEDBUY')[0].price} <br/> </>}
												{paym__price && <><Icon name='credit card outline' /> {paym__price} <br/> </>}
												{status} <br/>
												{env} <br/>
											</React.Fragment> || null
						},

					doc_paym_sum:
						{
							name: '$',
							type: 'compound',
							parts: [
								{
									name: 'paid',
									jpath: 'paym__price'
								},
								{
									name: 'medi_price',
									jpath: 'paym.mdcr_itm.amount',
								},
								{
									name: 'mdcr_itm',
									jpath: 'paym.mdcr_itm',
								},
							],
							template : ({paid, medi_price,mdcr_itm, row})=> <React.Fragment>
											{mdcr_itm && <MedicareClaim summary scr_obj={row} /> || paid && <><Icon name='credit card outline' /> ${paid} </>}
											
										</React.Fragment>
						},

					scr_ans:
						{
							name: 'Answers',
							type: 'compound',

							parts : [
								{
									name: 'Answers',
									jpath: 'answs',
									type: 'popup_func',
									ico: 'question',
									compact: true,
									func: val => <AnswersList answs={val} />
								},
								{
									name: 'Errors',
									type: 'compound_popup',
									ico: 'exclamation triangle',
									compact: true,
									parts : [
										{
											name: 'form_errs',
											jpath: 'form_errs',
											// type: 'popup',
										},
										{
											name: 'err',
											jpath: 'err',
										},
										{
											name: 'err_desc',
											jpath: 'err_desc',
											// type: 'popup',
										},
										{
											name: 'repeat_warn',
											jpath: 'meta.repeat_warn',
											// type: 'popup',
										},
										{
											name: 'req_invalid_error',
											jpath: 'meta.req_invalid_error',
										},
										{
											name: 'k10_score',
											jpath: 'form_data.k10_score'
										}
									],
									template : ({err, err_desc, form_errs, repeat_warn, req_invalid_error, k10_score}) => (err||form_errs&&form_errs.length>0||err_desc||repeat_warn)
													&& <React.Fragment>
														{err && <React.Fragment><i>Error type:</i> <b>{err}</b><br /></React.Fragment>}
														{form_errs&&form_errs.length>0 && <React.Fragment><i>Form Errors:</i> {form_errs}<br /></React.Fragment>}
														{err_desc && <React.Fragment><i>Server Error:</i> {err_desc}<br /></React.Fragment>}
														{repeat_warn && <React.Fragment><i>Repeat Warning:</i> {repeat_warn}<br /></React.Fragment>}
														{req_invalid_error && <React.Fragment><i>Error Reason:</i> {req_invalid_error}<br /></React.Fragment>}
														{k10_score >= 30 && <React.Fragment><i>K10 Score:</i> <span className='text-red-500'>{k10_score}</span><br /></React.Fragment>}
													</React.Fragment>
								},
								{
									name: 'form_errs',
									jpath: 'form_errs',
									// type: 'popup',
								},
								{
									name: 'err',
									jpath: 'err',
								},
								{
									name: 'err_desc',
									jpath: 'err_desc',
									// type: 'popup',
									type: 'show_more',
									cut_length: 11,
								},
							],
							template : ({Answers, Errors, err, err_desc, form_errs})=> <React.Fragment>
											{Answers || '-'}
											{Errors && <React.Fragment><br/><br/>{Errors}</React.Fragment>}
											{err=='paym_errors' && <React.Fragment><br/><br/>paym:<br/>{err_desc}</React.Fragment>}
										</React.Fragment>
						},

					scr_req_env:
						{
							name: 'ENV',
							type: 'multiline',
							lines: [
										{jpath: 'runt.mode'},
										{type: 'br'},
										{jpath: 'req.fe'},
										{type: 'br'},
										{jpath: 'req.vr'},
										{type: 'br'},
										{type: 'useragent', jpath: 'req.useragent', },

										{type: 'val', val: 'ld_tm:', },
										{type: 'fixed', prec: 1, jpath: 'runt.perf.ld_tm', },
										{type: 'val', val: 'init_tm:', },
										{type: 'fixed', prec: 1, jpath: 'runt.perf.init_tm', },
										{type: 'br'},
										{ template:FieldReqTm },
										{type: 'val', val: 'req_tm:', },
										{jpath: 'meta.req_tms.lS', },
										{type: 'val', val: 's', },
									]
						},

				}

const COMB_FIELDS = {

					scr_note_hist:
						{
							name: 'Hist',
							type: 'multiline',
							lines: [
										COMM_FIELDS.scr_note,
										{type: 'br'},
										{type: 'br'},
										COMM_FIELDS.history,
									]
						},

				}

const _DET_FIELDS_PHARM = [
						COMM_FIELDS.time_type,

						COMM_FIELDS.pts_dets_wlink,

						COMM_FIELDS.med_dets,

						COMM_FIELDS.by_from,
					];

const _DET_FIELDS_NURSE = [
						COMM_FIELDS.time,

						COMM_FIELDS.pts_dets_wlink,

						COMM_FIELDS.med_dets,

						COMM_FIELDS.treat,

						!app.settings.ileg && COMM_FIELDS.by_from,

						COMM_FIELDS.doc,

						COMM_FIELDS.appointment,

						COMM_FIELDS.scr_note,

						!app.settings.ileg && COMM_FIELDS.call_dets,
					].filter(Boolean);

const _DET_FIELDS_MANG = _DET_FIELDS_NURSE;

const _DET_FIELDS_DOC = [
						COMM_FIELDS.time_type,

						COMM_FIELDS.pts_dets_wlink,

						COMM_FIELDS.med_treat,

						COMM_FIELDS.by_from,

						COMM_FIELDS.appointment,

						COMM_FIELDS.doc_skype,

						COMB_FIELDS.scr_note_hist,

						// COMM_FIELDS.doc,

						// COMM_FIELDS.call_dets,
					];

const _DET_FIELDS_DOC_CONS = [
						COMM_FIELDS.time_type,

						COMM_FIELDS.pts_dets_wlink,

						// COMM_FIELDS.med_dets,

						COMM_FIELDS.treat,

						COMM_FIELDS.org,

						COMM_FIELDS.appointment,

						COMM_FIELDS.doc_skype,

						COMM_FIELDS.doc_paym_sum,

						COMB_FIELDS.scr_note_hist,

						// COMM_FIELDS.doc,

						// COMM_FIELDS.call_dets,
					];


const _DET_FIELDS_ADMIN = [

						COMM_FIELDS.tm_snum_adm,

						// COMM_FIELDS.snum,

						COMM_FIELDS.type_adm,

						// COMM_FIELDS.time,

						COMM_FIELDS.pts_dets_adm,

						// COMM_FIELDS.is_pts,

						COMM_FIELDS.med_treat,

						!app.acl.is_doc_gp && COMM_FIELDS.by_from,

						COMM_FIELDS.appointment,

						COMM_FIELDS.doc_skype,

						COMM_FIELDS.scr_ans,

						COMB_FIELDS.scr_note_hist,

						// COMM_FIELDS.is_scr,

						// {
						// 	name: 'Status',
						// 	jpath: 'status',
						// },

						// {
						// 	name: 'Auto Appr',
						// 	jpath: 'org_data.conf.scr_auto_appr',
						// 	type: 'last_arr_bool',
						// },

					];

const _DET_FIELDS_ROOT = _DET_FIELDS_ADMIN;

// 		--------------------------------		--------------------------------		---------

const _DET_FIELDS_MEDBUY = [

						COMM_FIELDS.tm_snum,

						COMM_FIELDS.pts_dets_mb,

						SPEC_FIELDS.mb_post_addr,

						COMM_FIELDS.med_dets_mb,

						COMM_FIELDS.mb_paym_pharm,

					];

const _DET_FIELDS_MEDBUY_ALL = [

						..._DET_FIELDS_MEDBUY,

						{
							name: 'Status',
							jpath: 'status',
						},
					];

const _DET_FIELDS_MEDBUY_ADM = [

						..._DET_FIELDS_MEDBUY,

						COMM_FIELDS.org,

						COMM_FIELDS.adm_paym_sum,
					];

const _DET_FIELDS_MEDBUY_ALL_ADM = [

						..._DET_FIELDS_MEDBUY_ADM,

						{
							name: 'Status',
							jpath: 'status',
						},
					];

const _DET_FIELDS_MEDBUY_POST = [

						COMM_FIELDS.tm_snum,

						COMM_FIELDS.pts_dets_post,

						SPEC_FIELDS.mb_post_addr,

						COMM_FIELDS.med_dets_mb,

					];

const _DET_FIELDS_MEDBUY_POST_ADM = [

						..._DET_FIELDS_MEDBUY_POST,

						COMM_FIELDS.org,
					];

// 		--------------------------------		--------------------------------		---------

const _DET_FIELDS_PAYM = [

						COMM_FIELDS.adm_paym_sum,

					];

const _DET_FIELDS_CADO = [

						// {
						// 	name: 'Script?',
						// 	jpath: 'is_script',
						// 	type: 'bool',
						// },

						// {
						// 	name: 'CaDo',
						// 	jpath: 'cado.want',
						// 	type: 'bool',
						// },

						{
							name: 'Phone',
							jpath: 'cado.phone',
						},

					];

const _DET_FIELDS_ERROR = [];

const _DET_FIELDS_HIST = [

						COMM_FIELDS.history,

					];

// 		--------------------------------		--------------------------------		---------

const _DET_FIELDS_PHARM_STATS = [
						COMM_FIELDS.time_type,

						COMM_FIELDS.pts_dets,

						COMM_FIELDS.med_dets,

						COMM_FIELDS.by_from,
					];

const _DET_FIELDS_NURSE_STATS = [

						COMM_FIELDS.time,

						COMM_FIELDS.pts_dets,

						COMM_FIELDS.med_dets,

						COMM_FIELDS.treat,

						COMM_FIELDS.by_from,

						COMM_FIELDS.is_phts,

						COMM_FIELDS.doc,

						COMM_FIELDS.call_dets,

					];

const _DET_FIELDS_MANG_STATS = _DET_FIELDS_NURSE_STATS;

const _DET_FIELDS_DOC_STATS = [
						COMM_FIELDS.time_type,

						COMM_FIELDS.pts_dets_wlink,

						COMM_FIELDS.med_treat,

						COMM_FIELDS.by_from,

						COMM_FIELDS.doc_skype,

						// COMB_FIELDS.scr_note_hist,
					];
// const _DET_FIELDS_DOC_STATS = _DET_FIELDS_NURSE_STATS;

const _DET_FIELDS_ADMIN_STATS = _DET_FIELDS_ADMIN;

const _DET_FIELDS_ROOT_STATS = _DET_FIELDS_ADMIN;

const _DET_FIELDS_MEDICARE =  [

						COMM_FIELDS.tm_snum,

	{
		name: 'Medicare Status',
		jpath:'sid',
		template : ({value,row}) => <MedicareClaim stacked scr_obj={row} />
	},

	COMM_FIELDS.pts_dets_adm,

	COMM_FIELDS.doc_skype,

	{
		name: 'Status',
		jpath: 'status',
	},
]

const _DET_FIELDS_ADD_RESULTS =  [

						{
							name: 'id',
							type: 'multiline',
							lines: [
										COMM_FIELDS.tm_snum_adm,
										{type: 'br'},
										{type: 'br'},
										COMM_FIELDS.type_adm,
									]
						},


						{
							name: 'Add result',
							jpath:'sid',
							// template : (v,row) => row.script_type
							template : ({value,row,...props}) => <AddResult stacked scr_obj={row} key={row.sid} {...props} />
						},

						COMM_FIELDS.pts_dets_adm,

						{
							name: 'doc / test',
							type: 'multiline',
							lines: [
										COMM_FIELDS.doc_skype,
										{type: 'br'},
										COMM_FIELDS.med_treat,
									]
						},

						COMB_FIELDS.scr_note_hist,
];

const _DET_FIELDS_EMERG_NOTIFY =  [

						{
							name: 'id',
							type: 'multiline',
							lines: [
										COMM_FIELDS.tm_snum_adm,
										{type: 'br'},
										{type: 'br'},
										COMM_FIELDS.type_adm,
									]
						},
						COMM_FIELDS.pts_dets_adm,
						{
							name: 'Emerg Notification Details',
							jpath:'emerg_attach',
							template : ({value,row,...props}) => {
								const emerg_notify_dets = value.meta.emerg_notify
								const {measure, emerg_check, sms_sent, email_sent, date: notify_date } = emerg_notify_dets
								const flask_dets = row.attch.find(att => att.type === 'patho')
								const measure_dets = flask_dets && flask_dets.value.find(v => v.measure === measure)
								const { itemValue, isEmergencyLow, isEmergencyHigh, emergency_range_low, emergency_range_high } = emerg_check || {}
								const high_low_config = () => {
									let def = {}
									if(isEmergencyHigh){
										def = {
											cat: 'High',
											symb: '>',
											emerg_threshold: emergency_range_high 
										}
									} else if (isEmergencyLow){
										def = {
											cat: 'Low',
											symb: '<',
											emerg_threshold: emergency_range_low 
										}
									}
									return def
								}
								const em_config = high_low_config()
								const results_label = `${itemValue} ${measure_dets?.units} (${em_config?.cat}) ${em_config?.symb} ${em_config?.emerg_threshold}`
								const notifications_sent = []
								email_sent && notifications_sent.push('Email')
								sms_sent && notifications_sent.push('SMS')
								return <div>
									<p>Emergency Notification sent for <b>{measure}</b></p>
									<div className='flex flex-col'>
										<span><b>Results: </b>{results_label}</span>
										<span><b>Notified:</b> {moment(notify_date).format('DD-MM-YYYY H:mm:ss a')}</span>
										<span><b>Notified By: </b>{notifications_sent.join(' and ')}</span>
										<span><b>RID:</b> {value.rid}</span>
									</div>
								</div>
							}
						},
						{
							name: 'doc / test',
							type: 'multiline',
							lines: [
										COMM_FIELDS.doc_skype,
										{type: 'br'},
										COMM_FIELDS.med_treat,
									]
						},

						COMB_FIELDS.scr_note_hist,
];

const _DET_FIELDS_MAAS = [

						COMM_FIELDS.time,

						COMM_FIELDS.scr_type,
						
						COMM_FIELDS.med_min,

						{
							name: 'Price',
							jpath: 'paym__price',
						},
					];

const _DET_FIELDS_DOC_CONS_STATS = [
						COMM_FIELDS.tm_snum_adm,

						COMM_FIELDS.type_adm,

						COMM_FIELDS.pts_dets_adm,

						COMM_FIELDS.med_treat,

						COMM_FIELDS.doc_skype,

						{
							name: 'Status History',
							jpath: 'hist',
							type: 'inline_status_hist',
						},

						{
							// name: 'ENV',
							type: 'multiline',
							lines: [
										{
											type: 'dict',
											jpath: 'status',
											func: s => <strong>{scripts_list_model.stats_dict(s)}</strong> ,
										},

										{type: 'br'},
										{type: 'br'},

										COMM_FIELDS.doc_paym_sum,

										{type: 'br'},
										{type: 'br'},

										{
											name: 'Req',
											template:FieldRequest
										},
									]
						},

						COMM_FIELDS.scr_req_env,
						
					]	

const _DET_FIELDS_EVERMED_STATS = [
						COMM_FIELDS.tm_snum_adm,
						COMM_FIELDS.pts_dets_adm,
						COMM_FIELDS.by_from,
						{
							// name: 'ENV',
							type: 'multiline',
							lines: [
										{
											type: 'dict',
											jpath: 'status',
											func: s => <strong>{scripts_list_model.stats_dict(s)}</strong> ,
										},

										{type: 'br'},
										{type: 'br'},

										COMM_FIELDS.doc_paym_sum,

										{type: 'br'},
										{type: 'br'},

										{
											name: 'Req',
											template:FieldRequest
										},
									]
						},
						
					]	

// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------


// const _MODEL_NAME = 'scripts_list_model';

// const _SCRIPT_PATIENT_FIELDS = [
// 	'spd_data.first_name',
// 	'spd_data.last_name',
// 	'spd_data.dob',
// 	'spd_data.email',
// 	'spd_data.medicare',
// 	'spd_data.address',
// ]

const _SCRIPT_TYPE_LIST = {
			'pharm': 'Pharmacy Script',
			'cosm': 'Cosmetic Treatment',
			'medcert': 'Medical Certificate',
			'custadm': 'Manual Script',
			'pthl-needlestick': 'Pathology Needle stick injury',
		}


const SCRIPT_MSG_OPT_WRONG_DETS = [
	`We are very sorry we cannot provide you the medication you have requested when our doctor reviewed your online request.
We don't want our patients being out of pocket if we cannot provide the service they are after.
As per our process in cancelling the booking your money has been refunded by our team and you will see that back in your account shortly, usually 3-5 business days depending on which bank or financial institution.
Please call 000 should the assistance you require is urgent.`,
	'We were unable to contact you, please ensure you are able to accept private calls.',
	'We couldn\'t reach you, please check your provided mobile phone number.',
	'Thank you for requesting an appointment with InstantScripts. Unfortunately, we are not able to provide requested treatment. We recommend booking an in-person consult with your regular doctor to discuss your options further. Kind regards, InstantScripts.',
	'We are unable to assist with this. We apologise for the inconvenience. Please see your local doctor for assistance.',
	'Please use hivprevent.com.au for requested treatment.',
	'Consultation no longer required.',
	`Unfortunately, we're unable to proceed with your appointment request, as Medicare has advised that you're not eligible for a new Mental Health Care Plan (MHCP) at this date. This is likely because you have already had an MHCP completed in the past 12 months.

Please contact your regular GP, so they can review your original MHCP and make the required changes.

If you feel you need urgent care, please call Lifeline 13 11 14, Beyond Blue 1300 224 636 or present to your local Emergency Department.`,
`Thank you for choosing InstantScripts for your health needs.
We noticed you have booked the incorrect consultation type.
To provide you with the best possible care you will need to book an InstantScripts General Consultation
instead of the service you previously ordered.
We ask you to now re-book for an InstantScripts General Consultation.
We have cancelled and will refund your initial consultation fee.`,
`Thank you for choosing InstantScripts for your health needs.
We noticed you have booked the incorrect consultation type.
To provide you with the best possible care you will need to book an InstantScripts Weight Loss Consultation
instead of the service you previously ordered.
We ask you to now re-book for an InstantScripts Weight Loss Consultation.
We have cancelled and will refund the service that you originally booked.`,
`Thank you for choosing InstantScripts for your health needs.
We noticed you have booked the incorrect consultation type.
To provide you with the best possible care you will need to book an InstantScripts Hair Loss Consultation
instead of the service you previously ordered.
We ask you to now re-book for an InstantScripts Hair Loss Consultation.
We have cancelled and will refund the service that you originally booked.`,
`We are unable to prescribe this medication through Telehealth and advise you see a local medical
practitioner.
If you still require medical attention please present to your regular GP or local emergency department`
]

const SCRIPT_MSG_OPT_NO_ANS = [
	`Thank you for booking a doctor consultation with InstantScripts.
Unfortunately, our doctor could not reach you at the phone number you provided when booking your consultation. If the number is incorrect, please let us know by clicking the link below and changing the number. 
It is also possible that your phone is automatically blocking unknown callers. This feature may be disabled in your phone settings to ensure we can reach you.
If you are unsure of how to check settings please follow the prompts below:
iphone: Settings > Phone > Un/Silence Unknown Callers.
galaxy: From the Home screen, tap Phone. Tap MORE > Settings > More settings > Caller ID > Tap desired setting to view or edit.
The doctor will try calling you again soon.`,
	'We were unable to reach you. Please check the mobile phone number you provided or request again at a more convenient time.',
	'We were unable to contact you, please ensure you are able to accept private calls.',
]

const SCRIPT_MSG_OPT_NO_ANS_CANCEL = [
	'Consultation canceled after multiple calls',
]


const SCRIPT_STATUS_BTNS = {
			'doc_approve': [
						{n:'approve paper script', a:'doc_approved', p:{is_scr: true, eml: true}, c:'green', i:'check'},
						{n:'reject', a:'doc_declined', p:{is_scr: false, eml: true}, c:'red', i:'stop'},
					],

			'doc_approve_escr': [
						{n:'approve eScript', a:'doc_approved', p:{is_scr: true, eml: true}, c:'green', i:'check'},
						{n:'reject eScript', a:'doc_declined', p:{is_scr: false, eml: true}, c:'red', i:'stop'},
					],

			'doc_revoke': [
						{n:'revoke', a:'doc_declined', cust:'stat_w_msg', msg_req: true, pl_hld:'Revoke Script Reason/ Ref', p:{is_scr: false}, c:'red', i:'stop'},
					],
			'doc_prescribe_again': [
						{
							n: 'Prescribe again',
							f: (row) => app.trigger(app.events.PRESCRIBE_AGAIN, row.epresc?.item ? row.epresc : { item: row.epresc?.log?.find?.((l) => l.item)?.item, mysl_consent: row.epresc.mysl_consent }),
							c: 'blue',
							i: 'medkit'
						}
					],

			'doc_consult': [
						{ n:'Take patient', 
							f: async (row, index) => {
										const page = script_model.is_express_consult(row) ? 'exprcons' : 'doccons';
										// console.log('in btn', row.add_tm, row.sid);
										const result = await API_service.load_data('chgStat', {
											sid: row.sid,
											stat: 'in_doccall',
											index,
											...(!row.doc && { doc: app.user.claims.doc_id }), // Ony assign a doc if there is no doctor assigned
										}); 
										// console.log('result', result);
									
										if (result.error) {
											alert(result.error);
										}
										else if (!['await_doccall', 'noans_doccall', 'wrong_number', 'followup_doccall', 'on_hold'].includes(result.fro?.status) && result.fro?.doc && result.fro?.doc != app.user.claims.doc_id )
											alert('This patient is already in consult');
										else
											app.history.push(`/${page}/${row.sid}/${row.spd_hash__dob}`);

										return {
												['tstat'] : 'in_doccall',
												['tstts_msg']: result.res,
											}
									} ,
							_l: r=>`/doccons/${r.sid}/${r.spd_hash__dob}`, 
							c:'purple', i:'doctor'
						},
						// {n:'await_doccall', a:'await_doccall', c:'yellow', i:'talk'},
					],

			'flagged_urgent_unassigned': [
				{
					n: 'Take patient',
					f: async (row) => {
						const result = await API_service.load_data(
							'chgStat',
							{sid: row.sid, doc: app.user.claims.doc_id}
						);
						if (result?.res === 'ok') {
							app.history.push(`/doccons/${row.sid}/${row.spd_hash__dob}/urgent`);
						} else {
							alert(result.error || 'Patient may already be in a consult with another doctor');
						}

						return result;
					},
					c: 'purple',
					i: 'doctor'
				},
			],

			'flagged_urgent_assigned': [
				{
					n: 'Take patient',
					f: async (row) => {
						app.history.push(`/doccons/${row.sid}/${row.spd_hash__dob}/urgent`);
					},
					c:'purple', i:'doctor'
				},
				{
					n: 'Back to await',
					f: async (row) => {
						const result = await API_service.load_data('chgStat',{sid: row.sid, doc: null});
						if (result?.res !== 'ok') {
							alert(result.error || 'Something went wrong returning the patient to the urgent care waiting room, try reloading');
						}

						return result;
					},
					c: 'green',
					i: 'call'
				},
			],

			'flagged_urgent_done': [
				{
					n: 'Scripts to Pt',
					f: async (row) => {
						app.history.push(`/doccons/${row.sid}/${row.spd_hash__dob}/urgent`);
					},
					c:'purple', i:'doctor'
				},
			],

			'await_consult': [
						{n:'Can\'t reach pts', a:'noans_doccall', cust: 'stat_w_msg', cann_dd_ops: SCRIPT_MSG_OPT_NO_ANS, msg_req: false, c:'blue', i:'talk'},
						{n:'Wrong request', a:'wrong details', cust:'stat_w_msg', cann_dd_ops: SCRIPT_MSG_OPT_WRONG_DETS, msg_req: false, c:'red', i:'exclamation'},
						{n:'contact pts', a:'contact pts', cust: 'stat_w_msg', c:'yellow', i:'talk'},
					],

			'not_await_consult': [
						{n:'Back to await', a:'await_doccall', c:'green', i:'call'},
					],

			'doc_followup_consult': [
						{n:'Make Follow Up call', l: r=>`/doccons/${r.sid}/${r.spd_hash__dob}`, c:'purple', i:'doctor'},
					],

			'doc_send_scrs': [
						{n:'Scripts to Pt', l: r=>`/doccons/${r.sid}/${r.spd_hash__dob}`, c:'purple', i:'doctor'},
					],
			'np_send_scrs': [
						{n:'Scripts to Pt', l: r=> instcons_model.consult_route(r), c:'purple', i:'doctor'},
					],

			'doc_followup': [
						{n:'Follow Up', 
							cf: (r, row) => `Are you sure you want to create a follow up to this consult with ${row.spd_data.first_name} ${row.spd_data.last_name} from ${row.add_tm}?`,
							f: async (row) => {

										// console.log('in follow up req', row.add_tm, row.sid);
										const req_data = {
											script_type: 'doccons',
											req_type: 'followup',

											form_data: {...row.form_data, ...row.spd_data, sendto_oid: row.form_data?.sendto_oid||row.org__oid||row.org_data?.m}, 

											med: row.med, 

											meta: {ref_sid: row.sid},

											doc: row.doc,
										}

										const scr_req = await API_service.load_data(
																'reqScriptForm', 
																req_data,
															)


										app.trigger(app.events.SHOW_MESSAGE, 
												'Creating Follow Up Consultation Record', 
												scr_req.res=='ok' && `Success creating record` || `ERROR creating record :: ${scr_req.err} -- ${scr_req.desc}`,
												scr_req.res=='ok' && 'positive' || 'negative')

										if (scr_req.res=='ok' && scr_req.sid)
											app.history.push(`/doccons/${scr_req.sid}/${row.spd_hash__dob}`);

									} ,
							_l: r=>`/doccons/${r.sid}/${r.spd_hash__dob}`,
							c:'yellow', i:'stethoscope'
						},

						{n:'Request FU booking', 
							// a:'req_followup_cons', 
							au:'scripts/doc_req_followup_cons', 
							cust:'stat_w_msg', 
							pl_hld:'Specify Reason', 
							// msg_init:r=>r.meta?.note, 
							c:'teal', i:'money bill alternate outline'
						},
					],
			
			'leg_followup': [
						{n:'Follow Up', 
							cf: (r, row) => `Are you sure you want to create a follow up to this consult with ${row.spd_data.first_name} ${row.spd_data.last_name} from ${row.add_tm}?`,
							f: async (row) => {
								console.log(row);
										// console.log('in follow up req', row.add_tm, row.sid);
										const req_data = {
											script_type: 'doccons',
											req_type: 'followup',

											form_data: {...row.form_data, ...row.spd_data, sendto_oid: row.form_data?.sendto_oid||row.org__oid||row.org_data?.m}, 

											med: {id: "-MpxWqdWIDvYiZm7fsfz", name: "Debrief Follow Up Consultation"}, 

											meta: {ref_sid: row.sid},

											doc: row.doc_db_data__id,

											// runt:row.runt,

											partn:{oid: app.user.org.oid},

											dvc: app.dvc,
											usr : app.user.user_det,
											// med: {id:med_code, route:cat_route},
											runt: app.runtime,
										}

										const scr_req = await API_service.load_data(
																'reqScriptForm', 
																req_data,
															)

										console.log('result scr_req', req_data, scr_req);

										app.trigger(app.events.SHOW_MESSAGE, 
												'Creating Follow Up Consultation Record', 
												scr_req.res=='ok' && `Success creating record` || `ERROR creating record :: ${scr_req.err} -- ${scr_req.desc}`,
												scr_req.res=='ok' && 'positive' || 'negative')

									} ,
							_l: r=>`/doccons/${r.sid}/${r.spd_hash__dob}`,
							c:'yellow', i:'stethoscope'
						},

						
					],


			'add_note': [
						{n:'add note', a:'script note', cust:'stat_w_msg', pl_hld:'Add Note', info:r=>r.meta?.note, c:'olive', i:'write'},
					],

			'presc_prep': [
						{
							n: 'Prescribe PrEP', 
							// a:'req_followup_cons', 
							au: 'scripts/doc_presc_prep_res', 
							cust: 'stat_w_msg', 
							pl_hld: 'Specify Reason', 
							c: 'teal', i: 'doctor',
							msg_init: _=>'We got your pathology results. Our doctors approved PrEP to you. Request your medication now.',
						},
					],

			// 'doc_approve': [
			// 			{n:'approve', u: r=>`${SCRIPT_ROOT}reqScriptAppr/${r.sid}/true`, c:'green', i:'check'},
			// 			{n:'stop', u: r=>`${SCRIPT_ROOT}reqScriptAppr/${r.sid}/false`, c:'red', i:'stop'},
			// 		],

			'pharm_scr_phrm': [
						{n:'dispensed', a:'dispensed', c:'green', i:'ticket'},
						{n:'no show', a:'no show', c:'yellow', i:'street view'},
						{n:'wrong details', a:'wrong details', c:'red', i:'exclamation'},
						{n:'request printed script', a:'request printed script', c:'blue', i:'send'},
					],
			'medbuy_scr_phrm': [
						{n:'shipped', a:'shipped', c:'green', i:'shipping fast'},
						{n:'to post', a:'to post', c:'olive', i:'send'},
						{n:'contact pts', a:'contact pts', cust: 'stat_w_msg', c:'yellow', i:'talk'},
						{n:'wrong details', a:'wrong details', cust:'stat_w_msg', c:'red', i:'exclamation'},
					],
			'medcons_scr_phrm': [
						{n:'collected', a:'collected', c:'green', i:'ticket'},
						{n:'no show', a:'no show', c:'yellow', i:'street view'},
						{n:'wrong details', a:'wrong details', c:'red', i:'exclamation'},
						{n:'request printed script', a:'request printed script', c:'blue', i:'send'},
					],


			'adm_non_user': [
						{n:'New usr from scr', f: async (row) => { return await API_service.load_data('UserAdmin/newUsrFromScr', {sid: row.sid})}, c:'green', i:'user'},
					],

			'adm_scr_true': [
						{n:'Decline Script', cust:'stat_w_msg', msg_req: true,pl_hld:'Decline Script Reason/ Ref', p:{is_scr: false, bypass:true }, c:'red', i:'minus'},
					],

			'put_on_hold': [
				{n:'Put on hold', a: 'on_hold', cust:'stat_w_msg', msg_req: true,pl_hld:'Reason for putting on hold', c:'yellow', i:'clock'},
			],

			'adm_scr_false': [
				{
				n:'Allow Script',
				c:'green',
				i:'plus', 
				f: async (row) => {
					const confirm_res = await app.confirm('Allow script?',`Are you sure you want to allow this script for ${row.spd_data.first_name} ${row.spd_data.last_name}?`)
					if(confirm_res == 'yes'){
						const result = await API_service.load_data(
											'chgStat', 
											{sid: row.sid, is_scr: true, bypass:true}
										) 
	
						row.status = result.to?.status
						row.is_script = result.to?.is_script
	
						return result;
					} 
					return
				},},
			],
			'reissue_escr': [
				{
				n:'Re-issue as eScript',
				c:'orange',
				i:'warning sign', 
				f: async (row) => {
					const err = row.epresc.log?.find(l=>l.err)?.err || row.epresc.err
					const content = <>
						<p className="text-md">This will attempt to re-issue a failed eScript. </p>
						<p>The last error:</p>
						<pre className="block whitespace-pre overflow-scroll h-44 w-full mb-4">{JSON.stringify(err,null,'  ')}</pre>
						<p className="text-md">⚠️ Are you sure you know what you're doing? ⚠️</p>
					</>
					const confirm_res = await app.confirm('Re-issue as eScript?',content)
					if(confirm_res == 'yes'){
						const result = await API_service.load_data(
											'chgStat', 
											{sid: row.sid, reissue: true}
										) 
	
						row.status = result.to?.status
						row.is_script = result.to?.is_script
	
						return result;
					} 
					return
				},},
			],
			'adm_form_errors': [
				{
				n:'Reset Form Errors',
				c:'yellow',
				i:'exclamation', 
				f: async (row) => {

					const confirm_res = await app.confirm('Reset patient answers attempt?',`Are you sure you want to reset the form for ${row.spd_data.first_name} ${row.spd_data.last_name}?`)
					if(confirm_res == 'yes'){
						const result = await API_service.load_data(
											'chgStat', 
											{sid: row.sid, stat:"_form_errors", bypass:true}
										) 
	
						row.status = result.to?.status
	
						return result;
					} 
					return
				},},
			],

			'adm_switch': [
				{
					n: 'Switch to Paper Script',
					c: 'orange',
					i: 'file alternate outline',
					f: async row => {
						const confirm_res = await app.confirm(
							'Confirm switch to paper script',
							'The patient will be emailed and the eScript will be cancelled, continue?'
						);

						if (confirm_res === 'yes') {
							const data = {
								switch_paper: true,
								is_scr: true,
								sid: row.sid,
							};

							return await API_service.load_data('chgStat', data);
						}
					}
				},
			],

			'root_scr': [
						{n:'Any status', cust: 'stat_dd',  p:{ bypass:true },c:'yellow', i:'attention'},
					],

			'evermed_actions': [
						{n:'Evermed Actions',
						 c:'violet',
						 i:'plus',
						 cf: (r, row) => (<><EvermedActions row={row}/></>),
						 f: async (row, i) => {console.log(row, i)}
						},
					],

		}

const SCRIPT_STATUS_OPTIONS = [
		'approved',
		'script_ready',
		'auto_approved',
		'done_doccall',
		'form_errors',
		'_form_errors',
		'doc_issued',
		'vrf_errors',
		'script_refused',
		'repeat_error',
		'_approved',
		'flagged_urgent',
		'collected',
		'shipped',
		'paym_errors',
		'noans_doccall',
		'could_not_call',
		'wrong_number',
		'wrong details',
		'_shipped',
		'dispensed',
		'request printed script',
		'await_doccall',
		'followup_doccall',
		'in_doccall',
		'doc_approved',
		'doc_declined',
		'qual_declined',
		'expr_declined',
		'no show',
		'script_cado',
		'user_cancel',
		'cado_approved',
		'await_cado',
		'auto_approved_delay',
		'to post',
		'contact pts',
		'in_checked'
	]

const CONS_TYPE_TO_HL_COL = (scr_type, req_type) => {
	const colors = {
		icann: 'rgb(236 252 203)',
		ileg: 'rgb(243 232 255)',
		docconswl: 'rgb(208 232 255)',
		docconswli: 'rgb(208 232 255)',
		docconswlo: 'rgb(208 232 255)',
		ihair: 'rgb(230 160 143)',
		dc_av: '#ffff00',
		dc_uti: 'rgba(255,219,242,0.79)',
		dc_sti: 'rgba(255,219,242,0.79)',
		dc_fertility: 'rgb(0,165,174,0.7)',
		dc_rh: '#85CABE',
		dcs: 'rgba(255,219,242,0.79)',
		mhcp: '#f0e68c',
		mhcprev: '#f0e68c',
		subcons: '#fadbff',
		qualcons: '#a3fabc',
		qualbuy: '#a3fabc',
		qualclick: '#a3fabc',
	};

	return colors[req_type] || colors[scr_type] || colors[req_type?.split("_")[0]];
};
const CONS_TYPE_TO_HL_BOR = (s, r, row) => (s=='doccons' && ['followup', 'docord', 'doccons_fu', 'followup_cron', 'followup_res'].includes(r) && '7px dashed purple'
											|| !app.acl.is_spon && isBulkBill(row) && '10px dotted red'
 /*|| s=='doccons' && r=='followup' && '7px dashed green' || s=='doccons' && r=='docord' && '7px dashed blue' || s=='doccons' && r=='doccons_fu' && '7px dashed orange' || s=='doccons' && r=='followup_cron' && '7px dashed yellow' || s=='doccons' && r=='followup_res' && '7px dashed red'*/ )

const SCRIPT_ROOT = 'https://api.instantscripts.com.au/'
// const SCRIPT_ROOT = 'http://localhost:8080/'

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class scripts_list_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC + (scripts_list_model.alt_db||''); }
	static get DET_FIELDS() { return _DET_FIELDS_ADMIN; }

	static get SCRIPT_TYPE_LIST() { return _SCRIPT_TYPE_LIST; }

	static get DET_FIELDS_PHARM() { return _DET_FIELDS_PHARM; }
	static get DET_FIELDS_NURSE() { return _DET_FIELDS_NURSE; }
	static get DET_FIELDS_MANG() { return _DET_FIELDS_MANG; }
	static get DET_FIELDS_DOC() { return _DET_FIELDS_DOC; }
	static get DET_FIELDS_DOC_CONS() { return _DET_FIELDS_DOC_CONS; }
	static get DET_FIELDS_ADMIN() { return _DET_FIELDS_ADMIN; }
	static get DET_FIELDS_ROOT() { return _DET_FIELDS_ROOT; }
	static get DET_FIELDS_MEDICARE() { return _DET_FIELDS_MEDICARE; }
	static get DET_FIELDS_ADD_RESULTS() { return _DET_FIELDS_ADD_RESULTS; }
	static get DET_FIELDS_EMERG_NOTIFY() { return _DET_FIELDS_EMERG_NOTIFY; }
	static get DET_FIELDS_MEDBUY() { return _DET_FIELDS_MEDBUY; }
	static get DET_FIELDS_MEDBUY_ALL() { return _DET_FIELDS_MEDBUY_ALL; }
	static get DET_FIELDS_MEDBUY_POST() { return _DET_FIELDS_MEDBUY_POST; }
	static get DET_FIELDS_MEDBUY_ADM() { return _DET_FIELDS_MEDBUY_ADM; }
	static get DET_FIELDS_MEDBUY_ALL_ADM() { return _DET_FIELDS_MEDBUY_ALL_ADM; }
	static get DET_FIELDS_MEDBUY_POST_ADM() { return _DET_FIELDS_MEDBUY_POST_ADM; }
	

	static get DET_FIELDS_PAYM() { return _DET_FIELDS_PAYM; }
	static get DET_FIELDS_CADO() { return _DET_FIELDS_CADO; }
	static get DET_FIELDS_ERROR() { return _DET_FIELDS_ERROR; }
	static get DET_FIELDS_HIST() { return _DET_FIELDS_HIST; }

	static get DET_FIELDS_PHARM_STATS() { return _DET_FIELDS_PHARM_STATS; }
	static get DET_FIELDS_NURSE_STATS() { return _DET_FIELDS_NURSE_STATS; }
	static get DET_FIELDS_MANG_STATS() { return _DET_FIELDS_MANG_STATS; }
	static get DET_FIELDS_DOC_STATS() { return _DET_FIELDS_DOC_STATS; }
	static get DET_FIELDS_ADMIN_STATS() { return _DET_FIELDS_ADMIN_STATS; }
	static get DET_FIELDS_ROOT_STATS() { return _DET_FIELDS_ROOT_STATS; }

	static get DET_FIELDS_MAAS() { return _DET_FIELDS_MAAS; }

	static get DET_FIELDS_DOC_CONS_STATS() { return _DET_FIELDS_DOC_CONS_STATS; }
	static get DET_FIELDS_EVERMED_STATS() { return _DET_FIELDS_EVERMED_STATS; }

	static get TYPE_DICT() { return TYPE_DICT; }
	
	// static get SCRIPT_STATUS_OPTIONS() { return SCRIPT_STATUS_OPTIONS; }

	static get CONS_TYPE_TO_HL_COL() { return CONS_TYPE_TO_HL_COL; }
	static get CONS_TYPE_TO_HL_BOR() { return CONS_TYPE_TO_HL_BOR; }

	// static get DET_FIELDS_COSM() { return _DET_FIELDS_COSM; }
	// static get MODEL_NAME() { return _MODEL_NAME; }

	// static get SCRIPT_PATIENT_FIELDS() { return _SCRIPT_PATIENT_FIELDS; }

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static set_db(c) {
		scripts_list_model.alt_db = c || false;
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static load_scripts_list( params, ca, ce ) {

		return new Promise((resolve,reject)=>{
			API_service.call_api(
										'getScriptsList',
										params,
										// 'post_form_cred', null,
										r => {
											resolve(r)
											ca && ca(r)
										},
										r => {
											reject(r);
											ce && ce(r);
										},
								)

		})
		
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static load_by_sid(sid, params = {}) {
		return scripts_list_model.load_scripts_list({ ...params, filters: {...params.filters, sid }}).then(res => {
			if (res.res === 'ok') {
				const count = (res.listScripts || []).length;
				if (count === 1) {
					return res.listScripts[0];
				}
				return null;
			}
			return Promise.reject(new Error(res.err || `Error loading script ${sid}`));
		});
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static type_name (c) {

		if (!c)			return null

		return _SCRIPT_TYPE_LIST[c] || c

	}

	static set_list_params (props) {

		const { show_list, show_prescribe_again } = props.match&&props.match.params||props

		let hdr='', flds, csv_flds, flts={}

		if (app.user.claims&&(app.user.claims.root||app.user.claims.admin||app.user.claims.supp||app.acl.can_urgent_care)) {
			hdr = 'View all scripts'
			flds = [ ...scripts_list_model.DET_FIELDS_ADMIN, ...scripts_list_model.DET_FIELDS_PAYM ]
			if (show_list == 'medicare') {
				hdr = 'Medicare Status'
				flds = [...scripts_list_model.DET_FIELDS_MEDICARE]
			} else if (show_list === 'urgent') {
				hdr = 'Urgent Follow-ups'
				flts.filters = {status: ['flagged_urgent']};
			}
		}
		else if (app.user.claims&&app.user.claims.doc && !app.user.claims.nurse) {
			hdr = `View scripts you've signed`
			flds =  ['await_consult', 'await_consult_all', 'consult_list'].includes(show_list) ? scripts_list_model.DET_FIELDS_DOC_CONS : scripts_list_model.DET_FIELDS_DOC
		}
		else if (app.user.claims?.mang && ['spon', 'maas'].includes(app.user.org.type)) {
			hdr = 'View all your organisation requests'
			flds = scripts_list_model.DET_FIELDS_MAAS
		}
		else if ((app.user.claims?.pharm || app.user.claims?.mang) && ['pharm', 'maas'].includes(app.user.org.type)) {
			hdr = 'View all your pharmacy scripts'
			flds = scripts_list_model.DET_FIELDS_PHARM
		}
		else if (app.user.claims&&app.user.claims.mang) {
			hdr = app.settings.ileg ? 'All Records' : 'View all scripts for your organisation'
			flds = scripts_list_model.DET_FIELDS_MANG
	
			if (app.user.org.type=='cosm')
				csv_flds = [
						'Time',
						'First',
						'Last',
						'DOB',
						'Mobile',
						'Medicare',
						'Conc',
						'Medicine',
						'Size',
						'qnty',
						'Quantity',
						'Location',
						'Notes',
						'Org',
						'By',
						'User',
						'Doctor',
						['Video?','cosm_det.doc'],
						'Avg. Wait Time'
					]
		}

		else if (app.user.claims&&app.user.claims.nurse) {
			hdr = app.settings.ileg? 'All patient referrals' : 'View all your/clinic scripts'
			flds = scripts_list_model.DET_FIELDS_NURSE
		}


		if (show_list == 'cado' || show_list == 'all') {
			hdr += ' - CALL DOCTOR'
			flds = [ ...flds, ...scripts_list_model.DET_FIELDS_CADO ]
		}

		if (show_list == 'err' || show_list == 'all') {
			hdr += ' - ERRORS'
			flds = [ ...flds, ...scripts_list_model.DET_FIELDS_ERROR ]
		}

		if (show_list == 'manual') {
			hdr += ' - MANUAL'
			// flds = [ ...flds, ...scripts_list_model.DET_FIELDS_ERROR ]
		}

		if (show_list == 'paid') {
			hdr += ' - ALL PAID'
		}
		if (show_list == 'discount') {
			hdr += ' - PAID WITH DISCOUNT'
		}
		if (['medbuy', 'exprbuy', 'qualbuy'].includes(show_list)) {
			hdr += ' - MedBuy all orders'
			flds = (app.user.claims.admin||app.user.claims.supp) ? scripts_list_model.DET_FIELDS_MEDBUY_ALL_ADM : scripts_list_model.DET_FIELDS_MEDBUY_ALL
		}
		if (show_list == 'medbuy_new') {
			hdr += ' - MedBuy new orders'
			flds = (app.user.claims.admin||app.user.claims.supp) ? scripts_list_model.DET_FIELDS_MEDBUY_ADM : scripts_list_model.DET_FIELDS_MEDBUY
		}
		if (show_list == 'medbuy_post') {
			hdr += ' - MedBuy orders for post'
			flds = (app.user.claims.admin||app.user.claims.supp) ? scripts_list_model.DET_FIELDS_MEDBUY_POST_ADM : scripts_list_model.DET_FIELDS_MEDBUY_POST
		}
		if (['medcons', 'exprcons', 'qualcons'].includes(show_list)) {
			hdr += ' - MedCons - App Scripts'
		}
		if (show_list == 'doccons') {
			hdr += ' - DocCons - Doctor Consultation requests'
		}
		if (show_list == 'photo') {
			hdr += ' - Photo scripts'
		}
		if (show_list == 'hist') {
			hdr += ' - Changed scripts'
			flds = [ ...flds, ...scripts_list_model.DET_FIELDS_HIST ]
		}

		if (show_list == 'support') {
			hdr += ' - scripts support'
			// flds = [ ...flds, COMM_FIELDS.treat ]
		}

		if (show_list == 'approve') {
			hdr = 'Scripts List - Awaits Doctor Approval'
		}


		if (show_list == 'await_consult') {
			hdr = 'Waiting List for Doctor Consultation Call'
			// flds = [ ...flds, ...scripts_list_model.DET_FIELDS_HIST ]
		}
		if (show_list == 'await_exprcons') {
			hdr = 'Waiting List for Express Consultation Call'
			// flds = [ ...flds, ...scripts_list_model.DET_FIELDS_HIST ]
		}
		if (show_list == 'need_review') {
			hdr = 'Scripts Needing Manual Review'
			// flds = [ ...flds, ...scripts_list_model.DET_FIELDS_HIST ]
		}
		if (show_list == 'await_consult_all') {
			hdr = 'Full Waiting Room for Doctor Consultation Call'
			// flds = [ ...flds, ...scripts_list_model.DET_FIELDS_HIST ]
		}

		if (show_list == 'consult_list') {
			hdr = 'Doctor Consultation history'
			// flds = [ ...flds, ...scripts_list_model.DET_FIELDS_HIST ]
		}

		if (show_list == 'add_results') {
			hdr = 'Add results, create followups'
			flds = scripts_list_model.DET_FIELDS_ADD_RESULTS
			flts.filters = {hide_err:true}
		}

		if (show_list == 'emerg_notify') {
			hdr = 'Emergency Notifications'
			flds = scripts_list_model.DET_FIELDS_EMERG_NOTIFY
			flts.filters = {hide_err:true}
		}
		
		const data_flds = [...flds]
		flds = [...flds, ...this.cust_scr_flds(show_list, show_prescribe_again)]

		if(show_list == 'doc_cons_stats'){
			flds = scripts_list_model.DET_FIELDS_DOC_CONS_STATS
		}

		if(show_list == 'evermed_stats'){
			flds = scripts_list_model.DET_FIELDS_EVERMED_STATS
		}

		if (show_list === 'cosm_stats') {
			if (app.user?.claims?.admin) {
				flds = [...scripts_list_model.DET_FIELDS_MANG, ...scripts_list_model.DET_FIELDS_PAYM];
			} else {
				flds = scripts_list_model.DET_FIELDS_MANG;
			}
		}

		if (flds) {
			flds = flds.filter(({ name }) => {
				if (name === 'Appointment') {
					return !!app?.site_status?.booking?.show_script_list_appointment;
				}
				return true
			});
		}

		return {
					show_list,
					hdr,
					flds,
					data_flds,
					csv_flds,
					...flts,
				}
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static stats_btns (row) {

		let btn_set
		let btns_arr = []
		let groups = {
			doc:[],
			pharm:[],
			admin:[],
			oper:[]
		}

		const is_consult_like = (
			script_model.is_express_consult(row)
			|| script_model.is_review_consult(row)
			|| script_model.is_async_consult(row)
		);

		if (app.acl.is_NP && row.cosm_det?.ic_wr_key && !['await_doccall', 'in_doccall', 'on_hold'].includes(row.status) && row.is_script) {
			groups.doc.push(...SCRIPT_STATUS_BTNS['np_send_scrs']);
		}

		if (app.user.claims.doc && !app.user.claims.nurse || app.user.claims.admin || app.user.claims.supp) {
			
			['auto_approved_delay', 'await_approval', 'await_payment'].includes(row.status) && !row.is_script && !['escript', 'c2u', 'home'].includes(row.form_data?.delivery) && 
				groups.doc.push( ...SCRIPT_STATUS_BTNS['doc_approve'] );

			['flagged_urgent'].includes(row.status) &&
				groups.doc.push(...(
					row.doc && row.doc === app.user.claims?.doc_id ? SCRIPT_STATUS_BTNS['flagged_urgent_assigned']
					: row.doc && row.doc !== DOCID_NODR ? []
					: SCRIPT_STATUS_BTNS['flagged_urgent_unassigned']
			));

			['flagged_urgent_done'].includes(row.status) &&
				groups.doc.push(...(SCRIPT_STATUS_BTNS['flagged_urgent_done']) );

			['auto_approved_delay', 'await_approval', 'await_payment'].includes(row.status) && !row.is_script && ['escript', 'c2u', 'home'].includes(row.form_data?.delivery) &&
				groups.doc.push( ...SCRIPT_STATUS_BTNS['doc_approve_escr'] );

			['approved', 'doc_approved', 'auto_approved', 'doc_issued'].includes(row.status) && row.is_script &&
				groups.doc.push( ...SCRIPT_STATUS_BTNS['doc_revoke'] );


			app.user.claims.doc && org_model.org_conf_itm(app.user.org, 'is_GP') && ['await_doccall', 'in_doccall', 'noans_doccall', 'could_not_call', 'wrong_number', 'on_hold'].includes(row.status) && (row.is_script || is_consult_like) &&
				groups.doc.push( ...SCRIPT_STATUS_BTNS['doc_consult'] );

			(app.user.claims.admin || app.user.claims.supp || app.user.claims.doc && org_model.org_conf_itm(app.user.org, 'is_GP')) && ['await_doccall', 'in_doccall', 'noans_doccall', 'could_not_call', 'wrong_number','need_review', 'on_hold'].includes(row.status) && (row.is_script || is_consult_like) &&
				groups.doc.push( 
					...SCRIPT_STATUS_BTNS['await_consult'].map((btn) => btn.a === 'noans_doccall' 
					? {
							...btn,
							...(Number(app.site_status?.max_missed_calls) && numberOfMissedCalls(row.hist) >= app.site_status.max_missed_calls && {
								alert: { contentWithIcon: maxMissedCallsText(row.hist), error: true, iconColor: 'red' },
								cann_dd_ops: SCRIPT_MSG_OPT_NO_ANS_CANCEL,
								a: 'cancel_doccall',
								checkbox: { label: 'Cancel consultation and refund patient', changeProOnChange: (checked) => ({ a: checked ? 'cancel_doccall' : 'noans_doccall' }), defaultChecked: true },
							})						
						}
					: btn),
				);

			(app.user.claims.admin || app.user.claims.supp || app.user.claims.doc && org_model.org_conf_itm(app.user.org, 'is_GP')) && ['in_doccall', 'noans_doccall', 'could_not_call', 'wrong_number'].includes(row.status) && (row.is_script || is_consult_like) &&
				groups.doc.push( ...SCRIPT_STATUS_BTNS['not_await_consult'] );

			app.user.claims.doc && org_model.org_conf_itm(app.user.org, 'is_GP') && ['followup_doccall'].includes(row.status) && row.is_script &&
				groups.doc.push( ...SCRIPT_STATUS_BTNS['doc_followup_consult'] );

			(app.user.claims.doc && org_model.org_conf_itm(app.user.org, 'is_GP') && !['await_doccall', 'in_doccall', 'on_hold'].includes(row.status) && (row.is_script || ['expr_declined', 'qual_declined'].includes(row.status))
			|| ((app.user.claims.admin || app.user.claims.supp) && !['flagged_urgent', 'flagged_urgent_done'].includes(row.status)))
				&& groups.doc.push( ...SCRIPT_STATUS_BTNS['doc_send_scrs'] );

			(row.status=='done_doccall') && row.is_script &&
				groups.doc.push( ...SCRIPT_STATUS_BTNS['doc_followup'] );

			groups.doc.push( ...SCRIPT_STATUS_BTNS['add_note'] );

			row?.med?.id=='-MdM99oAFZEtgGgABh2n' && groups.doc.push( ...SCRIPT_STATUS_BTNS['presc_prep'] );
		}

		if ((app.user.claims.admin || app.user.claims.pharm || app.user.claims.mang) && row.is_script!==false) {

			
			['pharm', 'medcert'].includes(row.script_type) &&
				groups.pharm.push( ...SCRIPT_STATUS_BTNS['pharm_scr_phrm'] );

			['medbuy', 'exprbuy', 'qualbuy'].includes(row.script_type) &&
				groups.pharm.push( ...SCRIPT_STATUS_BTNS['medbuy_scr_phrm'] );

			['medcons', 'exprcons', 'qualcons', 'docscr'].includes(row.script_type) &&
				groups.pharm.push( ...SCRIPT_STATUS_BTNS['medcons_scr_phrm'] );
		}

		if (app.user.claims.admin || app.user.claims.supp) {

			row.is_script &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['adm_scr_true'] );

			(row.is_script || is_consult_like) && ['await_doccall', 'in_doccall', 'noans_doccall', 'could_not_call', 'wrong_number'].includes(row.status) &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['put_on_hold'] );

			(row.is_script || is_consult_like) && row.status === 'on_hold' &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['not_await_consult'] );
			
			!row.pts_uid &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['adm_non_user'] )

			!row.is_script &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['adm_scr_false'] );

			!row.is_script && row.status === "form_errors" &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['adm_form_errors'] );

			(row.meta?.evermed || row?.org__oid === PARTN.EVERMED || row?.org_data?.oid === PARTN.EVERMED) &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['evermed_actions'] );
		}

		if (app.user.claims.admin) {
			(row.is_script) && row.epresc?.escript && row.epresc?.scid &&
				groups.admin.push( ...SCRIPT_STATUS_BTNS['adm_switch'] );

			(row.is_script && row.epresc?.err && !row.epresc?.escript)
				&& groups.admin.push( ...SCRIPT_STATUS_BTNS['reissue_escr'] );

		}

		if (app.acl.is_leg_oper && (['done_doccall'].includes(row.status))) {
			groups.oper.push( ...SCRIPT_STATUS_BTNS['leg_followup'] )
			
		}


		if (app.user.claims.root) {

			groups.admin.push( ...SCRIPT_STATUS_BTNS['root_scr'] )
		}

		if (groups.doc.length) {
			btns_arr.push( {t:'separator', n:'Doctor', i:'doctor'} );
			btns_arr = btns_arr.concat(groups.doc)
		}
		if (groups.pharm.length) {
			btns_arr.push( {t:'separator', n:'Pharmacist', i:'dna'} );
			btns_arr = btns_arr.concat(groups.pharm)
		}
		if (groups.admin.length) {
			btns_arr.push( {t:'separator', n:'Admin', i:'exclamation'} );
			btns_arr = btns_arr.concat(groups.admin)
		}
		if (groups.oper.length) {
			btns_arr.push( {t:'separator', n:'Operators', i:'user'} );
			btns_arr = btns_arr.concat(groups.oper)
		}

		
		return btns_arr
	}

	// ---- --------------------------------------------  --------------------------------------------

	static stats_dict (stat) {

		if ((app.user.claims.admin 
					|| app.user.claims.supp
					|| app.user.claims.doc 
					|| app.user.claims.mang 
					|| app.user.claims.pharm)
				&& ['auto_approved', 'approved', 'doc_approved'].includes(stat))
						return app.user.claims.root && stat || 'approved';

		if ((app.user.claims.admin 
					|| app.user.claims.supp
					|| app.user.claims.doc 
					|| app.user.claims.mang 
					|| app.user.claims.pharm)
				&& ['doc_issued'].includes(stat))
						return 'Dr issued';

		if (['followup_doccall'].includes(stat))
						return 'Follow up doctor call';

		if (['await_doccall'].includes(stat))
						return 'Awaiting doctor call';

		if (['noans_doccall'].includes(stat))
						return 'No answer to doctor call';

		if (['wrong_number'].includes(stat))
						return 'Wrong number';

		if (['in_doccall'].includes(stat))
						return 'Consult in progress';

		if (['done_doccall'].includes(stat))
						return 'Consult finished';

		if (['expr_declined', 'qual_declined'].includes(stat))
			return 'Consult finished (declined)';

		if(stat === 'on_hold') return 'On Hold';

		if(stat === 'cancelled') return 'Cancelled';

		if(stat === 'could_not_call') return 'Could not call';

		return stat
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static cust_scr_flds = (show_list, show_prescribe_again) => {
		return [
			!['maas', 'spon'].includes(app.user.org?.type) && !app.user.claims.doc && !['medbuy_new', 'medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick' , 'medbuy_post'].includes(show_list) && {
				name: 'Attach',
				template:FieldAttach
			},
			
			(app.user.claims.admin 
				|| app.user.claims.supp
				|| app.user.claims.doc 
				|| app.user.claims.mang && app.user.org?.type=='pharm' 
				|| app.acl.is_leg_oper
				|| app.user.claims.pharm) && 
			{
				name: 'Status',
				btns_list_func: (row) => [
					...scripts_list_model.stats_btns(row),
					...(show_prescribe_again && (app.user.claims.admin || app.user.claims.doc) && row.epresc?.res !== 'err' && (row.epresc?.item || row.epresc?.log?.find?.((l) => l.item))
					? SCRIPT_STATUS_BTNS['doc_prescribe_again']
					: [])
				],
				btns_stat_param: 'status',
				btns_stat_dict: scripts_list_model.stats_dict,
				btns_stat_dd_opts: SCRIPT_STATUS_OPTIONS,
				act_run: async (b, row, mod) => {
												const result = await API_service.load_data(
																	b.au || 'chgStat', 
																	{sid: row.sid, stat: b.a, ...b.p, mod}
																) 

												if (b.a!=null) row.status = result.to?.status || b.a
												if (b.p?.is_scr!=null) row.is_script = result.to?.is_script || b.p?.is_scr

												return result;
											},
				template: FieldActBtns
			},

			device_model.check_access('cosm') && app.acl.is_nurse && {
				name: 'Actions',
				btns_list_func: () => [{
					n: 'Scripts to pt',
					a: 'req_scr_ptn',
					c: 'purple',
					i: 'syringe'
				}, {n:'add note', a:'script note', cust:'stat_w_msg', pl_hld:'Add Note', info:r=>r.meta?.note, c:'olive', i:'write'}],
				act_run: async (b, row, mod) => {
					let result;
					if(b.a === 'script note'){
						result = await API_service.load_data( b.au || 'chgStat', {sid: row.sid, stat: b.a, ...b.p, mod} )
					}
					else{
						app.save_pt_form_data = row.spd_data;
						clearTimeout(app.save_pt_form_data_tmr);
						app.save_pt_form_data_tmr = setTimeout(_=>delete app.save_pt_form_data, 3*60*1000)
						app.history.push(app.consts.routes.cat_route+'/' + org_model.org_conf_itm(app.user.org, 'meds'));
						result = { res: 'ok' };
					}
					
					return result;
				},
				template: FieldActBtns
			},

			(app.user.claims.admin || app.user.claims.supp) && ['paid', 'medbuy', 'medclick', 'medcons', 'exprbuy', 'exprclick', 'exprcons', 'qualbuy', 'qualclick', 'qualcons', 'cado', 'support', 'all'].includes(show_list) &&
			{
				name: 'Payments',
				template:FieldPayments
			},
			
			(app.user.claims.admin || app.user.claims.supp) &&
				COMM_FIELDS.scr_req_env,

			(app.user.claims.admin || app.user.claims.supp) && 
			{
				name: 'Flag',
				type: 'multiline',
				lines: [
					{
						name:'User Flag',
						template:FieldUserFlag
					},
					{type: 'br'},
					{type: 'br'},
					{
						name:'Answer Flags',
						template:FieldAnswerFlags
					}
				]
			},
			
			(app.user.claims.admin || app.user.claims.supp) && 
			{
				name: 'Req',
				type: 'multiline',
				lines: [
					{
						name: 'Request',
						template:FieldRequest
					},
					{type: 'br'},
					{type: 'br'},
					
					(app.user.claims.admin || app.user.claims.supp) && 
					{
						name: 'Comms',
						template: LoadShowJson
					},
					{type: 'br'},
					{type: 'br'},

					...((!!app.user.claims.admin) ? [
					{
						name: 'RTDB',
						template: ({value: row}) => <LoadShowRTDBJson path={`MedScripts/${row.sid}`}/>
					},
					{type: 'br'},
					{type: 'br'},
					] : []),
					{
						name: 'VidCallLogs',
						template: ({value: row}) => row.form_data?.vid_call_network_test ? <>
							<Popup on="click" trigger={<Button icon='camera'/>} style={{minWidth: 700}}>
								<Tab panes={[
									{ menuItem: 'Log', render: () => <Tab.Pane><LoadVidCallLogSummary path={`MedScripts/${row.sid}`} /></Tab.Pane> },
									{ menuItem: 'Network Test', render: () => <Tab.Pane><NetworkTestResults stats={row.form_data.vid_call_network_test} /></Tab.Pane> },
								]}/>
							</Popup>
							</>: null
					},
				].filter(Boolean),
			},
			
			(app.user.claims.admin || app.user.claims.supp) &&
			{
				name: 'Edit Script',
				template:FieldEdit
			},
			
			!['await_consult', 'await_consult_all', 'consult_list', 'approve', 'medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick', 'medbuy_post'].includes(show_list) && !app.acl.is_leg_oper && !['maas', 'spon'].includes(app.user.org?.type) &&
			{
				name: 'Script PDFs',
				template:FieldPDFs
			},

			(app.user.claims.admin || app.user.claims.supp) && 
			{
				name: 'More',
				template:FieldMore
			},
			
		].filter(Boolean)
	}

	static prefill_pt_form_data(data, autoClearMinutes = 3) {
		app.save_pt_form_data = data;
		clearTimeout(app.save_pt_form_data_tmr);
		app.save_pt_form_data_tmr = setTimeout(_ => delete app.save_pt_form_data, autoClearMinutes * 60 * 1000)
	}
}

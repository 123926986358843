import React from 'react';
import { useEffect, useRef, useState } from 'react';
import firebase_auth from 'xAppLib/providers/firebase_auth';
import { PhoneAuthProvider, PhoneMultiFactorGenerator, multiFactor } from 'firebase/auth';

import { useUserPrefs, useProfile, useUser, useUserVerified } from 'xAppLib/Hooks';
import { Button, Input, Container, Segment, Select, Form, Icon, Grid, Modal,Message } from 'semantic-ui-react'
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import user_model from 'models/user_model'

const DEBUG = app.settings.dev_env

const ErrorMessage = ({error}) => {
	return  <Message
	error
	content={error && <>
		{ 
		(error.code == "auth/too-many-requests" || error.message?.includes("Code=17010")) && "Too many attempts. Try again later"
		|| (error.code == 'auth/invalid-verification-code' || error.message?.includes("Code=17044") || error.message?.startsWith('The sms verification code used to create')) && 'The SMS verification code used is invalid.' 
		|| error.message?.includes("Code=17051") && 'The SMS code has expired. Please re-send the code and try again.'
		|| error.code == "auth/unsupported-first-factor"  && <>Your account require a full logout of all your devices before you can enable this feature.<br/> <Button style={{marginLeft:'auto'}} basic size={'small'} content='Full Logout' secondary  onClick={ async ()=>user_model.full_logout() }  /></>
		|| error.message}
	</>}
  />
}

function handle_error(error) {
	if (error.message?.includes('Code=17058')) // cancel
		return true
	if (error.code == 'auth/requires-recent-login' || error.message?.includes('Code=17021') || error.message?.includes('Code=17014')) {
		app.confirm('This operation is sensitive and requires recent authentication. You must log in again before enabling this.','Would you like to log out and log in again now?').then(answer=>{
			if (answer == 'yes') {
				user_model.full_logout(true)
			}
		}) 
		return true
	}
}
 

const CodeInput = ({verificationCode,setVerificationCode, onSubmit, error}) => {
	const [btn, setBtn] = useState('');

	return <><div className='flex flex-row items-stretch'>
		<div className='flex-grow'>
			<Form.Input 
					// label={"Verify"}
					required={true}
					name={'verificationCode'}
					value={verificationCode}
					onChange={ (e) => {
						setVerificationCode(e.target.value)
						setBtn(e.target.value.length  > 1  ? 'primary' : '')
					} }
					placeholder={"Enter verification code"} 
					fluid 
					control = {Input}
					autoFocus
				/> 
		</div>
		<div className='flex items-stretch pl-2'>
			<Button className={btn} onClick={onSubmit}>Verify</Button>
		</div>
	</div>
	 <ErrorMessage error={error} />
   </>
	;
}

const EnrolledFactors = ({enrolledFactors}) => {
	const user = useUser()
	
	if (enrolledFactors.length == 0)
		return null
	return <div>
		<h3>Additional Authentication Methods</h3>
		{enrolledFactors.map(factor => {
			return <div key={factor.factorId || factor.index} className='flex items-center'>
				<div className='flex-grow'>{factor.displayName}</div>

				<Button size='tiny'
					onClick={async ()=>{
						if (await app.confirm('Remove authentication method.','Are you sure?') !== 'yes') 
							return 
						try {
							await firebase_auth.unenrollFactor(factor)
							
						} catch (error) {
							handle_error(error)
							
						}
					}}
				>Remove</Button>
			</div>
		})}

	</div>;
}
 


const MFA = ({ bannerMode = false}) => {
	const user = useUser()
	const verified = useUserVerified()
	const [profile,profiles] = useProfile()
	const [ref, setRef] = useState(null)
	const [modalRef, setModalRef] = useState(null)
	const recaptcha = useRef(null)
	const [verificationId, setVerificationId] = useState(null)
	const [verificationCode, setVerificationCode] = useState('')
	const [resolver, setResolver] = useState(null)
	const [onMfaResult, setOnMfaResult] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const location = useLocation()
	const enrolledFactors = user.enrolledFactors 
	
	const phoneNumber = profile?.mobile && '+61' + profile.mobile.replace(/\D/g,'').trimStart('0').replace(/^0+/, '')

	const showPanel = user.user_in && verified && app.settings.can_mfa && (!bannerMode || app.acl.need_mfa)
	const is_profile = location.pathname == '/profile'
	
	const panelClass = `!mb-4${bannerMode ? '' : 'mfa'}`

	useEffect(() => {
		setVerificationCode('')
		setError(null)
	},[user])

	useEffect(() => {
		DEBUG && console.log("creating RecaptchaVerifier",ref);
		if (!ref) return;
		recaptcha.current = firebase_auth.get_captcha(ref, {
			'size': 'invisible',
			'callback': function(response) {
				// reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
				// TODO handle this better
				DEBUG && console.log("Solved",response)
			},
			'expired-callback': (e) => {
				DEBUG && console.log("expired",e)
				// Response expired. Ask user to solve reCAPTCHA again.
				// ...
			  }
		   });
		return () =>{
			recaptcha.current.clear()
		}
	},[ref])

	useEffect(()=>{
		if (modalRef) {
			try {
				const el = document.getElementsByClassName('auth-modal').item(0)?.parentElement
				if (el)
					el.style.zIndex=10000
			} catch (error) {

			}
		}

	},[modalRef])


	const onHandleFactor = useCallback(async (resolver, mfaResult)=>{
		DEBUG && console.log("onHandleFactor",resolver, recaptcha.current);
		setResolver(resolver)
		setOnMfaResult(mfaResult)
		setVerificationId(null)
		setVerificationCode('')
		setError(null)
		setLoading(true)
		try {
			const phoneInfoOptions = {
				multiFactorHint: resolver.hints[0],
				session: resolver.session
			};
			const verificationId = await firebase_auth.verifyPhone(phoneInfoOptions, recaptcha.current)
			DEBUG && console.log("got verificationId",verificationId);
			setVerificationId(verificationId)
			
		} catch (error) {
			DEBUG && console.log("error",error);
			if (!handle_error(error)) {
				setError(error)
			}
		}
		setLoading(false)

	},[])

	const onRequestInitialCode = useCallback(async ()=>{
		setLoading(true)
		try {
			const verificationId = await firebase_auth.initiatePhoneEnroll(phoneNumber,recaptcha.current)
			DEBUG && console.log("got verificationId",verificationId);
			setVerificationId(verificationId)
		} catch (error) {
			DEBUG && console.log("error",error);
			if (!handle_error(error)) {
				setError(error)
			}
		}
		setLoading(false)
	},[user, phoneNumber])


	useEffect(()=>{
		app.on(app.events.MFA_CONFIRM, onHandleFactor)
		return () => {
			app.off(app.events.MFA_CONFIRM, onHandleFactor)
		}
	},[onHandleFactor])


	/* 
		Assuming phone
		TODO handle other future MFA types when they are supported
	*/

	const hint = resolver?.hints[0]

	const days = app.settings.mfa_days ?? 1
	const danger_days = 5

	return ( <>
		
		{showPanel && <Container className={panelClass}>
			<div ref={ref=>setRef(ref)}></div>
			<Segment style={{marginTop: 0}}>
				<h5>Account Security</h5>
				<EnrolledFactors enrolledFactors={enrolledFactors} />
		{ profile && !app.acl.has_mfa && <>
			
			{!verificationId && <Form
				loading={loading}	
				error={!!error}
			>
				<p>Enable two-factor authentication. This adds an additional security level to your account.</p>
				{ __IONIC__ && !firebase_auth.native_mfa && <p><b>Please note, the two-factor authentication is not currently supported on this version of the iOS mobile app. Please update your app to the latest from the App Store.</b></p> || null}

				{app.acl.need_mfa && <p className={days <= danger_days ? "ui text red" : "text orange"}>
					{days <= danger_days && <Icon name='warning sign' className="ui text" color={days <= danger_days ? "red" : "orange"} />} You have {days} day{days!=1?'s':''} left to enable two-factor authentication.
					{days <= 0 && <><br/><b>Access to the service is disabled until you enable two-factor authentication.</b></>
					 || days <= danger_days && " Access to the service will be disabled after this time."}
				</p>}

				{phoneNumber && <><Button
					onClick={onRequestInitialCode}
					>Enable for {phoneNumber}</Button>
					{bannerMode && !is_profile && <p>Not the correct number? <Link className='underline' to='/profile'>Click here to edit your profile</Link></p>}
					</> || <p>Please provide a mobile number in your profile to start enrollment. {bannerMode && !is_profile && <Link className='underline' to='/profile'>Click here to edit your profile</Link>}</p>}
				<ErrorMessage error={error} />
			</Form>
			
			}
			
			{verificationId && <>
				<p>A confirmation code has been sent to your nominated number: {phoneNumber}. Please enter the code in the field below. <br/>Didn't get it? <span className='underline' onClick={onRequestInitialCode}>Click here</span> to resend.</p>
				<Form
					error={!!error}
					loading={loading}
				>
					<CodeInput 
						name={'verificationCode'}
						verificationCode={verificationCode}
						setVerificationCode={setVerificationCode}
						error={error}
						onSubmit={async ()=>{
							setLoading(true)
							try {
								DEBUG && console.log("verify",verificationId, verificationCode);
								await firebase_auth.enrollPhoneFactor(phoneNumber,verificationId,verificationCode)
								app.functions.add_message({type: 'positive', header:'Second authentication factor', content: ["Successfully enabled."]})
								setVerificationId(null)
							} catch (error) {
								DEBUG && console.log("error",error);
								if (!handle_error(error)) {
									setError(error)
								}
							}
							setLoading(false)

							

						}}
					/>
					
					</Form> 
				</>
				
				}
				
			</>}
			</Segment>
				</Container>}
			{hint && <>
				{!showPanel && <div ref={ref=>setRef(ref)}></div>}
				<Modal open 
					closeIcon
					ref={ref=>setModalRef(ref)}
					onClose={()=> {
						setResolver(null)
						onMfaResult.reject(new Error('User cancelled'))
						setOnMfaResult(null)
					}}
					className='auth-modal'
				>
					{}
					<Modal.Header>Verify with mobile number</Modal.Header>
					<Modal.Content>
						{verificationId && <p>Code has been sent to your nominated mobile {hint.phoneNumber}. <br/>Didn't get it? <span className='underline' onClick={async ()=>{
							onHandleFactor(resolver, onMfaResult)
						}}>Click here</span> to resend.</p>}
						<Form 
							error={!!error}
							loading={loading}
						>	
						<CodeInput 
							name={'verificationCode'}
							verificationCode={verificationCode}
							setVerificationCode={setVerificationCode}
							error={error}
							onSubmit={async ()=>{
								setLoading(true)
								try {
									DEBUG && console.log("verify",verificationId, verificationCode);
									setError(null)
									await firebase_auth.verifyPhoneCode(resolver, verificationId, verificationCode)
									setResolver(null)
									onMfaResult.resolve()
									setOnMfaResult(null)
									setVerificationId(null)
									setVerificationCode('')
								} catch (error) {
									DEBUG && console.log("error",error);
									if (!handle_error(error)) {
										setError(error)
									}
								}
								
								setLoading(false)
							}}
						/>
							
						</Form>

					</Modal.Content>
				</Modal>
			</>}
			

	</>  );
}
 
export default MFA;
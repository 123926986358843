import React, { Fragment } from 'react'
import { Popup } from 'semantic-ui-react'
import { ObjectInspector, chromeLight } from 'react-inspector'
import ReferenceRangeIndicatorSVG from 'xAppLib/Results/ReferenceRangeIndicatorSVG'


export const cleanOBXNumericValue = (value) => {
	if (value === undefined) {
		console.log('cleanOBXNumericValue value undefined')
		return ''
	}

	if (['Not detected', 'Non reactive'].includes(value)) {
		console.log('cleanOBXNumericValue value boolean like')
		return ''
	}
	//Numeric obx value can be an array and can have < 1.0 or >2 etc
	let val = (Array.isArray(value))
		? value.join(' ')
		: value
	val = val.toString().replace(/nil/g, '0')
	val = val.toString().replace(/Nil/g, '0')
	val = Number(val.toString().replace(/[^0-9.]/g, ''))
	return val
}


export const cleanOBXRefRange = (refRange) => {
	let range = refRange.toString().replace(/ /g, '')
	range = range.toString().replace(/lass/g, '<')
	range = range.toString().replace(/over/g, '>')
	const annoyingSlash = range.indexOf('/')
	if (annoyingSlash !== -1)
		range = range.slice(0, annoyingSlash)
	return range
}

export const isEmergency = (itemValue, emergency_range_low = '', emergency_range_high = '') => {
	let isEmergencyLow = false, isEmergencyHigh = false
	if (itemValue !== undefined) {
		const value = cleanOBXNumericValue(itemValue)
		isEmergencyLow = (emergency_range_low !== '' && Number(value) < Number(emergency_range_low))
		isEmergencyHigh = (emergency_range_high !== '' && Number(value) > Number(emergency_range_high))
	}
	return (isEmergencyLow || isEmergencyHigh)
}


export const isInRange = (itemValue, refRange) => {
	let inRange = '', rangeLow, rangeHigh
	if (!(refRange === undefined || itemValue === undefined || refRange === '')) {
		//Examples of what the range looks like include
		// <70, < 70, <70-, < 70-, >10, > 10, >10-, 30-40, 30 - 40
		// etc etc. Need to remove white space
		// also annoying 10-20/Pregnant, so ignore to the right of the /
		let range = refRange.toString().replace(/ /g, '')
		range = range.toString().replace(/lass/g, '<')
		range = range.toString().replace(/over/g, '>')
		const annoyingSlash = range.indexOf('/')
		if (annoyingSlash !== -1)
			range = range.slice(0, annoyingSlash)
		const value = cleanOBXNumericValue(itemValue)
		const lessThan = range.indexOf('<')
		const greatThan = range.indexOf('>')
		const between = range.indexOf('-')
		// <X
		if (lessThan !== -1) {
			rangeHigh = range.slice(lessThan + 1)
			//Need to test for - as can have an unnecessary trailing one
			const lastChar = rangeHigh.slice(-1)
			if (lastChar === '-')
				rangeHigh = rangeHigh.slice(0, -1)
			//Need to check if <=
			const equal = rangeHigh.indexOf('=')
			if (equal !== -1)
				rangeHigh = rangeHigh.replace(/=/g, '')
			if (Number(value) > Number(rangeHigh)) {
				inRange = 'high'
			}
			// >X
		} else if (greatThan !== -1) {
			rangeLow = range.slice(greatThan + 1)
			//Need to test for - as can have an unnecessary trailing one
			const lastChar = rangeLow.slice(-1)
			if (lastChar === '-')
				rangeLow = rangeLow.slice(0, -1)
			//Need to check if <=
			const equal = rangeLow.indexOf('=')
			if (equal !== -1)
				rangeLow = rangeLow.replace(/=/g, '')
			if (Number(value) < Number(rangeLow)) {
				inRange = 'low'
			}
			// X-Y
		} else if (between !== -1) {
			rangeLow = range.slice(0, between)
			rangeHigh = range.slice(between + 1)
			if (Number(value) < Number(rangeLow)) {
				inRange = 'low'
			} else if (Number(value) > Number(rangeHigh)) {
				inRange = 'high'
			}
		}
	}
	return inRange
}


export const getChartRanges = (itemValue, refRange, emergency_range_low = '', emergency_range_high = '') => {
	let lower = 0, upper = 0, ref_lower = 0, ref_upper = 0
	let inRange = '', rangeLow, rangeHigh, isEmergencyLow = false, isEmergencyHigh = false
	if (!(refRange === undefined || itemValue === undefined || refRange === '')) {
		//Examples of what the range looks like include
		// <70, < 70, <70-, < 70-, >10, > 10, >10-, 30-40, 30 - 40
		// etc etc. Need to remove white space
		// also annoying 10-20/Pregnant, so ignore to the right of the /
		let range = refRange.toString().replace(/ /g, '')
		range = range.toString().replace(/lass/g, '<')
		range = range.toString().replace(/over/g, '>')
		const annoyingSlash = range.indexOf('/')
		if (annoyingSlash !== -1)
			range = range.slice(0, annoyingSlash)
		const value = cleanOBXNumericValue(itemValue)
		const lessThan = range.indexOf('<')
		const greatThan = range.indexOf('>')
		const between = range.indexOf('-')
		// <X
		if (lessThan !== -1) {
			rangeHigh = range.slice(lessThan + 1)
			//Need to test for - as can have an unnecessary trailing one
			const lastChar = rangeHigh.slice(-1)
			if (lastChar === '-')
				rangeHigh = rangeHigh.slice(0, -1)
			//Need to check if <=
			const equal = rangeHigh.indexOf('=')
			if (equal !== -1)
				rangeHigh = rangeHigh.replace(/=/g, '')
			if (Number(value) > Number(rangeHigh)) {
				inRange = 'high'
			}
			lower = 0
			upper = (emergency_range_high !== '')
				? Math.max(Number(value), Number(rangeHigh), Number(emergency_range_high))
				: Math.max(Number(value), Number(rangeHigh))
			ref_lower = 0
			ref_upper = Number(rangeHigh)
			// >X
		} else if (greatThan !== -1) {
			rangeLow = range.slice(greatThan + 1)
			//Need to test for - as can have an unnecessary trailing one
			const lastChar = rangeLow.slice(-1)
			if (lastChar === '-')
				rangeLow = rangeLow.slice(0, -1)
			//Need to check if <=
			const equal = rangeLow.indexOf('=')
			if (equal !== -1)
				rangeLow = rangeLow.replace(/=/g, '')
			if (Number(value) < Number(rangeLow)) {
				inRange = 'low'
			}
			lower = 0
			upper = Math.max(Number(value), Number(rangeLow))
			ref_lower = Number(rangeLow)
			ref_upper = upper + 1
			// X-Y
		} else if (between !== -1) {
			rangeLow = range.slice(0, between)
			rangeHigh = range.slice(between + 1)
			if (Number(value) < Number(rangeLow)) {
				inRange = 'low'
			} else if (Number(value) > Number(rangeHigh)) {
				inRange = 'high'
			}
			lower = 0
			upper = (emergency_range_high !== '')
				? Math.max(Number(value), Number(rangeHigh), Number(emergency_range_high))
				: Math.max(Number(value), Number(rangeHigh))
			ref_lower = Number(rangeLow)
			ref_upper = Number(rangeHigh)
		}
		isEmergencyLow = (emergency_range_low !== '' && Number(value) < Number(emergency_range_low))
		isEmergencyHigh = (emergency_range_high !== '' && Number(value) > Number(emergency_range_high))
	}
	//So we now have the extremes, let's add some headroom to the upper
	const headroom = Math.max(upper, ref_upper + ref_lower) - upper
	const chart_range = {
		lower: lower,
		upper: upper + headroom,
		ref_lower: ref_lower,
		ref_upper: ref_upper,
		inRange: inRange,
		isEmergencyLow: isEmergencyLow,
		isEmergencyHigh: isEmergencyHigh
	}
	return chart_range
}


const createMarkupNormal = (text) => {
	return { __html: text.replace(/monospace/, 'Roboto Mono') }
}

const createMarkup = (text) => {
	return { __html: '<pre class="text-sm" style="max-width: 38rem; white-space: pre-wrap; font-size: 0.8rem;">' + text + '</pre>' }
}

const PreLayout = (params) => {
	const { text } = params
	return <div style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={createMarkup(text)} />
}

const TextLayout = (params) => {
	const { text } = params
	return <div dangerouslySetInnerHTML={createMarkupNormal(text)} />
}

export const layoutTextResult = (textdata, pre = true) => {
	let data = (textdata === undefined) ? '' : textdata
	if (Array.isArray(data))
		data = data.join('\\.br\\')
	const textlines = []
	const text = String(data).split('\\.br\\')
	let t, tclean
	if (text.length > 0)
		for (t = 0; t < text.length; t++)
			if (text[t] !== '') {
				tclean = text[t]
				tclean = tclean.replace(/\\H\\/g, '<b>')
				tclean = tclean.replace(/\\N\\/g, '</b>')
				tclean = tclean.replace(/\\F\\/g, '|')
				tclean = tclean.replace(/\\S\\/g, '^')
				tclean = tclean.replace(/\\R\\/g, '~')
				tclean = tclean.replace(/\\T\\/g, '&')
				tclean = tclean.replace(/\\E\\/g, '\\')
				tclean = tclean.replace(/\\R\\SUND\\R\\/g, '<u>')
				tclean = tclean.replace(/\\R\\EUND\\R\\/g, '</u>')
				tclean = tclean.replace(/\\R\\SBLD\\R\\/g, '<b>')
				tclean = tclean.replace(/\\R\\EBLD\\R\\/g, '</b>')
				tclean = tclean.replace(/\\R\\SBLK\\R\\/g, '<mark>')
				tclean = tclean.replace(/\\R\\EBLK\\R\\/g, '</mark>')
				tclean = tclean.replace(/\\.nf\\/g, '')//begin no-wrap already dothis
				//Colours
				tclean = tclean.replace(/\\R\\FG00\\R\\/g, '<span class="FG00">')
				tclean = tclean.replace(/\\R\\FG01\\R\\/g, '<span class="FG01">')
				tclean = tclean.replace(/\\R\\FG02\\R\\/g, '<span class="FG02">')
				tclean = tclean.replace(/\\R\\FG03\\R\\/g, '<span class="FG03">')
				tclean = tclean.replace(/\\R\\FG04\\R\\/g, '<span class="FG04">')
				tclean = tclean.replace(/\\R\\FG05\\R\\/g, '<span class="FG05">')
				tclean = tclean.replace(/\\R\\FG06\\R\\/g, '<span class="FG06">')
				tclean = tclean.replace(/\\R\\FG07\\R\\/g, '<span class="FG07">')
				tclean = tclean.replace(/\\R\\FG08\\R\\/g, '<span class="FG08">')
				tclean = tclean.replace(/\\R\\FG09\\R\\/g, '<span class="FG09">')
				tclean = tclean.replace(/\\R\\FG10\\R\\/g, '<span class="FG10">')
				tclean = tclean.replace(/\\R\\FG11\\R\\/g, '<span class="FG11">')
				tclean = tclean.replace(/\\R\\FG12\\R\\/g, '<span class="FG12">')
				tclean = tclean.replace(/\\R\\FG13\\R\\/g, '<span class="FG13">')
				tclean = tclean.replace(/\\R\\FG14\\R\\/g, '<span class="FG14">')
				tclean = tclean.replace(/\\R\\FG15\\R\\/g, '<span class="FG15">')
				tclean = tclean.replace(/\\R\\FG99\\R\\/g, '</span>')
				//tclean = tclean.replace(/\\R\\/g, '~')
				if (pre)
					textlines.push(<PreLayout key={t} text={tclean} />)
				else
					textlines.push(<TextLayout key={t} text={tclean} />)
			}
	return textlines
}


export const layoutHL7ResultWithRange = (scr, att, i, j, is_admin, is_unseen_screen) => {
	//scr is the script object with the attch and the rid attachments
	const obxs = []
	const rid = att.rid
	let warning = 0, corrected_result = 0, obx_title_prev = ''
	//Grab the related raw result and display accordingly
	const result = scr.results_raw.filter(item => item.rid === rid)
	if (result.length === 0) return null
	const observations = result[0].data_json.observations
	const request = result[0].data_json.request
	const patient = result[0].data_json.patient
	const header = result[0].data_json.header
	//Check if this is only a report or whether we have individiual observations
	const is_report_only = observations.filter(obx => ['PIT', 'REPORT', 'HTML'].includes(obx.obs_id[0])).length === observations.length
	for (let o = 0; o < observations.length; o++) {
		const obx = observations[o]
		const val = (Array.isArray(obx.value) && Array.isArray(obx.value[0]))
			? obx.value[0].join(' ')
			: obx.value.join(' ')
		const obx_range = cleanOBXRefRange(obx.ref_range)
		//Out of range and positive disease done server side
		const is_warn = (obx.warn_disease === true || obx.warn_range === true)
		const warn_range_detail = (obx.warn_range_detail === undefined) ? '' : obx.warn_range_detail
		warning += (is_warn) ? 1 : 0
		corrected_result += (obx.status !== undefined && obx.status.toLowerCase() === 'c') ? 1 : 0
		const range_colour = (warn_range_detail === 'low')
			? '#0065F2'
			: (warn_range_detail === 'high')
				? '#F2711C'
				: '*000000'
		//Numeric with range
		if ((obx.ref_range !== '' || obx.units !== '' || obx.type === 'NM') && !['PIT', 'REPORT', 'HTML'].includes(obx.obs_id[0])) {
			const obx_value = cleanOBXNumericValue(val)
			obxs.push(
				<ul key={o} className={`flex flex-wrap mb-0.5 text-md ${(is_warn) ? 'bg-yellow-50' : ''}`}>
					<li className="w-52 pl-2 pt-1.5"><pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}>{obx.obs_id[1]}</pre></li>
					<li className="w-40"><ReferenceRangeIndicatorSVG desc={`${obx.obs_id[0]} ${obx.obs_id[1]}`} value={obx_value} ref_range={obx_range} emergency_range_low={''} emergency_range_high={''} width={160} /></li>
					<li className="w-16 text-right mr-3 pt-1.5"><pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}><strong style={{ color: range_colour }}>{val}</strong></pre></li>
					<li className="w-20 pt-1.5"><pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}>{obx.units}</pre></li>
					<li className="w-20 text-gray-500 text-center pt-1.5"><pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}>{obx_range}</pre></li>
					<li className="w-16 text-center pt-1.5"><pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}><strong style={{ color: range_colour }}>{warn_range_detail.toUpperCase()}</strong></pre></li>
				</ul>
			)
		} else {
			if (is_report_only) {
				obxs.push(
					<ul key={o} className={`flex text-md`}>
						<li>
							{layoutTextResult(val.substr(0, 100))}
							click view to see report...
						</li>
					</ul>
				)
			} else if (!['PIT', 'REPORT', 'HTML', 'PDF'].includes(obx.obs_id[0])) {
				//Clean the unnecessary title
				let obx_title = (obx.obs_id[1] === undefined) ? '' : obx.obs_id[1]
				let result_title = (request.sn === undefined) ? '' : request.sn
				result_title = Array.isArray(result_title) ? result_title.join(', ') : result_title
				obx_title = obx_title.replace(/^_+/g, '')
				obx_title = obx_title.replace(/\.$/, '')
				result_title = result_title.replace(/^_+/g, '')
				result_title = result_title.replace(/\.$/, '')
				obx_title = (result_title.toLowerCase() === obx_title.toLowerCase()) ? '' : obx_title
				obx_title = (obx.obs_id[0].substr(0, 1) === 'C' && obx.obs_id[0].length === 4 && header.send_fac === 'DHM') ? '' : obx_title
				obx_title = (['ln', 'comment'].includes(obx_title.toLowerCase())) ? '' : obx_title
				const obx_title_display = (obx_title === obx_title_prev)
					? ''
					: obx_title
				const pad = (obx_title_prev !== '' && obx_title === '')
				obxs.push(
					<Fragment key={o}>
						<ul key={o} className={`flex ${(pad) ? 'mt-5' : ''} text-md ${(is_warn) ? 'bg-yellow-50' : ''}`}>
							{(obx_title_display !== '' &&
								<li className="flex-none w-52 mt-2 pl-2 pr-2">{layoutTextResult(obx_title_display)}</li>
							)}
							{(obx_title_display === '' &&
								<li className="flex-none pl-2"></li>
							)}
							<li className="flex-auto mt-2 mb-2">
								<span className={`${(is_warn) ? 'font-bold' : ''}`}>{layoutTextResult(val)}</span>
							</li>
						</ul>
					</Fragment>
				)
				obx_title_prev = obx_title
			}
		}
	}
	return {
		layout: <>
			<div className="text-gray-600 mt-2 mb-4 mr-4">
				<ul><li className="flex-auto mt-3 mb-3"><hr /></li></ul>
				<ul className="flex text-md">
					<li className="flex-none w-52 pl-2">
						<pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.9rem' }}>
							{patient.first_name} {patient.last_name}
							<br />({patient.sex}) {patient.dob}
						</pre>
					</li>
					<li className="flex-auto">
						<pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.9rem' }}>
							{!(is_admin && is_unseen_screen) &&
								<>Laboratory: {header.send_fac}</>
							}
							{(is_admin && is_unseen_screen) &&
								<Popup
									trigger={<span className="hover:cursor-pointer">Laboratory: {header.send_fac}</span>}
									position="left center"
									on="click"
									style={{ height: '80%', width: '80%', minWidth: 700, overflow: 'scroll' }}
								>
									<ObjectInspector
										data={result[0]}
										expandLevel={0}
										theme={{ ...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 }) }}
									/>
								</Popup>
							}
							<br />Requested: {(request.req_time.substr(10, 9) === ' 00:00:00') ? request.req_time.substr(0, 10) : request.req_time}
							<br />Collected: {(request.obs_time.substr(10, 9) === ' 00:00:00') ? request.obs_time.substr(0, 10) : request.obs_time}
						</pre>
					</li>
					<li className="flex-auto text-right">
						<pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.9rem' }}>
							{request.id}
							<br />{request.ord_prov_p} {request.ord_prov_g_name} {request.ord_prov_f_name}
							<br />{request.ord_prov_no}
						</pre>
					</li>
				</ul>
				<ul><li className="flex-auto mt-3 mb-2"><hr /></li></ul>
			</div>
			{obxs.map(item => item)}
		</>,
		warning: warning,
		corrected_result: corrected_result
	}
}
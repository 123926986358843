import React from 'react';
import { Grid } from 'semantic-ui-react'
import IcosmImageLink from './icosmImageLink';

const IcosmTiles = ({tile_data, cols=null}) => {

    return  <div className="tiles mt-0 md:mt-12">
            <Grid stackable columns={cols ?? tile_data.length}>
                {tile_data.map((el, i) =>
                    <Grid.Column key={i}>
                        <IcosmImageLink title={el.title} img={el.img} link={el.link} link_text={el.link_text} target={el.target}/>
                    </Grid.Column>
                )}
            </Grid>
		  </div>
}

export default IcosmTiles;

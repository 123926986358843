import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

const IcosmImageLink = ({title, img, link, target="_self", link_text ="Learn More"}) => {

    return <Link to={link} target={target} rel="noopener noreferrer">
                <div className="flex flex-col justify-end image-link p-4" style={{backgroundImage: img.startsWith("/images/icosm/") ? `url(${img})` : `url(https://storage.googleapis.com/icosm/${img})`}}>
                    <div className="inner">
                        <h3 className="text-icosm-gold-50">{title}</h3>
                        {link && <p className="link">{link_text}</p>}
                    </div>
                </div>
            </Link>
}

export default IcosmImageLink;
